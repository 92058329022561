import React from "react";
import NavbarFavorites from "../../components/NavbarFavorites/NavbarFavorites";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import ExpertCardSkeleton from "../../components/ExpertCardSkeleton/ExpertCardSkeleton";
import { Grid, Typography } from "@mui/material";
import FavoriteExpertCard from "../../components/FavoriteExpertCard/FavoriteExpertCard";

function FavoriteExpertsUI({
	loading,
	loadingExperts,
	experts,
	onPressFavorite,
}) {
	return (
		<>
			<NavbarFavorites />
			<LoadingOverlay isLoading={loading} />
			{loadingExperts ? (
				<div
					style={{
						display: "flex",
						justifyContent: "center",
					}}
				>
					<Grid
						container
						spacing={3}
						sx={{ maxWidth: 1100, marginTop: "1rem" }}
					>
						{[1, 2, 3, 4, 5, 6].map((item) => (
							<Grid item key={item} xs={12} sm={6} md={4}>
								<ExpertCardSkeleton />
							</Grid>
						))}
					</Grid>
				</div>
			) : (
				<div
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "2rem",
						padding: "2rem",
						flexWrap: "wrap",
						justifyContent: "center",
					}}
				>
					{experts.map((expert) => (
						<FavoriteExpertCard
							key={expert._id}
							data={expert.expert}
							onPressFavorite={onPressFavorite}
						/>
					))}
				</div>
			)}
			{experts.length === 0 && (
				<div
					style={{
						display: "flex",
						padding: "1rem",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						height: "60vh",
					}}
				>
					<Typography
						variant="h6"
						fontSize="2rem"
						fontWeight="bold"
						gutterBottom
						style={{ textAlign: "center" }}
					>
						No has marcado ningun experto como favorito
					</Typography>
					<img
						src={require("../../../assets/images/caja-vacia.png")}
						alt="empty"
						width="300px"
					/>
				</div>
			)}
		</>
	);
}

export default FavoriteExpertsUI;
