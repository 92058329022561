import React, { useEffect, useState } from "react";
import EditHousingInvoicesUI from "./EditHousingInvoicesUI";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import readInvoice from "../../../networking/endpoints/formEndpoint/readInvoice";
import uploadInvoices from "../../../networking/endpoints/housing/uploadInvoices";
import getInvoices from "../../../networking/endpoints/housing/getInvoices";
import deleteInvoice from "../../../networking/endpoints/housing/deleteInvoice";

function EditHousingInvoices() {
	const { housingId } = useParams();
	const token = localStorage.getItem("authToken");
	const [loading, setLoading] = useState(false);

	const [selectedPDF, setSelectedPDF] = useState(null); // Selected PDF to display
	const [openPdfDialog, setOpenPdfDialog] = useState(false);
	const [invoiceRows, setInvoiceRows] = useState([]);
	const [edited, setEdited] = useState(false); // To check if the user has edited the forms

	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await getInvoices(token, housingId);
			console.log(response);
			const invoices = response.invoices;
			const auxRows = [];
			for (let i = 0; i < invoices.length; i++) {
				const invoice = invoices[i];
				const companyName = invoice.invoice_vendor;
				const date = invoice.invoice_date;
				const total = invoice.invoice_total;
				const pdfUrl = null;
				const newRow = {
					id: invoice._id,
					empresa: companyName,
					fecha: date,
					total: total,
					pdf: pdfUrl,
					pdfFile: null,
					fetched: true,
				};
				auxRows.push(newRow);
			}
			setInvoiceRows(auxRows);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		const isEdited = invoiceRows.some((row) => !row.fetched);
		if (isEdited) {
			setEdited(true);
		} else {
			setEdited(false);
		}
	}, [invoiceRows]);

	const handlePdfChange = async (event, id) => {
		const file = event.target.files[0];
		try {
			setLoading(true);
			let formData = new FormData();
			formData.append("pdf", file);
			const response = await readInvoice(token, formData);
			const companyName = response.fields.VendorName.value;
			const date = response.fields.DueDate.value.split("T")[0];
			const total = response.fields.InvoiceTotal.value.amount;
			const updatedRows = invoiceRows.map((row) => {
				if (row.id === id) {
					const pdfBlob = new Blob([file], {
						type: "application/pdf",
					});
					const pdfUrl = URL.createObjectURL(pdfBlob);
					return {
						...row,
						empresa: companyName,
						fecha: date,
						total: total,
						pdf: pdfUrl,
						pdfFile: file,
					};
				}
				return row;
			});
			setInvoiceRows(updatedRows);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};
	// Function to handle clicking on a PDF preview
	const handlePdfClick = (pdfUrl) => {
		setSelectedPDF(pdfUrl);
		setOpenPdfDialog(true);
	};

	// Function to close the PDF dialog
	const handleClosePdfDialog = () => {
		setSelectedPDF(null);
		setOpenPdfDialog(false);
	};

	const handleAddPdfRow = () => {
		const newRow = {
			id: Date.now(),
			empresa: "",
			fecha: "",
			total: "",
			pdf: null,
			pdfFile: null,
		};
		setInvoiceRows([...invoiceRows, newRow]);
	};

	const handleDeletePdfRow = async (id) => {
		const rowToDelete = invoiceRows.find((row) => row.id === id);

		if (rowToDelete.fetched) {
			try {
				setLoading(true);
				Swal.fire({
					title: "¿Estás seguro?",
					text: "Esta acción no se puede deshacer",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Sí, eliminar",
					cancelButtonText: "Cancelar",
				}).then(async (result) => {
					if (result.isConfirmed) {
						await deleteInvoice(token, id);
						setLoading(false);
						Swal.fire(
							"Eliminado",
							"La factura ha sido eliminada",
							"success"
						);
						fetchData();
					}
				});
				setLoading(false);
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error,
				});
			}
		} else {
			const updatedRows = invoiceRows.filter((row) => row.id !== id);
			setInvoiceRows(updatedRows);
		}
	};

	const validateInvoices = (inv) => {
		for (let i = 0; i < inv.length; i++) {
			if (inv[i].empresa === "") {
				return false;
			}
			if (inv[i].fecha === "") {
				return false;
			}
			if (inv[i].total === "") {
				return false;
			}
			if (inv[i].pdfFile === null) {
				return false;
			}
		}
		return true;
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const invoicesToUpload = invoiceRows.filter((row) => !row.fetched);

			const invoicesAreValid = validateInvoices(invoicesToUpload);

			if (!invoicesAreValid) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Por favor, cargue todas las facturas",
				});
				return;
			}
			await uploadInvoices(token, invoicesToUpload, housingId);
			setLoading(false);
			fetchData();
			Swal.fire({
				icon: "success",
				title: "¡Facturas subidas!",
				text: "Las facturas han sido subidas exitosamente",
			});
			fetchData();
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};

	return (
		<EditHousingInvoicesUI
			loading={loading}
			housingId={housingId}
			invoiceRows={invoiceRows}
			handlePdfChange={handlePdfChange}
			handlePdfClick={handlePdfClick}
			handleClosePdfDialog={handleClosePdfDialog}
			selectedPDF={selectedPDF}
			openPdfDialog={openPdfDialog}
			handleAddPdfRow={handleAddPdfRow}
			handleDeletePdfRow={handleDeletePdfRow}
			handleSubmit={handleSubmit}
			edited={edited}
		/>
	);
}

export default EditHousingInvoices;
