import React, { useState } from "react";
import ExpertAccountPageUI from "./ExpertAccountPageUI";
import axios from "axios";
import Swal from "sweetalert2";
import createExpertProfile from "../../../networking/endpoints/user/createExpertProfile";
import { useSelector, useDispatch } from "react-redux";
import { initUser } from "../../../redux/slices/userReducer";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";

function ExpertAccountPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token, id } = useSelector((state) => state.userReducer);
	const [province, setProvince] = useState("");
	const [locality, setLocality] = useState("");
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [localities, setLocalities] = useState([]);
	const [linkedinUrl, setLinkedinUrl] = useState("");
	const [facebookUrl, setFacebookUrl] = useState("");
	const [skills, setSkills] = useState([]);

	const capitalizeFirstLetter = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	};

	const handleSubmit = async () => {
		setLoading(true); // Set loading to true
		try {
			const userInfo = {
				expertTitle: title,
				expertDescription: description,
				address: { province: province, locality: locality },
				linkedinUrl: linkedinUrl,
				facebookUrl: facebookUrl,
				skills: skills,
			};
			const response = await createExpertProfile(token, id, userInfo);
			dispatch(initUser(response));
			Swal.fire({
				icon: "success",
				title: "¡Perfil de experto creado!",
				text: "¡Ya podés empezar a ofrecer tus servicios!",
			});
			navigate(constants.PAGES.ACCOUNT);
		} catch (error) {
			if (error.code === 1) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error,
				});
			}
		} finally {
			setLoading(false); // Set loading back to false
		}
	};

	const handleProvinceChange = async (province) => {
		setLoading(true); // Set loading to true

		setProvince(province);
		try {
			const response = await axios.get(
				`https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&max=1000`
			);

			const localitiesData = response.data.localidades;
			const formattedLocalities = localitiesData.map((locality) =>
				capitalizeFirstLetter(locality.nombre.toLowerCase())
			);

			// Sort the formattedLocalities array in alphabetical order
			formattedLocalities.sort((a, b) => a.localeCompare(b));

			setLocalities(formattedLocalities);
			// Reset the selected locality
			setLocality("");
		} catch (error) {
			console.log(error);
			if (error.code === 1) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "No se pudo cargar las localidades",
				});
			}
		}

		setLoading(false); // Set loading back to false
	};

	return (
		<ExpertAccountPageUI
			province={province}
			locality={locality}
			loading={loading}
			title={title}
			description={description}
			handleSubmit={handleSubmit}
			setProvince={setProvince}
			setLocality={setLocality}
			setTitle={setTitle}
			setDescription={setDescription}
			handleProvinceChange={handleProvinceChange}
			localities={localities}
			linkedinUrl={linkedinUrl}
			setLinkedinUrl={setLinkedinUrl}
			facebookUrl={facebookUrl}
			setFacebookUrl={setFacebookUrl}
			skills={skills}
			setSkills={setSkills}
		/>
	);
}

export default ExpertAccountPage;
