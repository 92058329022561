import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Card,
	CardMedia,
} from "@mui/material";
import { Delete, PhotoCamera } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";

function InputRow({
	rows,
	selectedImage,
	openImageDialog,
	handleAddRow,
	handleDeleteRow,
	handleImageChange,
	handleImageClick,
	handleCloseImageDialog,
}) {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">Marca</TableCell>
						<TableCell align="center">Modelo</TableCell>
						<TableCell align="center">Dispositivo</TableCell>
						<TableCell align="center">Eficiencia</TableCell>
						<TableCell align="center">Consumo</TableCell>
						<TableCell align="center">Imagen</TableCell>
						<TableCell align="center">Borrar</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.id}>
							<TableCell align="center">{row.marca}</TableCell>
							<TableCell align="center">{row.modelo}</TableCell>
							<TableCell align="center">
								{row.dispositivo}
							</TableCell>
							<TableCell align="center">
								{row.eficiencia}
							</TableCell>
							<TableCell align="center">{row.consumo} {row.consumo ? "kW/h" : undefined }</TableCell>
							<TableCell align="center">
								{row.image ? (
									<Card
										onClick={() =>
											handleImageClick(row.image)
										}
									>
										<CardMedia
											component="img"
											alt="Uploaded Image"
											image={row.image}
											sx={{
												maxHeight: "80px",
											}}
										/>
									</Card>
								) : (
									<input
										type="file"
										accept="image/*"
										onChange={(event) =>
											handleImageChange(event, row.id)
										}
										style={{ display: "none" }}
										id={`image-upload-${row.id}`}
									/>
								)}
								<Tooltip title="Subir Imagen">
									<label htmlFor={`image-upload-${row.id}`}>
										<IconButton component="span">
											{row.image ? null : <PhotoCamera />}
										</IconButton>
									</label>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title="Borrar">
									<IconButton
										onClick={() => handleDeleteRow(row.id)}
									>
										<Delete />
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Button
				variant="contained"
				onClick={handleAddRow}
				sx={{
					margin: "1rem",
				}}
			>
				Agregar Dispositivo
			</Button>
			<Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
				<DialogTitle>Image Preview</DialogTitle>
				<DialogContent>
					<Card>
						<CardMedia
							component="img"
							alt="Uploaded Image"
							image={selectedImage}
						/>
					</Card>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseImageDialog} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</TableContainer>
	);
}

export default InputRow;
