import React, { useState } from "react";
import chatbotImage from "../../../assets/images/chatbot.png";

const PvaBotComponent = () => {
	const [isChatbotOpen, setIsChatbotOpen] = useState(false);

	const toggleChatbot = () => {
		setIsChatbotOpen(!isChatbotOpen);
	};

	// Customize the iframe URL with query parameters or other data as needed
	const iframeUrl =
		"https://web.powerva.microsoft.com/environments/Default-344979d0-d31d-4c57-8ba0-491aff4acaed/bots/crc31_inglesito/webchat?__version__=2";

	return (
		<div className="chatbot">
			{isChatbotOpen ? (
				<div
					style={{
						width: "350px",
						height: "450px",
						position: "fixed",
						bottom: "20px",
						right: "20px",
						zIndex: 9999,
						backgroundColor: "white",
					}}
				>
					<iframe
						src={iframeUrl}
						style={{
							width: "100%",
							height: "100%",
							border: "none",
						}}
						title="Chatbot"
					></iframe>
					<button
						onClick={toggleChatbot}
						style={{
							position: "absolute",
							top: "10px",
							right: "10px",
							backgroundColor: "#1D3622",
							color: "white",
							border: "none",
							borderRadius: "50%",
							width: "30px",
							height: "30px",
							cursor: "pointer",
						}}
					>
						-
					</button>
				</div>
			) : (
				<div
					className="chatbot-icon"
					onClick={toggleChatbot}
					style={{
						position: "fixed",
						bottom: "20px",
						right: "20px",
						borderRadius: "100%",
						backgroundColor: "#1D3622",
						width: "70px",
						height: "70px",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						cursor: "pointer",
					}}
				>
					<img
						src={chatbotImage}
						alt="Chatbot"
						width="50"
						height="50"
					/>
				</div>
			)}
		</div>
	);
};

export default PvaBotComponent;
