import React, { useState, useEffect } from "react";
import CompleteHousingDetailsUI from "./CompleteHousingDetailsUI";
import Swal from "sweetalert2";
import readEfficiencyTag from "../../../networking/endpoints/formEndpoint/readEfficiencyTag";
import uploadDevices from "../../../networking/endpoints/housing/uploadDevices";
import { useParams } from "react-router-dom";
import readInvoice from "../../../networking/endpoints/formEndpoint/readInvoice";
import uploadInvoices from "../../../networking/endpoints/housing/uploadInvoices";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";

function CompleteHousingDetails() {
	const navigate = useNavigate();
	const { housingId } = useParams();
	const token = localStorage.getItem("authToken");
	const [loading, setLoading] = useState(false);
	const [activeStep, setActiveStep] = useState(2);
	const [selectedPDF, setSelectedPDF] = useState(null); // Selected PDF to display
	const [openPdfDialog, setOpenPdfDialog] = useState(false);
	const [isMobileScreen, setIsMobileScreen] = useState(
		window.innerWidth <= 600
	);
	const [rows, setRows] = useState([]);
	const [invoiceRows, setInvoiceRows] = useState([]);

	const [selectedImage, setSelectedImage] = useState(null);
	const [openImageDialog, setOpenImageDialog] = useState(false);

	useEffect(() => {
		const handleResize = () => {
			setIsMobileScreen(window.innerWidth <= 600);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handlePdfChange = async (event, id) => {
		const file = event.target.files[0];
		try {
			setLoading(true);
			let formData = new FormData();
			formData.append("pdf", file);
			const response = await readInvoice(token, formData);
			const companyName = response.fields.VendorName.value;
			const date = response.fields.DueDate.value.split("T")[0];
			const total = response.fields.InvoiceTotal.value.amount;
			const updatedRows = invoiceRows.map((row) => {
				if (row.id === id) {
					const pdfBlob = new Blob([file], {
						type: "application/pdf",
					});
					const pdfUrl = URL.createObjectURL(pdfBlob);
					return {
						...row,
						empresa: companyName,
						fecha: date,
						total: total,
						pdf: pdfUrl,
						pdfFile: file,
					};
				}
				return row;
			});
			setInvoiceRows(updatedRows);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};

	// Function to handle clicking on a PDF preview
	const handlePdfClick = (pdfUrl) => {
		setSelectedPDF(pdfUrl);
		setOpenPdfDialog(true);
	};

	// Function to close the PDF dialog
	const handleClosePdfDialog = () => {
		setSelectedPDF(null);
		setOpenPdfDialog(false);
	};

	const handleAddRow = () => {
		const newRow = {
			id: Date.now(),
			marca: "",
			modelo: "",
			dispositivo: "",
			eficiencia: "",
			consumo: "",
			image: null,
			imageFile: null,
		};
		setRows([...rows, newRow]);
	};

	const handleAddPdfRow = () => {
		const newRow = {
			id: Date.now(),
			empresa: "",
			fecha: "",
			total: "",
			pdf: null,
			pdfFile: null,
		};
		setInvoiceRows([...invoiceRows, newRow]);
	};

	const handleDeletePdfRow = (id) => {
		const updatedRows = invoiceRows.filter((row) => row.id !== id);
		setInvoiceRows(updatedRows);
	};

	const handleDeleteRow = (id) => {
		const updatedRows = rows.filter((row) => row.id !== id);
		setRows(updatedRows);
	};

	const handleImageChange = async (event, id) => {
		const file = event.target.files[0];
		if (!file) return;

		try {
			setLoading(true);
			let formData = new FormData();
			formData.append("image", file);

			const response = await readEfficiencyTag(token, formData);

			const deviceBrand = response.fields.device_brand.value;
			const deviceModel = response.fields.device_model.value;
			const deviceType = response.fields.device_type.value;
			const efficiencyLevel = response.fields.efficiency_level.value;
			const energyConsumption = response.fields.energy_consumption.value;

			const updatedRows = rows.map((row) => {
				if (row.id === id) {
					return {
						...row,
						marca: deviceBrand,
						modelo: deviceModel,
						dispositivo: deviceType,
						eficiencia: efficiencyLevel,
						consumo: energyConsumption,
						image: URL.createObjectURL(file),
						imageFile: file,
					};
				}
				return row;
			});
			setRows(updatedRows);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};

	const uploadHousingData = async () => {
		try {
			if (rows.length > 0) {
				let formData = new FormData();

				for (const row of rows) {
					if (
						row.marca &&
						row.modelo &&
						row.dispositivo &&
						row.eficiencia &&
						row.consumo &&
						row.imageFile
					) {
						formData.append("housingId", housingId);
						formData.append("brand", row.marca);
						formData.append("model", row.modelo);
						formData.append("deviceType", row.dispositivo);
						formData.append("efficiencyLevel", row.eficiencia);
						formData.append("energyConsumption", row.consumo);
						formData.append("imageFile", row.imageFile);
						await uploadDevices(token, formData);
						formData = new FormData();
					}
				}
			}
			if (invoiceRows.length > 0) {
				await uploadInvoices(token, invoiceRows, housingId);
			}

			setLoading(false);
			navigate(constants.PAGES.USER_HOUSINGS);
			Swal.fire({
				icon: "success",
				title: "¡Listo!",
				text: "Se han adherido los datos correctamente.",
			});
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
		setOpenImageDialog(true);
	};

	const handleCloseImageDialog = () => {
		setOpenImageDialog(false);
	};

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	return (
		<CompleteHousingDetailsUI
			invoiceRows={invoiceRows}
			rows={rows}
			selectedImage={selectedImage}
			openImageDialog={openImageDialog}
			handleDeleteRow={handleDeleteRow}
			handleImageClick={handleImageClick}
			handleCloseImageDialog={handleCloseImageDialog}
			handleAddRow={handleAddRow}
			handleImageChange={handleImageChange}
			loading={loading}
			activeStep={activeStep}
			isMobileScreen={isMobileScreen}
			handleNext={handleNext}
			handleBack={handleBack}
			handleSubmit={uploadHousingData}
			handlePdfChange={handlePdfChange}
			handlePdfClick={handlePdfClick}
			handleClosePdfDialog={handleClosePdfDialog}
			selectedPDF={selectedPDF}
			openPdfDialog={openPdfDialog}
			handleAddPdfRow={handleAddPdfRow}
			handleDeletePdfRow={handleDeletePdfRow}
		/>
	);
}

export default CompleteHousingDetails;
