import React from "react";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "@mui/material";
import constants from "../../../config/constants";
import { NavLink } from "react-router-dom";
import "./NavBarUserAccount.css";

function NavigatorTable() {
	// Check if the screen size matches a phone breakpoint (adjust the breakpoint as needed)
	const isPhoneSize = useMediaQuery("(max-width: 600px)");

	return (
		<div className="navigatorContainer">
			<NavLink
				to={constants.PAGES.ACCOUNT}
				className="navLink" // Add a custom class name for the NavLink
			>
				{constants.MISC_TEXT.PERSONAL_DATA}
			</NavLink>
			<Divider
				orientation={!isPhoneSize ? "vertical" : "horizontal"}
				flexItem={!isPhoneSize}
				variant="middle"
			/>
			<NavLink
				to={constants.PAGES.CHANGE_PASSWORD}
				className="navLink" // Add a custom class name for the NavLink
			>
				{constants.MISC_TEXT.CHANGE_PASSWORD}
			</NavLink>
			<Divider
				orientation={!isPhoneSize ? "vertical" : "horizontal"}
				flexItem={!isPhoneSize}
				variant="middle"
			/>
			<NavLink
				to={constants.PAGES.CHANGE_EMAIL}
				className="navLink" // Add a custom class name for the NavLink
			>
				{constants.MISC_TEXT.CHANGE_EMAIL}
			</NavLink>
			<Divider
				orientation={!isPhoneSize ? "vertical" : "horizontal"}
				flexItem={!isPhoneSize}
				variant="middle"
			/>
			<NavLink
				to={constants.PAGES.DELETE_ACCOUNT}
				className="navLink" // Add a custom class name for the NavLink
			>
				{constants.MISC_TEXT.DELETE_ACCOUNT}
			</NavLink>
		</div>
	);
}

export default NavigatorTable;
