import React from "react";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import NavigatorTable from "../../components/NavBarHousing/NavBarHousing";
import InvoicesTable from "../../components/InvoicesTable/InvoicesTable";
import DevicesTable from "../../components/DevicesTable/DevicesTable";
import { Typography } from "@mui/material";
function HousingDevicesUI({
	loading,
	housingId,
	devicesRows,
	invoicesRows,
	openImageDialog,
	handleImageClick,
	handleCloseImageDialog,
	selectedImage,
}) {
	return (
		<>
			<LoadingOverlay isLoading={loading} />
			<NavigatorTable housingId={housingId} />
			{devicesRows.length > 0 || invoicesRows.length > 0 ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
						marginTop: "2rem",
						gap: "2rem",
						padding: "0 2rem",
					}}
				>
					{devicesRows.length > 0 && (
						<>
							<Typography variant="h5" fontWeight="bold">
								Dispositivos
							</Typography>
							<DevicesTable
								rows={devicesRows}
								openImageDialog={openImageDialog}
								handleImageClick={handleImageClick}
								handleCloseImageDialog={handleCloseImageDialog}
								selectedImage={selectedImage}
							/>
						</>
					)}
					{invoicesRows.length > 0 && (
						<>
							<Typography variant="h5" fontWeight="bold">
								Facturas
							</Typography>
							<InvoicesTable rows={invoicesRows} />
						</>
					)}
				</div>
			) : (
				<div
					style={{
						display: "flex",
						padding: "1rem",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						height: "60vh",
					}}
				>
					<Typography
						variant="h6"
						fontSize="2rem"
						fontWeight="bold"
						gutterBottom
						style={{ textAlign: "center" }}
					>
						No hay registros
					</Typography>
					<img
						src={require("../../../assets/images/caja-vacia.png")}
						alt="empty"
						width="300px"
					/>
				</div>
			)}
		</>
	);
}

export default HousingDevicesUI;
