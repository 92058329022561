import React, { useState } from "react";
import LogInPageUI from "./LogInPageUI";
import loginUser from "../../../networking/endpoints/user/logIn";
import Swal from "sweetalert2";
import { initUser } from "../../../redux/slices/userReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";
import googleSignIn from "../../../networking/endpoints/user/googleSignIn";

function LogInPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const handleLogin = async () => {
		try {
			setLoading(true);
			// Call the loginUser API function to perform login
			const response = await loginUser(email, password);
			// Dispatch the initUser action to save the user data and token in the Redux store
			dispatch(initUser(response));
			localStorage.setItem("authToken", response.token);

			navigate(constants.PAGES.ACCOUNT);
		} catch (error) {
			// If there's an error, display it using SweetAlert2
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleGoogleLogIn = async (credentialResponse) => {
		setLoading(true);
		try {
			const response = await googleSignIn(credentialResponse.credential);

			dispatch(initUser(response));
			localStorage.setItem("authToken", response.token);
			navigate(constants.PAGES.ACCOUNT);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleGoogleFailure = () => {
		Swal.fire({
			icon: "error",
			title: "Error",
			text: "Login with Google failed",
		});
	};

	return (
		<LogInPageUI
			email={email}
			setEmail={setEmail}
			password={password}
			setPassword={setPassword}
			onSubmit={handleLogin}
			loading={loading}
			handleGoogleLogin={handleGoogleLogIn}
			handleGoogleFailure={handleGoogleFailure}
		/>
	);
}

export default LogInPage;
