import React, { useState } from "react";
import ForgotPasswordPageUI from "./ForgotPasswordPageUI";
import forgotPassword from "../../../networking/endpoints/user/forgotPassword";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";

function ForgotPasswordPage() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState("");

	const onSubmit = async () => {
		setLoading(true);
		try {
			const response = await forgotPassword(email);
			Swal.fire({
				icon: "success",
				title: response.message,
				showConfirmButton: false,
				timer: 1500,
			});
			navigate(constants.PAGES.LOG_IN);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: error,
				showConfirmButton: false,
				timer: 1500,
			});
		}
		setLoading(false);
	};

	return (
		<ForgotPasswordPageUI
			loading={loading}
			setEmail={setEmail}
			email={email}
			onSubmit={onSubmit}
		/>
	);
}

export default ForgotPasswordPage;
