const constants = {
	MISC_TEXT: {
		APP_NAME: "Ecohousing",
		COPYRIGHT:
			"Copyright © Ecohousing Tecnologies S.A de C.V 2023. All Rights Reserved.",
		WHAT_WE_DO:
			"Ecohousing Centraliza Viviendas Sustentables, Expertos,Vendedores y Compradores",
		WHAT_MAKES_US_DIFFERENT:
			"Nos esforzamos por promover y ofrecer viviendas sostenibles y respetuosas con el medio ambiente. Nuestro catálogo incluye una selección de propiedades que cumplen con criterios ecológicos, como la eficiencia energética, el uso de materiales eco-amigables y sistemas de energía renovable.",
		WHAT_MAKES_US_DIFFERENT_2:
			"Creemos en la importancia de vivir de manera sostenible y queremos facilitar a nuestros usuarios la búsqueda de hogares que reflejen sus valores ecológicos. Al elegir una vivienda a través de nuestra plataforma, los usuarios pueden contribuir activamente a la protección del medio ambiente y reducir su huella ecológica.",
		WHAT_MAKES_US_DIFFERENT_TITLE: "¿Qué nos hace diferentes?",
		CREATE_YOUR_ACCOUNT_TODAY:
			"¿Que esperas para crear tu cuenta? ¡Inicia hoy mismo, es gratis!",
		MEET_PRONEV: "Conoce el Pronev",
		SIGNUP: "Regístrate",
		PERSONAL_DATA: "Datos Personales",
		CHANGE_PASSWORD: "Cambiar Contraseña",
		CHANGE_EMAIL: "Cambiar Email",
		DELETE_ACCOUNT: "Eliminar Cuenta",
		WANT_TO_CREATE_TECHNICAL_ACCOUNT:
			"¿Quieres crear una cuenta de técnico?",
		WANT_TO_EDIT_TECHNICAL_ACCOUNT: "Opciones de cuenta de técnico",
		ENTER_NEW_PASSWORD: "Ingresa tu nueva contraseña:",
		EMAIL_CHANGED: "Tu correo electrónico ha sido actualizado",
		DELETE_ACCOUNT_WARNING:
			"Al eliminar tu cuenta de Ecohousing se eliminara tu perfil y tus publicaciones. ¿Estás seguro de que deseas continuar?",
		COMPLETE_DATA: "Completa tus datos",
		PROVINCE: "Provincia",
		LOCALITY: "Localidad",
		REVIEW_QUESTION: "¿Que te pareció la asesoría de ",
		HOUSING_PAGE: "Vivienda",
		HOUSING_DEVICES: "Gastos Adicionales",
		EDIT_HOUSING: "Editar Vivienda",
		EDIT_HOUSING_DEVICES: "Editar Dispositivos",
		EDIT_HOUSING_INVOICES: "Editar Facturas",
	},
	PAGES: {
		LANDING: "/",
		LOG_IN: "/login",
		SIGN_UP: "/signup",
		PUBLISH_HOUSE: "/publish-house",
		SEARCH_HOUSES: "/search-houses",
		SEARCH_EXPERTS: "/search-experts",
		ACCOUNT: "/account",
		FAVORITE_EXPERTS: "/favorite-experts",
		FAVORITE_HOUSINGS: "/favorite-housings",
		FAVORITES: "/favorites",
		LOG_OUT: "/logout",
		FORGOT_MY_PASSWORD: "/forgot-my-password",
		RESET_PASSWORD: "/reset-password",
		DELETE_ACCOUNT: "/delete-account",
		CHANGE_PASSWORD: "/change-password",
		CHANGE_EMAIL: "/change-email",
		CREATE_TECHNICAL_ACCOUNT: "/create-technical-account",
		EXPERT_PROFILE: "/expert-profile",
		CONTACT_EXPERT: "/contact-expert",
		TERMS_AND_CONDITIONS: "/terms-and-conditions",
		EDIT_EXPERT_PROFILE: "/edit-expert-profile",
		REVIEW_EXPERT: "/review-expert",
		HOUSING_PAGE: "/housing-page",
		USER_HOUSINGS: "/user-housings",
		EDIT_HOUSING: "/edit-housing",
		COMPLETE_HOUSING_DATA: "/complete-housing-data",
		HOUSING_DEVICES: "/housing-devices",
		HOUSING_INVOICES: "/housing-invoices",
		EDIT_HOUSING_DEVICES: "/edit-housing-devices",
		EDIT_HOUSING_INVOICES: "/edit-housing-invoices",
	},
	BUTTON_NAMES: {
		LOG_IN: "Ingresar",
		SIGN_UP: "Registrarse",
		PUBLISH_HOUSE: "Publicar Vivienda",
		SEARCH_HOUSES: "Buscar Viviendas",
		SEARCH_EXPERTS: "Buscar Expertos",
		CREATE_ACCOUNT: "Crear Cuenta",
		SIGN_IN: "Iniciar Sesión",
		ALREADY_HAVE_AN_ACCOUNT: "¿Ya tienes una cuenta? Inicia Sesión",
		DONT_HAVE_AN_ACCOUNT: "¿No tienes una cuenta? Regístrate",
		FORGOT_MY_PASSWORD: "Olvidé mi contraseña",
		SEND: "Enviar",
		RESET_PASSWORD: "Restablecer Contraseña",
		SAVE: "Guardar",
		DELETE_ACCOUNT: "Eliminar Cuenta",
		DELETE_EXPERT_ACCOUNT: "Eliminar Cuenta de Técnico",
		EDIT_EXPERT_ACCOUNT: "Editar Cuenta de Técnico",
		CREATE_EXPERT_PROFILE: "Crear Perfil de Técnico",
		VIEW_EXPERT_PROFILE: "Ver Perfil Técnico",
	},
	DROPDOWN_MENU: {
		ACCOUNT: "Cuenta",
		FAVORITES: "Favoritos",
		MY_PUBLICATIONS: "Mis Publicaciones",
		LOG_OUT: "Cerrar Sesión",
	},
	ERRORS: {
		EMAILS_DONT_MATCH: "Los correos no coinciden",
	},
	PROVINCES: [
		"Buenos Aires",
		"Catamarca",
		"Chaco",
		"Chubut",
		"Ciudad Autónoma de Buenos Aires",
		"Córdoba",
		"Corrientes",
		"Entre Ríos",
		"Formosa",
		"Jujuy",
		"La Pampa",
		"La Rioja",
		"Mendoza",
		"Misiones",
		"Neuquén",
		"Río Negro",
		"Salta",
		"San Juan",
		"San Luis",
		"Santa Cruz",
		"Santa Fe",
		"Santiago del Estero",
		"Tierra del Fuego",
		"Tucumán",
	],
	EXPERT_SKILLS: [
		"Instalaciones Eléctricas",
		"Domotización de hogares",
		"Dispositivos Solares",
		"Gestión del agua",
		"Diseño Bioclimático",
		"Energía Geotérmica",
	],
	TAG_OPTIONS: [
		"Paneles Solares",
		"Luces LED",
		"Reciclaje de Agua",
		"Aislamiento Térmico",
		"Eficiencia Energética",
		"Jardín Sostenible",
		"Compostaje",
		"Uso de Materiales Reciclados",
	],
	ALERT_TEXT: {
		TERMS_AND_CONDITIONS_NOTIFIER:
			"Al completar y enviar esta solicitud, estas aceptando nuestros",

		ADDRESS_USAGE_NOTIFIER:
			"No te preocupes. No usaremos la dirección completa. Se mostrara un estimado.",

		DEVICES_NOTIFIER:
			"Esta seccion es opcional, pero ayuda a darle mayor visibilidad ecologica a tu vivienda.",

		INVOICES_NOTIFIER:
			"Esta seccion tambien es opcional, pero permite al usuario tener visibilidad acerca de los gastos adicionales de la vivienda.",

		SENSIBLE_DATA_NOTIFIER:
			"Por favor cubre los campos que tengan informacion sensible.",
	},
};

export default constants;
