import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function deleteDevice(token, deviceId) {
	api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	try {
		const response = await api.delete(
			configuration.BASE_URL + "devices/" + deviceId
		);
		const data = await response.data;

		return data;
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
