import React, { useState, useEffect } from "react";
import CreatePropertyPageUI from "./CreatePropertyPageUI";
import Swal from "sweetalert2";
import axios from "axios";
import createHousing from "../../../networking/endpoints/housing/createHousing";
import constants from "../../../config/constants";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function CreatePropertyPage() {
	const token = localStorage.getItem("authToken");
	const { id } = useSelector((state) => state.userReducer);
	const navigate = useNavigate();
	const [activeStep, setActiveStep] = useState(0);
	const [housingType, setHousingType] = useState("");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [publicationType, setPublicationType] = useState("");
	const [numRooms, setNumRooms] = useState(""); //ambientes
	const [numBedrooms, setNumBedrooms] = useState("");
	const [size, setSize] = useState("");
	const [price, setPrice] = useState("");
	const [tags, setTags] = useState([]); //etiquetas
	const [images, setImages] = useState([]); //imagenes
	const [province, setProvince] = useState("");
	const [localities, setLocalities] = useState([]);
	const [locality, setLocality] = useState("");
	const [loading, setLoading] = useState(false);
	const [street, setStreet] = useState("");
	const [streetNumber, setStreetNumber] = useState("");
	const [efficiencyLevel, setEfficiencyLevel] = useState("");

	const [isMobileScreen, setIsMobileScreen] = useState(
		window.innerWidth <= 600
	);

	const capitalizeFirstLetter = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	};

	useEffect(() => {
		const handleResize = () => {
			setIsMobileScreen(window.innerWidth <= 600);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setLoading(true);
			const housingData = new FormData();
			housingData.append("title", title);
			housingData.append("description", description);
			housingData.append("housingType", housingType);
			housingData.append("listingType", publicationType);
			housingData.append("numRooms", numRooms);
			housingData.append("numBedrooms", numBedrooms);
			housingData.append("size", size);
			housingData.append("price", price);
			tags.forEach((value) => {
				housingData.append("tags[]", value);
			});
			housingData.append("address[street]", street);
			housingData.append("address[locality]", locality);
			housingData.append("address[province]", province);
			housingData.append("address[number]", streetNumber);
			images.forEach((value) => {
				housingData.append("images[]", value);
			});
			housingData.append("owner", id);
			if (efficiencyLevel !== "") {
				housingData.append("energyEfficiencyLevel", efficiencyLevel);
			}
			const response = await createHousing(token, housingData);
			const housingId = response.housing._id;

			Swal.fire({
				icon: "question",
				title: "¡Propiedad creada con éxito!",
				text: "¿Desea completar los datos de consumo de la propiedad ahora?",
				showCancelButton: true,
				confirmButtonText: "Sí",
				cancelButtonText: "No",
			}).then((result) => {
				if (result.isConfirmed) {
					navigate(
						constants.PAGES.COMPLETE_HOUSING_DATA + "/" + housingId
					);
				} else {
					navigate(constants.PAGES.USER_HOUSINGS);
				}
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleNext = () => {
		//check if required fields are filled
		if (
			activeStep === 0 &&
			(title === "" ||
				description === "" ||
				housingType === "" ||
				publicationType === "" ||
				numRooms === 0 ||
				numBedrooms === 0 ||
				size === 0 ||
				price === 0 ||
				tags.length === 0 ||
				images.length === 0)
		) {
			Swal.fire({
				icon: "warning",
				title: "Oops...",
				text: "Debe completar todos los campos obligatorios",
			});
			return;
		}
		if (
			activeStep === 1 &&
			(province === "" ||
				locality === "" ||
				street === "" ||
				streetNumber === "")
		) {
			Swal.fire({
				icon: "warning",
				title: "Oops...",
				text: "Debe completar todos los campos obligatorios",
			});
			return;
		}
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	const handleImageDrop = (acceptedFiles) => {
		if (images.length + acceptedFiles.length <= 6) {
			setImages([...images, ...acceptedFiles]);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Limite Alcanzado",
				text: "Se pueden subir hasta 6 imagenes",
				confirmButtonText: "OK",
			});
		}
	};

	const handleImageRemove = (index) => {
		const newImages = [...images];
		newImages.splice(index, 1);
		setImages(newImages);
	};

	const handleProvinceChange = async (province) => {
		setLoading(true); // Set loading to true

		setProvince(province);
		try {
			const response = await axios.get(
				`https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&max=1000`
			);

			const localitiesData = response.data.localidades;
			const formattedLocalities = localitiesData.map((locality) =>
				capitalizeFirstLetter(locality.nombre.toLowerCase())
			);

			// Sort the formattedLocalities array in alphabetical order
			formattedLocalities.sort((a, b) => a.localeCompare(b));

			setLocalities(formattedLocalities);
			// Reset the selected locality
			setLocality("");
		} catch (error) {
			console.log(error);
			if (error.code === 1) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "No se pudo cargar las localidades",
				});
			}
		}

		setLoading(false); // Set loading back to false
	};
	return (
		<CreatePropertyPageUI
			handleSubmit={handleSubmit}
			activeStep={activeStep}
			handleBack={handleBack}
			handleNext={handleNext}
			title={title}
			setTitle={setTitle}
			description={description}
			setDescription={setDescription}
			publicationType={publicationType}
			setPublicationType={setPublicationType}
			numRooms={numRooms}
			setNumRooms={setNumRooms}
			numBedrooms={numBedrooms}
			setNumBedrooms={setNumBedrooms}
			size={size}
			setSize={setSize}
			price={price}
			setPrice={setPrice}
			tags={tags}
			setTags={setTags}
			images={images}
			setImages={setImages}
			handleImageDrop={handleImageDrop}
			handleImageRemove={handleImageRemove}
			isMobileScreen={isMobileScreen}
			handleProvinceChange={handleProvinceChange}
			province={province}
			localities={localities}
			locality={locality}
			setLocality={setLocality}
			loading={loading}
			street={street}
			setStreet={setStreet}
			streetNumber={streetNumber}
			setStreetNumber={setStreetNumber}
			efficiencyLevel={efficiencyLevel}
			setEfficiencyLevel={setEfficiencyLevel}
			housingType={housingType}
			setHousingType={setHousingType}
		/>
	);
}

export default CreatePropertyPage;
