import React from "react";
import NavBarUserAccount from "../../components/NavBarUserAccount/NavBarUserAccount";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import constants from "../../../config/constants";
import { Alert } from "@mui/material";

function ChangePasswordUI({
	loading,
	onSubmit,
	password,
	setPassword,
	setNewPassword,
	setRepeatPassword,
	newPassword,
	repeatPassword,
	googleId,
}) {
	const theme = useTheme();
	return (
		<>
			<LoadingOverlay isLoading={loading} />
			<NavBarUserAccount />
			<Container component="main" maxWidth="md">
				<CssBaseline />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography component="h5" variant="h5">
						{constants.MISC_TEXT.CHANGE_PASSWORD}
					</Typography>

					<Box
						component="form"
						onSubmit={(e) => {
							e.preventDefault(); // Prevent default form submission
							onSubmit(); // Call the onSubmit function passed as a prop
						}}
						noValidate
						sx={{ mt: 1 }}
					>
						{googleId && (
							<Alert
								severity="warning"
								sx={{ mt: "1rem", mb: "1rem" }}
							>
								No disponible para cuentas creadas con google
							</Alert>
						)}
						<TextField
							margin="normal"
							required
							fullWidth
							name="actual-password"
							label="Contraseña Actual"
							type="password"
							id="actual-password"
							autoComplete="current-password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							disabled={loading || googleId !== null}
						/>

						<Typography component="p" variant="p">
							{constants.MISC_TEXT.ENTER_NEW_PASSWORD}
						</Typography>
						<TextField
							margin="normal"
							required
							fullWidth
							name="new-password"
							label="Contraseña Nueva"
							type="password"
							id="new-password"
							autoComplete="current-password"
							value={newPassword}
							onChange={(e) => setNewPassword(e.target.value)}
							disabled={loading || googleId !== null}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							name="repeat-password"
							label="Repita Contraseña Nueva"
							type="password"
							id="repeat-password"
							autoComplete="current-password"
							value={repeatPassword}
							onChange={(e) => setRepeatPassword(e.target.value)}
							disabled={loading || googleId !== null}
						/>
						<Box
							sx={{
								display: "flex",
								alignItems: "center", // Center the loading spinner vertically
								justifyContent: "center", // Center the loading spinner horizontally
								flexDirection: "column", // Stack the loading spinner on top of the button
							}}
						>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									backgroundColor: `${theme.palette.secondary.main} !important`,
								}}
								disabled={loading || googleId !== null}
							>
								{constants.BUTTON_NAMES.SAVE}
							</Button>
						</Box>
					</Box>
				</Box>
				<LoadingOverlay isLoading={loading} />
			</Container>
		</>
	);
}

export default ChangePasswordUI;
