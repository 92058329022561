import React from "react";
import EditHousingNavBar from "../../components/EditHousingNavBar/EditHousingNavBar";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import EditInvoicesTable from "../../components/EditInvoicesTable/EditInvoicesTable";
import { Typography, Button } from "@mui/material";

function EditHousingInvoicesUI({
	loading,
	housingId,
	handleSubmit,
	invoiceRows,
	handlePdfChange,
	handlePdfClick,
	handleClosePdfDialog,
	selectedPDF,
	openPdfDialog,
	handleAddPdfRow,
	handleDeletePdfRow,
	edited,
}) {
	return (
		<>
			<LoadingOverlay isLoading={loading} />
			<EditHousingNavBar housingId={housingId} />
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					marginTop: "2rem",
					gap: "2rem",
					padding: "0 2rem",
				}}
			>
				<Typography variant="h5" fontWeight="bold">
					Facturas
				</Typography>

				<EditInvoicesTable
					openPdfDialog={openPdfDialog}
					selectedPDF={selectedPDF}
					key={invoiceRows.id}
					rows={invoiceRows}
					handlePdfChange={handlePdfChange}
					handlePdfClick={handlePdfClick}
					handleDeleteRow={handleDeletePdfRow}
					handleAddRow={handleAddPdfRow}
					handleClosePdfDialog={handleClosePdfDialog}
				/>
				{edited ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "2rem",
						}}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleSubmit}
						>
							Subir facturas
						</Button>
					</div>
				) : null}
			</div>
		</>
	);
}

export default EditHousingInvoicesUI;
