import styled from "@emotion/styled";
import {
	Avatar,
	Button,
	Card,
	CardActions,
	CardContent,
	Chip,
	Divider,
	Grid,
	Typography,
	Rating,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { Link } from "react-router-dom";
import constants from "../../../config/constants";

export default function MentorCard({ data }) {
	const StyledCard = styled(Card)`
		border-radius: 1rem;
		border: 1.5px solid #2d8d59;
		background-color: #eeeeee50;
	`;

	return (
		<StyledCard
			sx={{
				minWidth: 275,
				maxWidth: {
					xs: "unset",
					md: 330,
				},
				height: 550,
			}}
			elevation={0}
		>
			<CardContent>
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: "repeat(3, 20% 50% 30%)",
						gap: 1,
						gridTemplateRows: "100px auto auto ",
						gridTemplateAreas: {
							md: `
                "sider sider sider"
                "sider sider sider"
                "main main main"
                "main main main"
                  `,
							xs: `
              "sider sider sider"
              "sider sider sider"
              "main main main"
              "main main main"
                `,
						},
					}}
				>
					<Box
						sx={{
							maxHeight: "230px",
							gridArea: "sider",
							borderRight: "1px solid",
							borderColor: "grey.200",
							pr: 1,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Rating
							value={data.rating}
							readOnly
							size="medium"
							precision={0.5}
						/>
						<Box
							sx={{
								height: 300,
								width: "min(100%, 200px)",
							}}
						>
							<Avatar
								variant="circular"
								src={data.profileImageUrl}
								sx={{
									width: "min(100%, 200px)",
									height: "min(100%, 200px)",
									fontSize: "2rem",
									alignSelf: "center",
									objectFit: "cover",
								}}
							/>
						</Box>
					</Box>
					<Box
						sx={{
							gridArea: "main",
							borderRight: "1px solid",
							borderColor: "grey.200",
							pr: 1,
						}}
					>
						<Stack>
							<Typography color="primary.dark" variant="h5">
								{data.firstName + " " + data.lastName}
							</Typography>
							<Box
								sx={{
									minHeight: 50,
								}}
							>
								<Typography color="primary.dark" gutterBottom>
									{data.expertTitle}
								</Typography>
							</Box>

							<Divider sx={{ mb: 1 }} />
							<Box
								sx={{
									minHeight: 80,
									maxHeight: 80,
									overflowY: "hidden",
									textOverflow: "ellipsis",
									display: "-webkit-box",
									WebkitLineClamp: 3,
									WebkitBoxOrient: "vertical",
								}}
							>
								<Typography
									variant="body1"
									gutterBottom
									sx={{
										overflowY: "hidden",
										textOverflow: "ellipsis",
										display: "-webkit-box",
										WebkitLineClamp: 3,
										WebkitBoxOrient: "vertical",
									}}
								>
									{data.expertDescription}
								</Typography>
							</Box>

							<Divider sx={{ mb: 1 }} />
							<Grid container spacing={1}>
								{data.skills.slice(0, 3).map((skill) => (
									<Grid item xs={4} key={skill}>
										<Chip
											label={skill}
											sx={{
												width: "120px",
												height: "32px",
											}}
										/>
									</Grid>
								))}
								{data.skills.length - 3 > 0 && (
									<Grid item xs={4} key="plus-skills">
										<Chip
											sx={{
												width: "120px",
												height: "32px",
											}}
											label={`+${data.skills.length - 3}`}
											color="secondary"
										/>
									</Grid>
								)}
							</Grid>
						</Stack>
					</Box>
				</Box>
			</CardContent>
			<CardActions>
				<Button
					component={Link}
					to={constants.PAGES.EXPERT_PROFILE + "/" + data._id}
					fullWidth
					variant="text"
				>
					Ver perfil
				</Button>
			</CardActions>
		</StyledCard>
	);
}
