import React, { useEffect } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import LandingPage from "./LandingPage/LandingPage";
import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";
import constants from "../../config/constants";
import LogInPage from "./LogInPage/LogInPage";
import { initUser, logout } from "../../redux/slices/userReducer";
import AccountPage from "./AccountPage/AccountPage";
import SignUp from "./SignUpPage/SignUp";
import LoadingOverlay from "../components/LoadingOverlay/LoadingOverlay";
import ForgotPasswordPage from "./ForgotPasswordPage/ForgotPasswordPage";
import ResetPasswordPage from "./ResetPasswordPage/ResetPasswordPage";
import ChangePasswordPage from "./ChangePasswordPage/ChangePasswordPage";
import ChangeEmailPage from "./ChangeEmailPage/ChangeEmailPage";
import DeleteAccountPage from "./DeleteAccountPage/DeleteAccountPage";
import ExpertAccountPage from "./ExpertAccountPage/ExpertAccountPage";
import ExpertProfilePage from "./ExpertProfilePage/ExpertProfilePage";
import { TermsAndConditions } from "./TermsAndConditions/TermsAndConditions";
import EditExpertProfile from "./EditExpertProfile/EditExpertProfile";
import SearchExpertPage from "./SearchExpertPage/SearchExpertPage";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import useAuth from "../../hooks/auth-hook";
import CreatePropertyPage from "./CreateProperty/CreatePropertyPage";
import RateExpert from "./RateExpertPage/RateExpert";
import HousingPage from "./HousingPage/HousingPage";
import SearchHousings from "./SearchHousingsPage/SearchHousings";
import UserHousings from "./UserHousings/UserHousings";
import EditHousingPage from "./EditHousingPage.js/EditHousingPage";
import FavoriteHousings from "./FavoriteHousings/FavoriteHousings";
import FavoriteExperts from "./FavoriteExperts/FavoriteExperts";
import CompleteHousingDetails from "./CompleteHousingDetails.js/CompleteHousingDetails";
import HousingDevices from "./HousingDevices/HousingDevices";
import EditHousingDevices from "./EditHousingDevices/EditHousingDevices";
import EditHousingInvoices from "./EditHousingInvoices/EditHousingInvoices";

function MainNavigation() {
	const dispatch = useDispatch();
	let routes;
	const [user, loading] = useAuth();

	useEffect(() => {
		if (user) {
			dispatch(initUser(user));
		} else {
			dispatch(logout());
		}
	}, [user, dispatch]);

	if (loading) {
		// Show Circular Progress while loading
		return <LoadingOverlay isLoading={loading} />;
	}
	routes = (
		<Routes>
			<Route path={constants.PAGES.LANDING} element={<LandingPage />} />
			<Route path={constants.PAGES.LOG_IN} element={<LogInPage />} />
			<Route path={constants.PAGES.SIGN_UP} element={<SignUp />} />
			<Route
				path={constants.PAGES.FORGOT_MY_PASSWORD}
				element={<ForgotPasswordPage />}
			/>
			<Route
				path={constants.PAGES.RESET_PASSWORD + "/:token"}
				element={<ResetPasswordPage />}
			/>
			<Route
				path={constants.PAGES.TERMS_AND_CONDITIONS}
				element={<TermsAndConditions />}
			/>
			<Route
				path={constants.PAGES.SEARCH_EXPERTS}
				element={<SearchExpertPage />}
			/>
			<Route
				path={constants.PAGES.SEARCH_HOUSES}
				element={<SearchHousings />}
			/>
			{/*Rutas Privadas:*/}
			<Route
				path={constants.PAGES.ACCOUNT}
				element={
					<PrivateRoute>
						<AccountPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.CHANGE_PASSWORD}
				element={
					<PrivateRoute>
						<ChangePasswordPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.CHANGE_EMAIL}
				element={
					<PrivateRoute>
						<ChangeEmailPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.DELETE_ACCOUNT}
				element={
					<PrivateRoute>
						<DeleteAccountPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.CREATE_TECHNICAL_ACCOUNT}
				element={
					<PrivateRoute>
						<ExpertAccountPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.EXPERT_PROFILE + "/:id"}
				element={
					<PrivateRoute>
						<ExpertProfilePage />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.EDIT_EXPERT_PROFILE + "/:id"}
				element={
					<PrivateRoute>
						<EditExpertProfile />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.PUBLISH_HOUSE}
				element={
					<PrivateRoute>
						<CreatePropertyPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.REVIEW_EXPERT + "/:expertId"}
				element={
					<PrivateRoute>
						<RateExpert />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.HOUSING_PAGE + "/:housingId"}
				element={
					<PrivateRoute>
						<HousingPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.USER_HOUSINGS}
				element={
					<PrivateRoute>
						<UserHousings />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.EDIT_HOUSING + "/:housingId"}
				element={
					<PrivateRoute>
						<EditHousingPage />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.FAVORITE_HOUSINGS}
				element={
					<PrivateRoute>
						<FavoriteHousings />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.FAVORITE_EXPERTS}
				element={
					<PrivateRoute>
						<FavoriteExperts />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.COMPLETE_HOUSING_DATA + "/:housingId"}
				element={
					<PrivateRoute>
						<CompleteHousingDetails />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.HOUSING_DEVICES + "/:housingId"}
				element={
					<PrivateRoute>
						<HousingDevices />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.EDIT_HOUSING_DEVICES + "/:housingId"}
				element={
					<PrivateRoute>
						<EditHousingDevices />
					</PrivateRoute>
				}
			/>
			<Route
				path={constants.PAGES.EDIT_HOUSING_INVOICES + "/:housingId"}
				element={
					<PrivateRoute>
						<EditHousingInvoices />
					</PrivateRoute>
				}
			/>
		</Routes>
	);

	return (
		<BrowserRouter>
			<NavBar />
			{routes}
			<Footer />
		</BrowserRouter>
	);
}

export default MainNavigation;
