import api from "../../api";
import { configuration } from "../../../config/configuration";

const editHousing = async (token, updatedFields, housingId) => {
	try {
		// Set the Authorization header with the token
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		// Make the API request to create a new housing
		const response = await api.patch(
			configuration.BASE_URL + "housings/" + housingId,

			updatedFields,

			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
			}
		);

		const data = await response.data;
		return data; // Return the created housing data
	} catch (error) {
		throw new Error(error.response.data.error);
	}
};

export default editHousing;
