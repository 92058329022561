import { useState, useEffect } from "react";
import getUserDataFromToken from "../networking/endpoints/user/getDataFromToken";
import chekTokenValidity from "../networking/endpoints/auth/checkTokenValidity";

function useAuth() {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true); // Set loading to true initially

	useEffect(() => {
		async function fetchData() {
			const authToken = localStorage.getItem("authToken");
			if (authToken) {
				try {
					// Verify the access token
					const response = await chekTokenValidity(authToken);

					if (response.status === 200) {
						// If the token is valid, get user data from the token
						const userData = await getUserDataFromToken(authToken);
						setUser(userData);
					} else {
						// If the token is not valid, remove it from local storage and set user to null
						localStorage.removeItem("authToken");
						setUser(null);
					}
				} catch (error) {
					// Handle any errors that occur during token validation or user data retrieval
					localStorage.removeItem("authToken");
					setUser(null);
				}
			}
			setLoading(false); // Set loading to false after attempting to fetch user data
		}

		fetchData();
	}, []);

	return [user, loading];
}

export default useAuth;
