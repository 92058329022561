import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

function ButtonOutline({ title, onClick }) {
	const theme = useTheme();
	return (
		<Button
			variant="outlined"
			sx={{
				color: theme.palette.secondary.main,
			}}
			onClick={onClick}
		>
			{title}
		</Button>
	);
}

export default ButtonOutline;
