import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@emotion/react";
import constants from "../../../config/constants";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { Autocomplete, Chip, AlertTitle, Alert, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

function ExpertAccountPageUI({
	province,
	locality,
	loading,
	handleSubmit,
	setTitle,
	setDescription,
	handleProvinceChange,
	setLocality,
	title,
	description,
	localities,
	linkedinUrl,
	setLinkedinUrl,
	facebookUrl,
	setFacebookUrl,
	skills,
	setSkills,
}) {
	const theme = useTheme();

	return (
		<Container component="main" maxWidth="md">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Typography component="h1" variant="h5">
					{constants.MISC_TEXT.COMPLETE_DATA}
				</Typography>
				<br />

				<Box
					component="form"
					onSubmit={(e) => {
						e.preventDefault(); // Prevent default form submission
						handleSubmit(); // Call the onSubmit function passed as a prop
					}}
				>
					<Alert severity="info">
						<AlertTitle>Bienvenido</AlertTitle>
						<Typography>
							Al llenar este formulario nos permites conocerte en
							detalle, y llenar tus datos de experto.
						</Typography>
						<br />
						Al completar y enviar esta solicitud, estas aceptando
						nuestros{" "}
						<Link
							component={RouterLink}
							to={constants.PAGES.TERMS_AND_CONDITIONS}
							target="_blank"
							rel="noopener noreferrer"
						>
							Términos y Condiciones de uso
						</Link>
						.
					</Alert>
					<TextField
						margin="normal"
						required
						fullWidth
						id="titulo"
						label="Titulo"
						name="titulo"
						autoFocus
						value={title}
						onChange={(e) => setTitle(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="description"
						label="Descripcion"
						id="description"
						rows={5}
						multiline
						value={description}
						onChange={(e) => setDescription(e.target.value)}
					/>
					<div>
						<FormControl margin="normal" fullWidth>
							<InputLabel id="demo-simple-select-autowidth-label">
								{constants.MISC_TEXT.PROVINCE}
							</InputLabel>
							<Select
								labelId="demo-simple-select-autowidth-label"
								id="demo-simple-select-autowidth"
								value={province}
								onChange={(event) =>
									handleProvinceChange(event.target.value)
								}
								autoWidth
								label={constants.MISC_TEXT.PROVINCE}
								required
								MenuProps={{
									PaperProps: {
										style: {
											width: "60%", // Set the maximum width to 100% of the parent
										},
									},
								}}
							>
								{constants.PROVINCES.map((province) => {
									return (
										<MenuItem
											value={province}
											key={province}
										>
											{province}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
					<div>
						<FormControl margin="normal" fullWidth>
							<InputLabel id="demo-simple-select-autowidth-label">
								{constants.MISC_TEXT.LOCALITY}
							</InputLabel>
							<Select
								labelId="demo-simple-select-autowidth-label"
								id="demo-simple-select-autowidth"
								value={locality}
								onChange={(event) =>
									setLocality(event.target.value)
								}
								autoWidth
								label={constants.MISC_TEXT.LOCALITY}
								required
								MenuProps={{
									PaperProps: {
										style: {
											width: "60%", // Set the maximum width to 100% of the parent
										},
									},
								}}
							>
								{localities.map((locality) => {
									return (
										<MenuItem
											value={locality}
											key={locality}
										>
											{locality}
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</div>
					<div>
						<FormControl margin="normal" fullWidth>
							<Autocomplete
								value={skills}
								fullWidth
								multiple
								options={constants.EXPERT_SKILLS}
								getOptionLabel={(option) => option}
								onChange={(event, newValue) => {
									setSkills(newValue);
								}}
								freeSolo
								renderTags={(tagValue, getTagProps) =>
									tagValue.map((option, index) => (
										<Chip
											label={option}
											{...getTagProps({ index })}
											onDelete={() => {
												const newSkills = [...skills];
												newSkills.splice(index, 1);
												setSkills(newSkills);
											}}
										/>
									))
								}
								renderInput={(params) => (
									<TextField {...params} label="Expertise" />
								)}
							/>
						</FormControl>
					</div>
					<TextField
						margin="normal"
						required
						fullWidth
						id="linkedin"
						label="Linkedin Url"
						name="linkedin"
						autoFocus
						value={linkedinUrl}
						onChange={(e) => setLinkedinUrl(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						id="facebook"
						label="Facebook Url"
						name="facebook"
						autoFocus
						value={facebookUrl}
						onChange={(e) => setFacebookUrl(e.target.value)}
					/>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							backgroundColor: theme.palette.secondary.main,
						}}
					>
						{constants.BUTTON_NAMES.CREATE_EXPERT_PROFILE}
					</Button>
				</Box>
			</Box>
			<LoadingOverlay isLoading={loading} />
		</Container>
	);
}

export default ExpertAccountPageUI;
