import React from "react";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "@mui/material";
import constants from "../../../config/constants";
import { NavLink } from "react-router-dom";
import "./NavBarHousing.css";

function NavigatorTable({ housingId }) {
	// Check if the screen size matches a phone breakpoint (adjust the breakpoint as needed)
	const isPhoneSize = useMediaQuery("(max-width: 600px)");

	return (
		<div className="navigatorContainerHousings">
			<NavLink
				to={constants.PAGES.HOUSING_PAGE + "/" + housingId}
				className="navLink" // Add a custom class name for the NavLink
				style={{
					width: "49%",
				}}
				align="center"
			>
				{constants.MISC_TEXT.HOUSING_PAGE}
			</NavLink>

			<Divider
				orientation={!isPhoneSize ? "vertical" : "horizontal"}
				flexItem={!isPhoneSize}
				variant="middle"
			/>
			<NavLink
				to={constants.PAGES.HOUSING_DEVICES + "/" + housingId}
				className="navLink" // Add a custom class name for the NavLink
				style={{
					width: "49%",
				}}
				align="center"
			>
				{constants.MISC_TEXT.HOUSING_DEVICES}
			</NavLink>
		</div>
	);
}

export default NavigatorTable;
