import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function deleteExpertProfile(token, id) {
	try {
		// Set the Authorization header with the token
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		// Make the API request to update user data
		const response = await api.patch(
			configuration.BASE_URL + "users/deleteExpertAccount/" + id
		);

		const data = await response.data;
		return data; // Return the updated user data
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
