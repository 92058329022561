import React from "react";
import UserHousingCard from "../../components/UserHousingCard/UserHousingCard";
import { Typography } from "@mui/material";
import HousingCardSkeleton from "../../components/HousingCardSkeleton/HousingCardSkeleton";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

function UserHousingsUI({
	loadingHousings,
	housings,
	handleClickHousing,
	onPressDelete,
	onPressEdit,
	loading,
}) {
	if (loadingHousings) {
		return (
			<div
				style={{
					display: "flex",
					flexDirection: "column",
					gap: "2rem",
					padding: "2rem",
				}}
			>
				<HousingCardSkeleton />
				<HousingCardSkeleton />
				<HousingCardSkeleton />
				<HousingCardSkeleton />
			</div>
		);
	}
	if (housings.length === 0) {
		return (
			<div
				style={{
					display: "flex",
					padding: "1rem",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
					height: "100vh",
				}}
			>
				<Typography
					variant="h6"
					fontSize="2rem"
					fontWeight="bold"
					gutterBottom
					style={{ textAlign: "center" }}
				>
					No tienes viviendas publicadas
				</Typography>
				<img
					src={require("../../../assets/images/caja-vacia.png")}
					alt="empty"
					width="300px"
				/>
			</div>
		);
	}
	return (
		<>
			<Typography
				mt={2}
				variant="h1"
				fontSize="2rem"
				fontWeight="bold"
				gutterBottom
				style={{ textAlign: "center" }}
			>
				Viviendas Publicadas
			</Typography>
			<div
				style={{
					display: "flex",
					justifyContent: "center",
					padding: "2rem",
					flexDirection: "column",
					gap: "2rem",
				}}
			>
				{housings.map((housing) => (
					<UserHousingCard
						key={housing._id}
						title={housing.title}
						description={housing.description}
						price={housing.price}
						locality={housing.address.locality}
						province={housing.address.province}
						listingType={housing.listingType}
						housingImage={housing.images[0]}
						tags={housing.tags}
						id={housing._id}
						effieciencyLevel={housing.energyEfficiencyLevel}
						handleClickHousing={() =>
							handleClickHousing(housing._id)
						}
						onPressDelete={() => onPressDelete(housing._id)}
						onPressEdit={() => onPressEdit(housing._id)}
					/>
				))}
			</div>
			<LoadingOverlay isLoading={loading} />
		</>
	);
}

export default UserHousingsUI;
