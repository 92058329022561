import UserHousingsUI from "./UserHousingsUI";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";
import getHousingsForUser from "../../../networking/endpoints/housing/getHousingsForUser";
import deleteHousing from "../../../networking/endpoints/housing/deleteHousings";

function UserHousings() {
	const navigate = useNavigate();
	const token = localStorage.getItem("authToken");
	const [loadingHouses, setLoadingHouses] = useState(false);
	const [housings, setHousings] = useState([]);
	const [loading, setLoading] = useState(false);

	async function getUserHousings() {
		try {
			setLoadingHouses(true);
			const housings = await getHousingsForUser(token);
			setHousings(housings.housings);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error.message,
			});
		} finally {
			setLoadingHouses(false);
		}
	}

	useEffect(() => {
		getUserHousings();
	}, []);

	const onPressDelete = (id) => {
		Swal.fire({
			title: "¿Estás seguro?",
			text: "No podrás revertir esta acción",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Sí, eliminar",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					setLoading(true);
					await deleteHousing(token, id);
					getUserHousings();
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error,
					});
				} finally {
					setLoading(false);
				}
			}
		});
	};

	const onPressEdit = (housingId) => {
		navigate(constants.PAGES.EDIT_HOUSING + "/" + housingId);
	};
	const handleClickHousing = (housingId) => {
		navigate(constants.PAGES.HOUSING_PAGE + "/" + housingId);
	};

	return (
		<UserHousingsUI
			loadingHousings={loadingHouses}
			housings={housings}
			onPressDelete={onPressDelete}
			onPressEdit={onPressEdit}
			handleClickHousing={handleClickHousing}
			loading={loading}
		/>
	);
}

export default UserHousings;
