import React from "react";
import HousingCard from "../../components/HousingCard/HousingCard";
import { Grid, Typography, Container, Button } from "@mui/material";

import { FilterdHousingsDrawer } from "../../components/FilterHousingsDrawer/FilterHousingsDrawer";
import HousingCardSkeleton from "../../components/HousingCardSkeleton/HousingCardSkeleton";

function SearchHousingsUI({
	data,
	containerRef,
	handleDrawerToggle,
	isOpen,
	province,
	localities,
	locality,
	listingType,
	housingType,
	handleProvincesChange,
	handleLocalitiesChange,
	handleListingTypeChange,
	handleHousingTypeChange,
	handleCleanFilters,
	performSearch,
	pageSize,
	currentPage,
	totalHousings,
	handlePageChange,
	numRooms, //ambientes
	numBedrooms, //dormitorios
	handleBedroomsChange,
	handleRoomsChange,
	efficiencyLevel,
	handleEfficiencyLevelChange,
	handleClickHousing,
	loadingHouses,
	searchQuery,
	handleSearchInputChange,
	suggestions,
	setSuggestions,
	setSearchQuery,
	searchActive,
	handleClickResetSearch,
}) {
	return (
		<Container style={{ position: "relative" }} maxWidth="false">
			<FilterdHousingsDrawer
				container={containerRef}
				province={province}
				localities={localities}
				locality={locality}
				listingType={listingType}
				housingType={housingType}
				numRooms={numRooms}
				numBedrooms={numBedrooms}
				efficiencyLevel={efficiencyLevel}
				handleProvincesChange={handleProvincesChange}
				handleLocalitiesChange={handleLocalitiesChange}
				handleListingTypeChange={handleListingTypeChange}
				handleHousingTypeChange={handleHousingTypeChange}
				handleRoomsChange={handleRoomsChange}
				handleBedroomsChange={handleBedroomsChange}
				handleEfficiencyLevelChange={handleEfficiencyLevelChange}
				handleDrawerToggle={handleDrawerToggle}
				isOpen={isOpen}
				handleCleanFilters={handleCleanFilters}
				performSearch={performSearch}
				searchQuery={searchQuery}
				handleSearchInputChange={handleSearchInputChange}
				suggestions={suggestions}
				setSuggestions={setSuggestions}
				setSearchQuery={setSearchQuery}
				searchActive={searchActive}
				handleClickResetSearch={handleClickResetSearch}
			>
				<Grid
					container
					rowSpacing={{ xs: 2, sm: 2, md: 3 }}
					columnSpacing={{ xs: 2, sm: 2, md: 3 }}
				>
					{loadingHouses ? (
						<div
							style={{
								display: "flex",
								flexDirection: "column",
								flex: 1,
								margin: "2rem",
								gap: "2rem",
							}}
						>
							<HousingCardSkeleton />
							<HousingCardSkeleton />
							<HousingCardSkeleton />
							<HousingCardSkeleton />
						</div>
					) : data.length ? (
						data.map((housing, index) => (
							<Grid
								key={index}
								item
								mb={1}
								lg={12}
								md={12}
								xs={12}
								sx={{
									display: "flex",
								}}
								mr={{ xs: 1, sm: 1, md: 2 }}
							>
								<HousingCard
									title={housing.title}
									description={housing.description}
									price={housing.price}
									locality={housing.address.locality}
									province={housing.address.province}
									listingType={housing.listingType}
									housingImage={housing.images[0]}
									tags={housing.tags}
									id={housing._id}
									effieciencyLevel={
										housing.energyEfficiencyLevel
									}
									handleClickHousing={() =>
										handleClickHousing(housing._id)
									}
								/>
							</Grid>
						))
					) : (
						<Grid
							item
							mb={1}
							xs={12}
							sx={{
								height: "100vh",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								margin: "3rem 0",
							}}
						>
							<Typography>
								Lo sentimos, parece que todavía no tenemos
								viviendas que se ajusten a sus criterios de
								búsqueda.😧
							</Typography>
						</Grid>
					)}

					{data.length > 0 && (
						<Grid
							container
							columnSpacing={{ xs: 2, sm: 2, md: 3 }}
							justifyContent={"center"}
							mt={5}
						>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									disabled={currentPage === 1}
									onClick={() =>
										handlePageChange(currentPage - 1)
									}
								>
									Anterior
								</Button>
							</Grid>
							<Grid item>
								<Typography variant="body1">
									{currentPage} de{" "}
									{Math.ceil(totalHousings / pageSize)}
								</Typography>
							</Grid>
							<Grid item>
								<Button
									variant="contained"
									color="primary"
									disabled={
										currentPage ===
										Math.ceil(totalHousings / pageSize)
									}
									onClick={() =>
										handlePageChange(currentPage + 1)
									}
								>
									Siguiente
								</Button>
							</Grid>
						</Grid>
					)}
				</Grid>
			</FilterdHousingsDrawer>
		</Container>
	);
}

export default SearchHousingsUI;
