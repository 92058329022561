import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";

function InvoiceRow({ rows }) {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">Empresa</TableCell>
						<TableCell align="center">Fecha de emision</TableCell>
						<TableCell align="center">Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.id}>
							<TableCell align="center">
								{row.invoice_vendor}
							</TableCell>
							<TableCell align="center">
								{row.invoice_date}
							</TableCell>
							<TableCell align="center">
								{row.invoice_total} ARS
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}

export default InvoiceRow;
