import React, { useState, useEffect } from "react";
import FavoriteExpertsUI from "./FavoriteExpertsUI";
import Swal from "sweetalert2";
import getFavoriteExperts from "../../../networking/endpoints/favoriteExperts/getFavoriteExperts";
import unmarkFavorite from "../../../networking/endpoints/favoriteExperts/unmarkFavorite";

function FavoriteExperts() {
	const [loading, setLoading] = useState(false);
	const [experts, setExperts] = useState([]);
	const [loadingExperts, setLoadingExperts] = useState(false);
	const token = localStorage.getItem("authToken");

	useEffect(() => {
		const getFavoriteExpertsData = async () => {
			try {
				setLoadingExperts(true);
				const response = await getFavoriteExperts(token);
				setExperts(response.experts);
				setLoadingExperts(false);
			} catch (error) {
				setLoadingExperts(false);
				Swal.fire({
					title: "Error",
					text: error,
					icon: "error",
				});
			}
		};
		getFavoriteExpertsData();
	}, [token]);

	const onPressFavorite = async (expertId) => {
		try {
			setLoading(true);
			await unmarkFavorite(token, expertId);
			const experts = await getFavoriteExperts(token);
			setExperts(experts.experts);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<FavoriteExpertsUI
			loading={loading}
			experts={experts}
			loadingExperts={loadingExperts}
			onPressFavorite={onPressFavorite}
		/>
	);
}

export default FavoriteExperts;
