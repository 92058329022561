import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function getUserDataFromToken(token) {
	try {
		// Set the Authorization header with the token
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		// Make the API request to get user data
		const response = await api.get(
			configuration.BASE_URL + "users/userByToken"
		);
		const data = await response.data;

		return data; // Return the user data
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
