import React, { useState } from "react";
import { Button, TextField, Grid, Box, Divider } from "@mui/material";
import Swal2 from "sweetalert2";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";

const ContactCard = ({ phoneNumber, handleSendMail }) => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [message, setMessage] = useState("");

	const handleSendEmail = () => {
		handleSendMail(name, email, phone, message);
	};

	const handleContactWhatsApp = () => {
		if (phoneNumber) {
			const whatsappUrl = `https://wa.me/${phoneNumber}`;

			window.open(whatsappUrl, "_blank");
		} else {
			Swal2.fire({
				icon: "error",
				title: "Oops...",
				text: "El propietario no ha proporcionado un número de teléfono",
				footer: "Intente contactarlo por email",
			});
		}
	};

	return (
		<Card
			sx={{
				bgcolor: "background.paper",
				p: 6,
			}}
		>
			<Typography
				gutterBottom
				variant="h5"
				component="div"
				align="center"
			>
				Contactar Propietario
			</Typography>
			<TextField
				label="Email"
				fullWidth
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				sx={{ mb: 2 }}
			/>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<TextField
						label="Nombre"
						fullWidth
						value={name}
						onChange={(e) => setName(e.target.value)}
					/>
				</Grid>
				<Grid item xs={6}>
					<TextField
						label="Telefono"
						fullWidth
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					/>
				</Grid>
			</Grid>
			<TextField
				label="Mensaje"
				fullWidth
				multiline
				rows={4}
				value={message}
				onChange={(e) => setMessage(e.target.value)}
				sx={{ mt: 2, mb: 2 }}
			/>
			<Box sx={{ width: "100%", mt: 2 }}>
				<Button
					variant="contained"
					color="primary"
					onClick={handleSendEmail}
					sx={{ width: "100%" }}
				>
					Enviar Email
				</Button>
				<Divider sx={{ my: 1 }}>o</Divider>
				<Button
					variant="contained"
					color="secondary"
					onClick={handleContactWhatsApp}
					sx={{ width: "100%" }}
				>
					Contactar via WhatsApp
				</Button>
			</Box>
		</Card>
	);
};

export default ContactCard;
