import React, { useState, useEffect } from "react";
import RateExpertUI from "./RateExpertUI";
import getRatingsForExpertAndUser from "../../../networking/endpoints/rating/getRatingForExpertAndUser";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import getUserById from "../../../networking/endpoints/user/getUserById";
import editRating from "../../../networking/endpoints/rating/editRating";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";
import rateExpert from "../../../networking/endpoints/rating/rateExpert";
import deleteRating from "../../../networking/endpoints/rating/deleteRating";

function RateExpert() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [rating, setRating] = useState(0);
	const [comment, setComment] = useState("");
	const [expert, setExpert] = useState({});
	const [review, setReview] = useState({});
	const { id } = useSelector((state) => state.userReducer);
	const { expertId } = useParams();

	useEffect(() => {
		let isMounted = true; // Add a flag to track whether the component is mounted

		const token = localStorage.getItem("authToken");

		async function getData() {
			try {
				setLoading(true);
				const data = await getRatingsForExpertAndUser(
					token,
					expertId,
					id
				);
				const response = await getUserById(expertId);

				if (isMounted) {
					setExpert(response);
					if (data.rating !== null) {
						setRating(data.rating.rating);
						setComment(data.rating.comment);
					}
					setReview(data.rating);
					setLoading(false);
				}
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error,
				});
				if (isMounted) {
					setLoading(false);
				}
			}
		}

		getData();

		// Cleanup function to cancel any pending requests when the component unmounts
		return () => {
			isMounted = false;
		};
	}, [expertId, id]);

	const handleCommentChange = (event) => {
		setComment(event.target.value);
	};
	const handleRatingChange = (event, newValue) => {
		setRating(newValue);
	};

	const onEdit = async () => {
		try {
			const token = localStorage.getItem("authToken");
			const data = await editRating(
				token,
				expertId,
				comment,
				rating,
				review._id
			);
			setReview(data.rating);
			Swal.fire({
				icon: "success",
				title: "Success!",
				text: "Reseña editada correctamente",
			});
			navigate(constants.PAGES.EXPERT_PROFILE + "/" + expertId);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};

	const handleCancel = () => {
		setRating(review.rating);
		setComment(review.comment);
	};

	const onSubmit = async () => {
		try {
			const token = localStorage.getItem("authToken");
			await rateExpert(token, {
				expertId: expertId,
				rating,
				comment,
			});
			Swal.fire({
				icon: "success",
				title: "Success!",
				text: "Reseña creada correctamente",
			});
			navigate(constants.PAGES.EXPERT_PROFILE + "/" + expertId);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};
	const handleDelete = async () => {
		// ask with swal if the user is sure to delete the review
		Swal.fire({
			title: "¿Estás seguro?",
			text: "No podrás revertir esta acción",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#3085d6",
			cancelButtonText: "Cancelar",
			confirmButtonText: "Sí, eliminar",
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const token = localStorage.getItem("authToken");
					await deleteRating(token, review._id);
					Swal.fire({
						icon: "success",
						title: "Success!",
						text: "Reseña eliminada correctamente",
					});
					navigate(constants.PAGES.EXPERT_PROFILE + "/" + expertId);
				} catch (error) {
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: error,
					});
				}
			}
		});
	};

	return (
		<RateExpertUI
			loading={loading}
			rating={rating}
			expert={expert}
			comment={comment}
			handleCommentChange={handleCommentChange}
			handleRatingChange={handleRatingChange}
			review={review}
			handleCancel={handleCancel}
			onEdit={onEdit}
			onSubmit={onSubmit}
			handleDelete={handleDelete}
		/>
	);
}

export default RateExpert;
