import styled from "@emotion/styled";
import {
	Card,
	CardContent,
	Chip,
	Divider,
	Grid,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ButtonBase from "@mui/material/ButtonBase";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Tooltip from "@mui/material/Tooltip";
const StyledCard = styled(Card)`
	border-radius: 1rem;
	border: 1px solid #2d8d59;
	background-color: #eeeeee50;

	/* Media query for small screens (phones) */
	@media (max-width: 600px) {
		flex-direction: column;
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

export default function FavoriteHousingCard({
	title,
	description,
	locality,
	province,
	listingType,
	housingImage,
	tags,
	effieciencyLevel,
	price,
	handleClickHousing,
	onPressFavorite,
}) {
	return (
		<StyledCard elevation={0}>
			<ButtonBase
				sx={{
					flex: 1, // Expand to take the available space
				}}
				onClick={handleClickHousing}
			>
				<img
					src={housingImage}
					alt="Housing"
					style={{
						width: "100%",
						height: "100%",
						maxHeight: "17rem",
						objectFit: "cover",
					}}
				/>
			</ButtonBase>
			<CardContent
				sx={{
					flex: 2, // Expand to take the available space
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Box>
						<Typography variant="h5">{title}</Typography>
						<Typography gutterBottom>
							{province + ", " + locality}
						</Typography>
					</Box>
					<Tooltip title="Quitar de favoritos">
						<IconButton
							aria-label="favorite"
							onClick={onPressFavorite}
						>
							<FavoriteIcon color="secondary" />
						</IconButton>
					</Tooltip>
				</Box>
				<Divider sx={{ mb: 1 }} />
				<Typography
					variant="body1"
					gutterBottom
					sx={{
						height: "4rem", // Set the maximum height
						overflow: "hidden",
						textOverflow: "ellipsis",
						lineHeight: "1.3", // Adjust the line height
						whiteSpace: "normal", //
					}}
				>
					{description}
				</Typography>
				<Grid container spacing={1}>
					{tags.slice(0, 4).map((skill) => (
						<Grid item key={skill}>
							<Chip label={skill} />
						</Grid>
					))}
					{tags.length - 4 > 0 && (
						<Grid item key="plus-skills">
							<Chip
								label={`+${tags.length - 4}`}
								color="primary"
							/>
						</Grid>
					)}
				</Grid>
				<Divider sx={{ mb: 1, mt: 1 }} />
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						flexWrap: "wrap",
					}}
				>
					{effieciencyLevel ? (
						<img
							src={require(`../../../assets/images/${effieciencyLevel}.png`)}
							alt="Efficiency Level"
							style={{
								width: "15rem",
								height: "2rem",
								objectFit: "cover",
							}}
						/>
					) : (
						<Typography
							variant="body1"
							sx={{
								maxHeight: "100px",
								overflowY: "hidden",
								textOverflow: "ellipsis",
								display: "-webkit-box",
								WebkitLineClamp: 3,
								WebkitBoxOrient: "vertical",
							}}
						>
							{"Sin nivel de eficiencia cargado"}
						</Typography>
					)}
					<Typography variant="body1" gutterBottom>
						{listingType === "sale" ? "Venta" : "Alquiler"}
					</Typography>
					<Typography variant="h6" gutterBottom>
						{"USD " + price}
					</Typography>
				</Box>
			</CardContent>
		</StyledCard>
	);
}
