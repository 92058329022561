import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function markHousingAsFavorite(token, housingId) {
	try {
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		const response = await api.post(
			configuration.BASE_URL + "favoriteHousings",
			{
				housingId: housingId,
			}
		);
		const data = await response.data;

		return data;
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
