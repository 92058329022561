import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function sendEmail(recipient, subject, message) {
	try {
		const response = await api.post(
			configuration.BASE_URL + "users/sendEmail",
			{
				recipient: recipient,
				subject: subject,
				message: message,
			}
		);
		const data = await response.data;

		return data; // Return the user data and token
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
