import React, { useState } from "react";
import ChangeEmailPageUI from "./ChangeEmailPageUI";
import Swal from "sweetalert2";
import updateUser from "../../../networking/endpoints/user/updateUser";
import { useSelector, useDispatch } from "react-redux";
import { initUser } from "../../../redux/slices/userReducer";
import constants from "../../../config/constants";

function ChangeEmailPage() {
	const dispatch = useDispatch();
	const { token, id, googleId } = useSelector((state) => state.userReducer);
	const [email, setEmail] = useState("");
	const [repeatEmail, setRepeatEmail] = useState("");
	const [loading, setLoading] = useState(false);

	const handleEmailChange = async () => {
		try {
			if (email === repeatEmail) {
				const formData = new FormData();
				formData.append("email", email);
				const { value: accept } = await Swal.fire({
					title: "¿Estás seguro?",
					text: "¿Estás seguro que deseas cambiar tu email?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Sí",
					cancelButtonText: "No",
				});
				if (!accept) return;
				setLoading(true);
				const response = await updateUser(token, id, formData);
				dispatch(initUser(response));
				Swal.fire({
					icon: "success",
					title: "¡Listo!",
					text: constants.MISC_TEXT.EMAIL_CHANGED,
				});
				setEmail("");
				setRepeatEmail("");
			} else {
				Swal.fire({
					icon: "error",
					title: "Error...",
					text: constants.ERRORS.EMAILS_DONT_MATCH,
				});
			}
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error...",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<ChangeEmailPageUI
			loading={loading}
			email={email}
			setEmail={setEmail}
			repeatEmail={repeatEmail}
			setRepeatEmail={setRepeatEmail}
			onSubmit={handleEmailChange}
			googleId={googleId}
		/>
	);
}

export default ChangeEmailPage;
