import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import EditHousingDevicesUI from "./EditHousingDevicesUI";
import readEfficiencyTag from "../../../networking/endpoints/formEndpoint/readEfficiencyTag";
import uploadDevices from "../../../networking/endpoints/housing/uploadDevices";
import { useParams } from "react-router-dom";
import deleteDevice from "../../../networking/endpoints/housing/deleteDevice";
import getDevices from "../../../networking/endpoints/housing/getDevices";

function EditHousingDevices() {
	const { housingId } = useParams();
	const token = localStorage.getItem("authToken");
	const [loading, setLoading] = useState(false);

	const [rows, setRows] = useState([]);

	const [selectedImage, setSelectedImage] = useState(null);
	const [openImageDialog, setOpenImageDialog] = useState(false);
	const [edited, setEdited] = useState(false); // To check if the user has edited the forms
	const fetchData = async () => {
		try {
			setLoading(true);
			const response = await getDevices(token, housingId);
			const devices = response.devices;
			const auxDevices = [];
			for (let i = 0; i < devices.length; i++) {
				const device = devices[i];
				const deviceBrand = device.brand;
				const deviceModel = device.model;
				const deviceType = device.device;
				const efficiencyLevel = device.efficiency;
				const energyConsumption = device.consumption;
				const image = device.imageUrl;
				const imageFile = null;
				const newRow = {
					id: device._id,
					marca: deviceBrand,
					modelo: deviceModel,
					dispositivo: deviceType,
					eficiencia: efficiencyLevel,
					consumo: energyConsumption,
					image: image,
					imageFile: imageFile,
					fetched: true,
				};
				auxDevices.push(newRow);
			}
			setRows(auxDevices);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};
	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		const isEdited = rows.some((row) => !row.fetched);
		if (isEdited) {
			setEdited(true);
		} else {
			setEdited(false);
		}
	}, [rows]);

	const handleAddRow = () => {
		const newRow = {
			id: Date.now(),
			marca: "",
			modelo: "",
			dispositivo: "",
			eficiencia: "",
			consumo: "",
			image: null,
			imageFile: null,
		};
		setRows([...rows, newRow]);
	};

	const handleDeleteRow = (id) => {
		const rowToDelete = rows.find((row) => row.id === id);
		if (rowToDelete.fetched) {
			try {
				setLoading(true);
				Swal.fire({
					title: "¿Estás seguro?",
					text: "Esta acción no se puede deshacer",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#3085d6",
					cancelButtonColor: "#d33",
					confirmButtonText: "Sí, eliminar",
					cancelButtonText: "Cancelar",
				}).then(async (result) => {
					if (result.isConfirmed) {
						await deleteDevice(token, id);
						setLoading(false);
						Swal.fire(
							"Eliminado",
							"El dispositivo ha sido eliminado",
							"success"
						);
						fetchData();
					}
				});
				setLoading(false);
			} catch (error) {
				setLoading(false);
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error,
				});
			}
		} else {
			const updatedRows = rows.filter((row) => row.id !== id);
			setRows(updatedRows);
		}
	};
	const handleImageChange = async (event, id) => {
		const file = event.target.files[0];
		if (!file) return;

		try {
			setLoading(true);
			let formData = new FormData();
			formData.append("image", file);

			const response = await readEfficiencyTag(token, formData);

			const deviceBrand = response.fields.device_brand.value;
			const deviceModel = response.fields.device_model.value;
			const deviceType = response.fields.device_type.value;
			const efficiencyLevel = response.fields.efficiency_level.value;
			const energyConsumption = response.fields.energy_consumption.value;

			const updatedRows = rows.map((row) => {
				if (row.id === id) {
					return {
						...row,
						marca: deviceBrand,
						modelo: deviceModel,
						dispositivo: deviceType,
						eficiencia: efficiencyLevel,
						consumo: energyConsumption,
						image: URL.createObjectURL(file),
						imageFile: file,
					};
				}
				return row;
			});
			setRows(updatedRows);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
		setOpenImageDialog(true);
	};

	const handleCloseImageDialog = () => {
		setOpenImageDialog(false);
	};

	const validateDevices = (devs) => {
		for (let i = 0; i < devs.length; i++) {
			if (devs[i].marca === "") {
				return false;
			}
			if (devs[i].modelo === "") {
				return false;
			}
			if (devs[i].dispositivo === "") {
				return false;
			}
			if (devs[i].eficiencia === "") {
				return false;
			}
			if (devs[i].consumo === "") {
				return false;
			}
			if (devs[i].imageFile === null) {
				return false;
			}
		}
		return true;
	};

	const handleSubmit = async () => {
		try {
			setLoading(true);
			const devicesToUpload = rows.filter((row) => !row.fetched);
			if (rows.length > 0) {
				let formData = new FormData();
				const devicesAreValid = validateDevices(devicesToUpload);
				if (!devicesAreValid) {
					setLoading(false);
					Swal.fire({
						icon: "error",
						title: "Oops...",
						text: "Por favor, cargue los dispositivos",
					});
					return;
				}
				for (const row of devicesToUpload) {
					formData.append("housingId", housingId);
					formData.append("brand", row.marca);
					formData.append("model", row.modelo);
					formData.append("deviceType", row.dispositivo);
					formData.append("efficiencyLevel", row.eficiencia);
					formData.append("energyConsumption", row.consumo);
					formData.append("imageFile", row.imageFile);

					await uploadDevices(token, formData);
					formData = new FormData();
				}
			}
			setLoading(false);
			fetchData();
			Swal.fire({
				icon: "success",
				title: "¡Listo!",
				text: "Se han subido los dispositivos correctamente.",
			});
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};

	return (
		<EditHousingDevicesUI
			loading={loading}
			housingId={housingId}
			rows={rows}
			selectedImage={selectedImage}
			openImageDialog={openImageDialog}
			handleDeleteRow={handleDeleteRow}
			handleImageClick={handleImageClick}
			handleCloseImageDialog={handleCloseImageDialog}
			handleAddRow={handleAddRow}
			handleImageChange={handleImageChange}
			edited={edited}
			handleSubmit={handleSubmit}
		/>
	);
}

export default EditHousingDevices;
