import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import constants from "../../../config/constants";
import { GoogleLogin } from "@react-oauth/google";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { Link as DOMLink } from "react-router-dom";

function SignUpUI({
	handleSubmit,
	handleGoogleFailure,
	handleGoogleLogin,
	loading,
	email,
	password,
	firstName,
	lastName,
	setEmail,
	setPassword,
	setFirstName,
	setLastName,
}) {
	const theme = useTheme();
	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					{constants.MISC_TEXT.SIGNUP}
				</Typography>
				<Box
					component="form"
					noValidate
					onSubmit={(e) => {
						e.preventDefault(); // Prevent default form submission
						handleSubmit(); // Call the onSubmit function passed as a prop
					}}
					sx={{ mt: 3 }}
				>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6}>
							<TextField
								autoComplete="given-name"
								name="firstName"
								required
								fullWidth
								id="firstName"
								label="First Name"
								autoFocus
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								required
								fullWidth
								id="lastName"
								label="Last Name"
								name="lastName"
								autoComplete="family-name"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								id="email"
								label="Email Address"
								name="email"
								autoComplete="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								required
								fullWidth
								name="password"
								label="Password"
								type="password"
								id="password"
								autoComplete="new-password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</Grid>
					</Grid>

					<Button
						type="submit"
						fullWidth
						variant="contained"
						sx={{
							mt: 3,
							mb: 2,
							backgroundColor: `${theme.palette.secondary.main} !important`,
						}}
					>
						{constants.BUTTON_NAMES.SIGN_UP}
					</Button>

					<Grid container justifyContent="center">
						<Grid item>
							<Link
								component={DOMLink}
								variant="body2"
								to={constants.PAGES.LOG_IN}
							>
								{constants.BUTTON_NAMES.ALREADY_HAVE_AN_ACCOUNT}
							</Link>
						</Grid>
					</Grid>
				</Box>

				<Box sx={{ mt: 5 }}>
					<GoogleLogin
						onSuccess={(credentialResponse) => {
							handleGoogleLogin(credentialResponse);
						}}
						onError={handleGoogleFailure}
						text="Sign up with Google"
					/>
				</Box>
			</Box>
			<LoadingOverlay isLoading={loading} />
		</Container>
	);
}

export default SignUpUI;
