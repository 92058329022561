import React from "react";
import GoogleMapReact from "google-map-react";
const Circle = () => {
	const circleStyle = {
		width: "200px",
		height: "200px",
		backgroundColor: "blue",
		borderRadius: "50%",
		opacity: 0.5,
	};

	return <div style={circleStyle}></div>;
};

const GoogleMapContainer = ({ latitude, longitude }) => {
	const center = {
		lat: latitude,
		lng: longitude,
	};
	const createMapOptions = (maps) => {
		return {
			panControl: false,
			mapTypeControl: false,
			scrollwheel: false,
			gestureHandling: "none",
			zoomControl: false,
		};
	};
	return (
		<div style={{ height: "100%", width: "100%" }}>
			<GoogleMapReact
				bootstrapURLKeys={{
					key: "AIzaSyDQq7OMXLOVjDCVWurMLTMG1rGCsTqlKww",
				}} // Replace with your Google Maps API key
				center={center}
				defaultZoom={15}
				options={createMapOptions}
				yesIWantToUseGoogleMapApiInternals
			>
				<Circle lat={latitude} lng={longitude} text="My Marker" />
			</GoogleMapReact>
		</div>
	);
};

export default GoogleMapContainer;
