import React from "react";
import Carousel from "react-material-ui-carousel";

function ImageCarousel({ images }) {
	return (
		<Carousel
			autoPlay={false}
			navButtonsAlwaysVisible
			height={450}
			sx={{
				objectFit: "cover",
				borderRadius: "10px",
			}}
		>
			{images.map((imageLink, index) => (
				<img
					key={index}
					src={imageLink}
					alt={index}
					style={{
						objectFit: "cover",
						height: "100%",
						width: "100%",
						borderRadius: "10px",
					}}
				/>
			))}
		</Carousel>
	);
}

export default ImageCarousel;
