import * as React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import "./Footer.css";
import constants from "../../../config/constants";

export default function Footer() {
	const theme = useTheme();
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					minHeight: "40vh",
				}}
			>
				<Box
					component="footer"
					sx={{
						py: 3,
						px: 2,
						mt: "auto",
						backgroundColor: theme.palette.grey[200],
					}}
				>
					<Container maxWidth="sm">
						<div className="logoContainer">
							<img
								src={require("../../../assets/images/solar-energy.png")}
								alt="logo"
								className="logo"
							/>
							<Typography
								color={theme.palette.secondary.main}
								sx={{
									fontWeight: "bold",
									fontFamily: theme.typography.fontFamily,
									fontSize: 20,
								}}
								align="center"
							>
								{constants.MISC_TEXT.APP_NAME}
							</Typography>
						</div>
						<div className="linksContainer">
							<a href="https://instagram.com">
								<img
									src={require("../../../assets/images/instagram.png")}
									alt="instagram"
									className="logo"
								/>
							</a>
							<a href="https://facebook.com">
								<img
									src={require("../../../assets/images/facebook.png")}
									alt="facebook"
									className="logo"
								/>
							</a>
						</div>
						<div>
							<Typography
								color={theme.palette.secondary.main}
								sx={{
									fontFamily: theme.typography.fontFamily,
									fontSize: 14,
								}}
								align="center"
							>
								{constants.MISC_TEXT.COPYRIGHT}
							</Typography>
						</div>
					</Container>
				</Box>
			</Box>
		</>
	);
}
