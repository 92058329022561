import api from "../../api";
import { configuration } from "../../../config/configuration";

const getFavoriteExperts = async (token) => {
	try {
		// Set the Authorization header with the token
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		// Make the API request to create a new housing
		const response = await api.get(
			configuration.BASE_URL + "favoriteExperts/"
		);

		const data = await response.data;
		return data;
	} catch (error) {
		throw new Error(error.response.data.error);
	}
};

export default getFavoriteExperts;
