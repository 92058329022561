import React from "react";
import { Provider } from "react-redux";
import { store } from "../redux/Store";

import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainNavigation from "./screens/MainNavigation";
import { GoogleOAuthProvider } from "@react-oauth/google";
import PvaBotComponent from "./components/PvaBotComponent/PvaBotComponent";
import { pdfjs } from "react-pdf"; // Import pdfjs from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	"pdfjs-dist/build/pdf.worker.min.js",
	import.meta.url
).toString();

const theme = createTheme({
	palette: {
		primary: {
			main: "#1D3622", // Light Green
		},
		secondary: {
			main: "#2D8D59", // Dark Green
		},
		background: {
			main: "#FAFAFA", // Background Color
		},
		gray: {
			main: "#808080", // Custom Gray Color
		},
		common: {
			white: "#FAFAFA", // Custom White Color
		},
	},
	typography: {
		fontFamily: "Poppins, Helvetica", // Custom font family
		// You can also define other typography options here like h1, h2, body1, body2, etc.
	},
});

function App() {
	return (
		// Wrap your app with the GoogleOAuthProvider component
		<GoogleOAuthProvider
			// Pass your web client ID as a prop
			clientId="889441881347-q3d7l92gj6su728rrfb9i3oe31s4gosd.apps.googleusercontent.com"
		>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<div>
						<MainNavigation />
						<PvaBotComponent />
					</div>
				</ThemeProvider>
			</Provider>
		</GoogleOAuthProvider>
	);
}

export default App;
