import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Leaf from "@mui/icons-material/EnergySavingsLeaf";
import Tooltip from "@mui/material/Tooltip";
import CardActionArea from "@mui/material/CardActionArea";
function RecommendedHousing({
	imageUrl,
	title,
	listingType,
	efficiencyLevel,
	price,
	handleClickRecommendation,
}) {
	const theme = useTheme();
	return (
		<Card sx={{ width: 200 }}>
			<CardActionArea onClick={handleClickRecommendation}>
				<CardMedia
					component="img"
					height="140"
					alt="green iguana"
					image={imageUrl}
				/>
				<CardContent>
					<Typography
						gutterBottom
						variant="h5"
						component="div"
						sx={{
							whiteSpace: "nowrap",
							overflow: "hidden",
							textOverflow: "ellipsis",
						}}
					>
						{title}
					</Typography>
					<Typography variant="body2" color="text.secondary">
						{listingType}
					</Typography>
				</CardContent>
			</CardActionArea>
			<CardActions>
				<Typography
					variant="p"
					fontWeight="bold"
					color={theme.palette.primary.main}
				>
					{price} USD
				</Typography>
				{(efficiencyLevel === "A" || efficiencyLevel === "B") && (
					<Tooltip title="Casa Eficiente">
						<Leaf
							sx={{
								ml: "auto",
								color: theme.palette.secondary.main,
							}} // Change the color based on the state
						/>
					</Tooltip>
				)}
			</CardActions>
		</Card>
	);
}

export default RecommendedHousing;
