import React from "react";
import { styled } from "@mui/material/styles";

const DividerContainer = styled("div")({
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	gap: "8px",
});

const Divider = styled("div")({
	flexGrow: 1,
	height: "1px",
	background: "#000",
});

function CustomDivider({ text }) {
	return (
		<DividerContainer>
			<Divider />
			{text && <span>{text}</span>}
			<Divider />
		</DividerContainer>
	);
}

export default CustomDivider;
