import React, { useRef, useState, useCallback, useEffect } from "react";
import SearchHousingsUI from "./SearchHousingsUI";
import Swal from "sweetalert2";
import axios from "axios";
import getFilteredHousings from "../../../networking/endpoints/housing/getFilteredHousings";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";

function SearchHousings() {
	const container = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [province, setProvince] = useState("");
	const [locality, setLocality] = useState("");
	const [localities, setLocalities] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalHousings, setTotalHousings] = useState(0);
	const [housings, setHousings] = useState([]);
	const [pageSize] = useState(4);
	const [listingType, setListingType] = useState("");
	const [housingType, setHousingType] = useState("");
	const [numRooms, setNumRooms] = useState("");
	const [numBedrooms, setNumBedrooms] = useState("");
	const [efficiencyLevel, setEfficiencyLevel] = useState("");
	const [loadingHouses, setLoadingHouses] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [suggestions, setSuggestions] = useState([]);
	const originalHousings = useRef([]);
	const [searchActive, setSearchActive] = useState(false);

	const navigate = useNavigate();

	useEffect(()  => {
		const fetchOriginalHousings = async () => {
		let response = await getFilteredHousings(1, {});
		originalHousings.current = response.housings;
		response = await getFilteredHousings(2, {});
		originalHousings.current = originalHousings.current.concat(response.housings);
		}
		fetchOriginalHousings();
	},[]);

	const fetchHousings = async (page, filters) => {
		try {
			setLoadingHouses(true);
			const response = await getFilteredHousings(page, filters);
			const { housings, totalCount } = response;
			setHousings(housings);
			setTotalHousings(totalCount);
		} catch (error) {
			console.error("Error fetching housings:", error);
			Swal.fire({
				title: "Error",
				text: error,
				icon: "error",
				confirmButtonText: "Aceptar",
			});
		} finally {
			setLoadingHouses(false);
		}
	};

	useEffect(() => {
		const filters = {
			province,
			locality,
			listingType,
			housingType,
			numRooms,
			numBedrooms,
			energyEfficiencyLevel: efficiencyLevel,
		};
		fetchHousings(currentPage, filters);
	}, [
		province,
		locality,
		currentPage,
		listingType,
		housingType,
		numRooms,
		numBedrooms,
		efficiencyLevel,
	]);
	const getSuggestions = async (searchQuery) => {
		const suggestEndpoint =
			"https://ecohousing-housing-search.search.windows.net/indexes/cosmosdb-index/docs/suggest?api-version=2023-07-01-Preview";

		const apiKey = "FNxz2vTrAVX99BVxNSkPjTukldHqw8Tp8pgVa6K9ZnAzSeASQQrx";

		const config = {
			headers: {
				"Content-Type": "application/json",
				"api-key": apiKey,
			},
		};

		try {
			const response = await axios.post(
				suggestEndpoint,
				{
					suggesterName: "housings-suggester",
					search: searchQuery,
				},
				config
			);

			// Process the suggestions.
			const suggestions = response.data.value || [];
			return suggestions;
		} catch (error) {
			console.error("Error getting suggestions:", error);
		}
	};

	const handleSearchInputChange = async (event) => {
		const query = event.target.value;
		setSearchQuery(query || "");
		try {
			if (!query) {
				setSuggestions([]);
			} else {
				const suggestions = await getSuggestions(query);
				const suggestionsAux = [];
				suggestions.forEach((suggestion) => {
					if (!suggestionsAux.includes(suggestion["@search.text"])) {
						suggestionsAux.push(suggestion["@search.text"]);
					}
				});
				setSuggestions(suggestionsAux);
			}
		} catch (error) {
			console.error("Error getting suggestions:", error);
		}
	};
	const performSearch = async (s) => {
		if (s === "") {
			setSearchActive(false);
			fetchHousings(currentPage, {
				province,
				locality,
				listingType,
				housingType,
				numRooms,
				numBedrooms,
				energyEfficiencyLevel: efficiencyLevel,
			});
			return;
		}
		const searchEndpoint =
			"https://ecohousing-housing-search.search.windows.net/indexes/cosmosdb-index/docs?api-version=2023-07-01-Preview&search=" +
			s;
		const apiKey = "FNxz2vTrAVX99BVxNSkPjTukldHqw8Tp8pgVa6K9ZnAzSeASQQrx";

		const config = {
			headers: {
				"Content-Type": "application/json",
				"api-key": apiKey,
			},
		};

		try {
			setLoadingHouses(true);
			const response = await axios.get(searchEndpoint, config);

			// Process the search results.
			const results = await response.data.value || [];
			const filteredHousings = originalHousings.current.filter((housing) =>
				results.some((result) => housing._id === result.doc_id)
			);
			// Set the component state to the filtered array.
			console.log("filteredHousings", filteredHousings);
			console.log("originalHousings", originalHousings.current);
			setHousings(filteredHousings);
			setTotalHousings(filteredHousings.length);
			handleCleanFilters();
			setSearchActive(true);
			setLoadingHouses(false);
		} catch (error) {
			console.error("Error performing search:", error);
			setLoadingHouses(false);
			// Handle the error here.
		}
	};

	const handlePageChange = (newPage) => {
		if (newPage >= 1 && newPage <= Math.ceil(totalHousings / pageSize)) {
			setCurrentPage(newPage);
		}
	};

	const capitalizeFirstLetter = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	};

	useEffect(() => {
		const fetchLocalities = async () => {
			try {
				const response = await axios.get(
					`https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&max=1000`
				);
				const localitiesData = response.data.localidades;
				const formattedLocalities = localitiesData.map((locality) =>
					capitalizeFirstLetter(locality.nombre.toLowerCase())
				);

				// Remove duplicates from the formattedLocalities array
				const uniqueLocalities = [...new Set(formattedLocalities)];

				// Sort the uniqueLocalities array in alphabetical order
				uniqueLocalities.sort((a, b) => a.localeCompare(b));

				setLocalities(uniqueLocalities);
				// Reset the selected locality
				setLocality("");
			} catch (error) {
				if (error.code === 1) {
					Swal.fire({
						title: "Error",
						text: error,
						icon: "error",
					});
				}
			}
		};
		if (province !== "") {
			fetchLocalities();
		}
	}, [province]);

	const handleDrawerToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const handleProvincesChange = useCallback(
		(event) => {
			setProvince(event.target.value);
		},
		[setProvince]
	);

	const handleLocalitiesChange = useCallback(
		(event) => {
			setLocality(event.target.value);
		},
		[setLocality]
	);

	const handleCleanFilters = useCallback(() => {
		setProvince("");
		setLocality("");
		setListingType("");
		setHousingType("");
		setNumRooms("");
		setNumBedrooms("");
		setEfficiencyLevel("");
	}, [
		setProvince,
		setLocality,
		setListingType,
		setHousingType,
		setNumRooms,
		setNumBedrooms,
		setEfficiencyLevel,
	]);

	const handleListingTypeChange = useCallback(
		(event) => {
			setListingType(event.target.value);
		},
		[setListingType]
	);

	const handleHousingTypeChange = useCallback(
		(event) => {
			setHousingType(event.target.value);
		},
		[setHousingType]
	);

	const handleRoomsChange = useCallback(
		(event) => {
			setNumRooms(event.target.value);
		},
		[setNumRooms]
	);

	const handleBedroomsChange = useCallback(
		(event) => {
			setNumBedrooms(event.target.value);
		},
		[setNumBedrooms]
	);

	const handleEfficiencyLevelChange = useCallback(
		(event) => {
			setEfficiencyLevel(event.target.value);
		},
		[setEfficiencyLevel]
	);

	const handleClickHousing = useCallback(
		(id) => {
			navigate(constants.PAGES.HOUSING_PAGE + "/" + id);
		},
		[navigate]
	);

	const handleClickResetSearch = () => {
		setSearchQuery("");
		setSuggestions([]);
		setSearchActive(false);
		fetchHousings(currentPage, {
			province,
			locality,
			listingType,
			housingType,
			numRooms,
			numBedrooms,
			energyEfficiencyLevel: efficiencyLevel,
		});
	};

	return (
		<SearchHousingsUI
			data={housings}
			containerRef={container}
			handleDrawerToggle={handleDrawerToggle}
			isOpen={isOpen}
			province={province}
			locality={locality}
			listingType={listingType}
			housingType={housingType}
			numRooms={numRooms}
			numBedrooms={numBedrooms}
			efficiencyLevel={efficiencyLevel}
			handleLocalitiesChange={handleLocalitiesChange}
			handleProvincesChange={handleProvincesChange}
			handleListingTypeChange={handleListingTypeChange}
			handleHousingTypeChange={handleHousingTypeChange}
			handleRoomsChange={handleRoomsChange}
			handleBedroomsChange={handleBedroomsChange}
			handleEfficiencyLevelChange={handleEfficiencyLevelChange}
			localities={localities}
			handleCleanFilters={handleCleanFilters}
			pageSize={pageSize}
			handlePageChange={handlePageChange}
			currentPage={currentPage}
			totalHousings={totalHousings}
			handleClickHousing={handleClickHousing}
			loadingHouses={loadingHouses}
			performSearch={performSearch}
			searchQuery={searchQuery}
			handleSearchInputChange={handleSearchInputChange}
			suggestions={suggestions}
			setSuggestions={setSuggestions}
			setSearchQuery={setSearchQuery}
			searchActive={searchActive}
			handleClickResetSearch={handleClickResetSearch}
		/>
	);
}

export default SearchHousings;
