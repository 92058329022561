import React from "react";
import { Grid, Typography, Container, Button } from "@mui/material";
import { FiltersExpertsDrawer } from "../../components/FiltersExpertsDrawer/FiltersExpertsDrawer";
import ExpertCard from "../../components/ExpertCard/ExpertCard";
import ExpertCardSkeleton from "../../components/ExpertCardSkeleton/ExpertCardSkeleton";

const SearchExpertPageUI = ({
	data,
	containerRef,
	handleDrawerToggle,
	isOpen,
	province,
	localities,
	locality,
	skills,
	rating,
	handleProvincesChange,
	handleLocalitiesChange,
	handleSkillsChange,
	handleRatingChange,
	handleCleanFilters,
	loading,
	pageSize,
	currentPage,
	totalExperts,
	handlePageChange,
}) => {
	return (
		<Container style={{ position: "relative" }}>
			<FiltersExpertsDrawer
				container={containerRef}
				province={province}
				localities={localities}
				locality={locality}
				skills={skills}
				rating={rating}
				handleProvincesChange={handleProvincesChange}
				handleLocalitiesChange={handleLocalitiesChange}
				handleSkillsChange={handleSkillsChange}
				handleRatingChange={handleRatingChange}
				handleDrawerToggle={handleDrawerToggle}
				isOpen={isOpen}
				handleCleanFilters={handleCleanFilters}
			>
				<Grid
					container
					rowSpacing={{ xs: 2, sm: 2, md: 3 }}
					columnSpacing={{ xs: 2, sm: 2, md: 3 }}
				>
					{loading ? (
						<Grid
							container
							spacing={3}
							sx={{ maxWidth: 1100, marginTop: "1rem" }}
						>
							{[1, 2, 3, 4, 5, 6].map((item) => (
								<Grid item key={item} xs={12} sm={6} md={4}>
									<ExpertCardSkeleton />
								</Grid>
							))}
						</Grid>
					) : data.length > 0 ? (
						data.map((expert) => (
							<Grid
								key={expert._id}
								item
								mb={1}
								lg={4}
								md={"auto"}
								xs={12}
								sx={{ display: "flex" }}
							>
								<ExpertCard data={expert} />
							</Grid>
						))
					) : (
						<Grid
							item
							mb={1}
							xs={12}
							sx={{
								height: "100vh",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								margin: "3rem 0",
							}}
						>
							<Typography>
								Lo sentimos, parece que todavía no tenemos
								expertos que se ajusten a sus criterios de
								búsqueda.😧
							</Typography>
						</Grid>
					)}

					<Grid
						container
						columnSpacing={{ xs: 2, sm: 2, md: 3 }}
						justifyContent={"center"}
						mt={5}
					>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								disabled={currentPage === 1}
								onClick={() =>
									handlePageChange(currentPage - 1)
								}
							>
								Anterior
							</Button>
						</Grid>
						<Grid item>
							<Typography variant="body1">
								{currentPage} de{" "}
								{Math.ceil(totalExperts / pageSize)}
							</Typography>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								disabled={
									currentPage ===
									Math.ceil(totalExperts / pageSize)
								}
								onClick={() =>
									handlePageChange(currentPage + 1)
								}
							>
								Siguiente
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</FiltersExpertsDrawer>
		</Container>
	);
};

export default SearchExpertPageUI;
