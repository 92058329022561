import React, { useState, useEffect } from "react";
import FavoriteHousingsUI from "./FavoriteHousingsUI";
import getFavoriteHousings from "../../../networking/endpoints/housing/getFavoriteHousings";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";
import unmarkHousingAsFavorite from "../../../networking/endpoints/housing/unmarkHousingAsFavorite";

function FavoriteHousings() {
	const [loadingHousings, setLoadingHousings] = useState(false);
	const [loading, setLoading] = useState(false);
	const [housings, setHousings] = useState([]);
	const navigate = useNavigate();
	const token = localStorage.getItem("authToken");

	useEffect(() => {
		async function getHousings() {
			try {
				setLoadingHousings(true);
				const housings = await getFavoriteHousings(token);
				setHousings(housings);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error,
				});
			} finally {
				setLoadingHousings(false);
			}
		}
		getHousings();
	}, [token]);

	const handleClickHousing = (housingId) => {
		navigate(constants.PAGES.HOUSING_PAGE + "/" + housingId);
	};

	const onPressFavorite = async (housingId) => {
		try {
			setLoading(true);
			await unmarkHousingAsFavorite(token, housingId);
			const housings = await getFavoriteHousings(token);
			setHousings(housings);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<FavoriteHousingsUI
			loadingHousings={loadingHousings}
			loading={loading}
			housings={housings}
			handleClickHousing={handleClickHousing}
			onPressFavorite={onPressFavorite}
		/>
	);
}

export default FavoriteHousings;
