import React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import constants from "../../../config/constants";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import NavBarUserAccount from "../../components/NavBarUserAccount/NavBarUserAccount";
import { Alert } from "@mui/material";

function ChangeEmailPageUI({
	loading,
	email,
	setEmail,
	onSubmit,
	repeatEmail,
	setRepeatEmail,
	googleId,
}) {
	const theme = useTheme();
	return (
		<>
			<LoadingOverlay isLoading={loading} />
			<NavBarUserAccount />
			<Container component="main" maxWidth="md">
				<CssBaseline />

				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography component="h5" variant="h5">
						{constants.MISC_TEXT.CHANGE_EMAIL}
					</Typography>

					<Box
						component="form"
						onSubmit={(e) => {
							e.preventDefault(); // Prevent default form submission
							onSubmit(); // Call the onSubmit function passed as a prop
						}}
						noValidate
						sx={{ mt: 1 }}
					>
						{googleId && (
							<Alert
								severity="warning"
								sx={{ mt: "1rem", mb: "1rem" }}
							>
								No disponible para cuentas creadas con google
							</Alert>
						)}
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Nuevo Email"
							name="email"
							autoComplete="email"
							autoFocus
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							disabled={loading || googleId !== null}
						/>
						<TextField
							margin="normal"
							required
							fullWidth
							id="email"
							label="Repetir Email"
							name="email"
							autoComplete="email"
							autoFocus
							value={repeatEmail}
							onChange={(e) => setRepeatEmail(e.target.value)}
							disabled={loading || googleId !== null}
						/>

						<Box
							sx={{
								display: "flex",
								alignItems: "center", // Center the loading spinner vertically
								justifyContent: "center", // Center the loading spinner horizontally
								flexDirection: "column", // Stack the loading spinner on top of the button
							}}
						>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									backgroundColor: `${theme.palette.secondary.main} !important`,
								}}
								disabled={loading || googleId !== null}
							>
								{constants.BUTTON_NAMES.SAVE}
							</Button>
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
}

export default ChangeEmailPageUI;
