import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function editRating(
	token,
	expertId,
	comment,
	rating,
	ratingId
) {
	try {
		// Set the Authorization header with the token
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		// Make the API request to update user data
		const response = await api.put(configuration.BASE_URL + "ratings/", {
			expertId: expertId,
			comment: comment,
			rating: rating,
			ratingId: ratingId,
		});

		const data = await response.data;
		return data; // Return the updated user data
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
