import React, { useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Card,
} from "@mui/material";
import { Delete, PictureAsPdf } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import { Document, Page } from "react-pdf";

function InvoiceRow({
	rows,
	selectedPDF,
	openPdfDialog,
	handleAddRow,
	handleDeleteRow,
	handlePdfChange,
	handlePdfClick,
	handleClosePdfDialog,
}) {
	const [numPages, setNumPages] = useState(null);
	const pageNumber = 1;

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">Empresa</TableCell>
						<TableCell align="center">Fecha de emision</TableCell>
						<TableCell align="center">Total</TableCell>
						<TableCell align="center">PDF</TableCell>
						<TableCell align="center">Borrar</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.id}>
							<TableCell align="center">{row.empresa}</TableCell>
							<TableCell align="center">{row.fecha}</TableCell>
							<TableCell align="center">{row.total}  {row.total ? "ARS" : undefined}</TableCell>
							<TableCell align="center">
								{row.pdf ? (
									<Card
										onClick={() => handlePdfClick(row.pdf)}
										sx={{
											width: "3rem",
											height: "3rem",
											margin: "auto",
											justifyContent: "center",
										}}
									>
										<Document
											file={row.pdf}
											onLoadSuccess={
												onDocumentLoadSuccess
											}
										>
											<Page pageNumber={pageNumber} />
										</Document>
									</Card>
								) : (
									<input
										type="file"
										accept=".pdf"
										onChange={(event) =>
											handlePdfChange(event, row.id)
										}
										style={{ display: "none" }}
										id={`pdf-upload-${row.id}`}
									/>
								)}

								<Tooltip title="Subir PDF">
									<label htmlFor={`pdf-upload-${row.id}`}>
										<IconButton component="span">
											{row.pdf ? null : <PictureAsPdf />}
										</IconButton>
									</label>
								</Tooltip>
							</TableCell>
							<TableCell align="center">
								<Tooltip title="Borrar">
									<IconButton
										onClick={() => handleDeleteRow(row.id)}
									>
										<Delete />
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Button
				variant="contained"
				onClick={handleAddRow}
				sx={{
					margin: "1rem",
				}}
			>
				Agregar Factura
			</Button>
			<Dialog open={openPdfDialog} onClose={handleClosePdfDialog}>
				<DialogTitle>PDF Preview</DialogTitle>
				<DialogContent>
					<Document
						file={selectedPDF}
						onLoadSuccess={onDocumentLoadSuccess}
					>
						<Page pageNumber={pageNumber} />
					</Document>
					<p>
						Page {pageNumber} of {numPages}
					</p>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClosePdfDialog} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</TableContainer>
	);
}

export default InvoiceRow;
