import React, { useRef } from "react";
import {
	Dashboard,
	ExpandMore,
	Facebook,
	LinkedIn,
	Star,
} from "@mui/icons-material";
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Avatar,
	Badge,
	Button,
	Chip,
	Divider,
	Grid,
	IconButton,
	Link,
	Rating,
	Typography,
	useTheme,
} from "@mui/material";
import { Box, Container, Stack } from "@mui/system";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Tooltip from "@mui/material/Tooltip";

import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

import { Comment } from "../../components/Comment/Comment";
import ContactModal from "../../components/ContactModal/ContactModal";

function ExpertProfilePageUI({
	user,
	reviews,
	loading,
	handleOpenModal,
	handleCloseModal,
	modalOpen,
	handleSendMail,
	handleReviewExpert,
	isFavorite,
	handleMarkAsFavorite,
	handleUnmarkAsFavorite,
}) {
	const theme = useTheme();
	const skillRef = useRef(null);

	const handleGoToSkills = () => {
		skillRef.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});
	};
	if (user !== null) {
		return (
			<>
				<LoadingOverlay isLoading={loading} />
				<Box>
					<Box
						sx={{
							backgroundImage: (theme) =>
								theme.palette.primary.main,
							backgroundColor: (theme) =>
								theme.palette.secondary.main,
						}}
					>
						<Container sx={{ display: "flex" }} maxWidth="false">
							<Badge
								badgeContent={0}
								overlap="circular"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
							>
								<Avatar
									src={user.profileImageUrl}
									sx={{
										width: "180px",
										height: "180px",
										fontSize: "2rem",
										alignSelf: "center",
										transform: "translateY(3rem)",
									}}
								/>
							</Badge>
							<Box sx={{ flexGrow: 1 }} />
							<Box alignSelf="flex-end">
								<IconButton
									component={Link}
									href={user.facebookUrl}
									target="_blank"
								>
									<Facebook
										sx={{ fill: "#FFF" }}
										fontSize="large"
									/>
								</IconButton>
								<IconButton
									component={Link}
									href={user.linkedinUrl}
									target="_blank"
								>
									<LinkedIn
										sx={{ fill: "#FFF" }}
										fontSize="large"
									/>
								</IconButton>
							</Box>
						</Container>
					</Box>
					<Container sx={{ marginTop: "4rem" }} maxWidth="false">
						<Grid container>
							<Grid item xs={12} md={6}>
								<Stack>
									<Rating
										value={user.rating}
										readOnly
										size="medium"
										precision={0.5}
									/>
									<Typography variant="h4">
										{user.firstName + " " + user.lastName}
									</Typography>
									<Typography gutterBottom>
										<strong>{user.expertTitle}</strong>
									</Typography>
								</Stack>
							</Grid>
							<Grid
								item
								container
								xs={12}
								md={6}
								columnSpacing={2}
								rowSpacing={2}
							>
								<Grid item xs={12} md={12} lg={6}>
									<Stack>
										<Typography gutterBottom>
											Habilidades
										</Typography>
										<Grid container spacing={1}>
											{user.skills
												.slice(0, 4)
												.map((skill) => (
													<Grid item key={skill}>
														<Chip label={skill} />
													</Grid>
												))}
											{user.skills.length - 4 > 0 && (
												<Grid item key="plus-skills">
													<IconButton
														onClick={
															handleGoToSkills
														}
													>
														+
														{user.skills.length - 4}
													</IconButton>
												</Grid>
											)}
										</Grid>
									</Stack>
								</Grid>
								{/* Add the favorite button here */}
								<Grid
									item
									xs={12}
									md={12}
									lg={6}
									sx={{
										display: "flex",
										justifyContent: "flex-end",
										alignItems: "center",
									}}
								>
									<Tooltip
										title={
											isFavorite
												? "Quitar de favoritos"
												: " Marcar como favorito"
										}
									>
										<div
											style={{
												width: "60px",
												height: "60px",
												border: "0.5px solid #2D8D59",
												borderRadius: "50%",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}
										>
											<IconButton
												style={{
													color: isFavorite
														? "#1d3622"
														: "#ccc",
												}}
											>
												<FavoriteIcon
													fontSize="large"
													onClick={
														isFavorite
															? handleUnmarkAsFavorite
															: handleMarkAsFavorite
													}
												/>
											</IconButton>
										</div>
									</Tooltip>
								</Grid>
							</Grid>
						</Grid>
					</Container>
					<Divider sx={{ mb: 1, mt: 1 }} />
					<Container maxWidth="false">
						<Accordion defaultExpanded elevation={0}>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography
									variant="h6"
									sx={{ fontWeight: 600 }}
								>
									Sobre mí
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Typography variant="body1" gutterBottom>
									{user.expertDescription}
								</Typography>
							</AccordionDetails>
						</Accordion>
					</Container>

					<Container maxWidth="false">
						<Accordion defaultExpanded elevation={0}>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography
									variant="h6"
									sx={{ fontWeight: 600 }}
								>
									Comentarios
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container rowSpacing={2}>
									{reviews.map((review) => (
										<Comment
											key={review.id}
											review={review}
										/>
									))}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Container>

					<Container maxWidth="false">
						<Accordion
							defaultExpanded
							elevation={0}
							sx={{ scrollMarginTop: "50px  " }}
						>
							<AccordionSummary
								expandIcon={<ExpandMore />}
								aria-controls="panel1a-content"
								id="panel1a-header"
							>
								<Typography
									variant="h6"
									sx={{ fontWeight: 600 }}
								>
									Habilidades
								</Typography>
							</AccordionSummary>
							<AccordionDetails>
								<Grid container spacing={1}>
									{user.skills.map((skill) => (
										<Grid item key={skill}>
											<Chip label={skill} />
										</Grid>
									))}
								</Grid>
							</AccordionDetails>
						</Accordion>
					</Container>

					<Box
						sx={{
							width: "100%",
							padding: "3rem",
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
							gap: "1rem",
						}}
					>
						<Button
							fullWidth
							startIcon={<Star />}
							variant="contained"
							//component={RouterLink}
							//to={constants.PAGES.CONTACT_EXPERT}
							sx={{
								backgroundColor: theme.palette.secondary.main,
							}}
							onClick={handleReviewExpert}
						>
							{"Calificar"}
						</Button>
						<Button
							fullWidth
							startIcon={<Dashboard />}
							variant="contained"
							//component={RouterLink}
							//to={constants.PAGES.CONTACT_EXPERT}
							sx={{
								backgroundColor: theme.palette.secondary.main,
							}}
							onClick={handleOpenModal}
						>
							{"Contactar"}
						</Button>
					</Box>
				</Box>
				<ContactModal
					open={modalOpen}
					onClose={handleCloseModal}
					phoneNumber={user.phone}
					handleSendMail={handleSendMail}
				/>
			</>
		);
	} else {
		return (
			<div
				style={{
					height: "100vh",
				}}
			>
				<LoadingOverlay isLoading={loading} />
			</div>
		);
	}
}

export default ExpertProfilePageUI;
