import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function signUp(email, password, firstName, lastName) {
	try {
		const response = await api.post(
			configuration.BASE_URL + "users/signup",
			{
				email: email,
				password: password,
				firstName: firstName,
				lastName: lastName,
			}
		);
		const data = await response.data;

		return data; // Return the user data and token
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
