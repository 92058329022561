import React, { useState } from "react";
import ChangePasswordUI from "./ChangePasswordUI";
import changePassword from "../../../networking/endpoints/user/changePassword";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

function ChangePasswordPage() {
	const { token, id, googleId } = useSelector((state) => state.userReducer);
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");

	const handleChangePassword = async () => {
		try {
			if (newPassword !== repeatPassword) {
				Swal.fire({
					icon: "error",
					title: "Erro",
					text: "Las contraseñas no coinciden",
				});
				return;
			}
			//show a prompt before changing the password
			const { value: accept } = await Swal.fire({
				title: "¿Estás seguro?",
				text: "¿Estás seguro que deseas cambiar tu contraseña?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Sí",
				cancelButtonText: "No",
			});
			if (!accept) return;
			setLoading(true);
			await changePassword(token, id, password, newPassword);
			setPassword("");
			setNewPassword("");
			setRepeatPassword("");
			Swal.fire({
				icon: "success",
				title: "¡Contraseña cambiada!",
				text: "Tu contraseña ha sido cambiada exitosamente",
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<ChangePasswordUI
			loading={loading}
			password={password}
			newPassword={newPassword}
			repeatPassword={repeatPassword}
			setPassword={setPassword}
			setNewPassword={setNewPassword}
			setRepeatPassword={setRepeatPassword}
			onSubmit={handleChangePassword}
			googleId={googleId}
		/>
	);
}

export default ChangePasswordPage;
