import React from "react";
import {
	Alert,
	AlertTitle,
	Button,
	Step,
	StepLabel,
	Stepper,
	Typography,
	Link,
	TextField,
	InputAdornment,
	Select,
	InputLabel,
	MenuItem,
	FormControl,
	Chip,
	Autocomplete,
	FormHelperText,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import constants from "../../../config/constants";
import ImagePicker from "../../components/ImagePicker/ImagePicker";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

const CreatePropertyPageUI = ({
	handleSubmit,
	activeStep,
	handleBack,
	handleNext,
	title,
	setTitle,
	description,
	setDescription,
	publicationType,
	setPublicationType,
	numRooms,
	setNumRooms,
	numBedrooms,
	setNumBedrooms,
	size,
	setSize,
	price,
	setPrice,
	tags,
	setTags,
	handleImageDrop,
	handleImageRemove,
	images,
	isMobileScreen,
	province,
	handleProvinceChange,
	localities,
	locality,
	setLocality,
	loading,
	street,
	setStreet,
	streetNumber,
	setStreetNumber,
	efficiencyLevel,
	setEfficiencyLevel,
	housingType,
	setHousingType,
}) => {
	const steps = ["Sobre la vivienda", "Datos de dirección"];

	return (
		<Container maxWidth="md">
			<Typography
				mt={2}
				variant="h1"
				fontSize="2rem"
				fontWeight="bold"
				gutterBottom
				style={{ textAlign: "center", margin: "2rem" }}
			>
				Crear Vivienda
			</Typography>
			<Stepper
				activeStep={activeStep}
				orientation={isMobileScreen ? "vertical" : "horizontal"}
			>
				{steps.map((label) => {
					const stepProps = {};
					const labelProps = {};
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>

			<Box
				id="create-property-form"
				component="form"
				noValidate
				sx={{
					margin: "3rem auto 0",
				}}
				onSubmit={handleSubmit}
			>
				<>
					<Box m={4}>
						{activeStep === 0 && (
							<>
								<Alert severity="info">
									<AlertTitle>Bienvenido</AlertTitle>
									<br />
									{
										constants.ALERT_TEXT
											.TERMS_AND_CONDITIONS_NOTIFIER
									}{" "}
									<Link
										component={RouterLink}
										to={
											constants.PAGES.TERMS_AND_CONDITIONS
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										Términos y Condiciones de uso
									</Link>
									.
								</Alert>

								{/* Step 1 Input Components */}
								<TextField
									label="Título"
									value={title}
									onChange={(e) => setTitle(e.target.value)}
									fullWidth
									margin="normal"
									required
								/>

								<TextField
									label="Descripción"
									value={description}
									onChange={(e) =>
										setDescription(e.target.value)
									}
									fullWidth
									margin="normal"
									multiline
									rows={4}
									required
								/>
								<FormControl fullWidth margin="normal">
									<InputLabel id="publicationType-label">
										Nivel de Eficiencia Energética
									</InputLabel>
									<Select
										labelId="efficiencyLevel-label"
										id="efficiencyLevel"
										value={efficiencyLevel}
										onChange={(event) =>
											setEfficiencyLevel(
												event.target.value
											)
										}
										label="Nivel de Eficiencia Energética"
									>
										<MenuItem value="A">A</MenuItem>
										<MenuItem value="B">B</MenuItem>
										<MenuItem value="C">C</MenuItem>
										<MenuItem value="D">D</MenuItem>
										<MenuItem value="E">E</MenuItem>
										<MenuItem value="F">F</MenuItem>
										<MenuItem value="G">G</MenuItem>
									</Select>
									<FormHelperText>
										Si no sabes que es el PRONEV o tu
										vivienda no ha sido etiquetada deja este
										campo libre.
									</FormHelperText>
								</FormControl>

								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
										gap: "1rem",
									}}
								>
									<FormControl
										margin="normal"
										fullWidth
										required
									>
										<InputLabel id="publicationType-label">
											Tipo de Publicación
										</InputLabel>
										<Select
											labelId="publicationType-label"
											id="publicationType"
											value={publicationType}
											onChange={(event) =>
												setPublicationType(
													event.target.value
												)
											}
											label="Tipo de Publicación"
										>
											<MenuItem value="rent">
												Alquiler
											</MenuItem>
											<MenuItem value="sale">
												Venta
											</MenuItem>
										</Select>
									</FormControl>

									<FormControl
										fullWidth
										margin="normal"
										required
									>
										<InputLabel id="publicationType-label">
											Tipo de Vivienda
										</InputLabel>
										<Select
											labelId="publicationType-label"
											id="publicationType"
											value={housingType}
											onChange={(event) =>
												setHousingType(
													event.target.value
												)
											}
											label="Tipo de Vivienda"
										>
											<MenuItem value="apartment">
												Departamento
											</MenuItem>
											<MenuItem value="house">
												Casa
											</MenuItem>
										</Select>
									</FormControl>
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
										gap: "1rem",
									}}
								>
									<TextField
										label="Cantidad de ambientes"
										value={numRooms}
										required
										onChange={(e) => {
											const newValue = parseInt(
												e.target.value,
												10
											);
											if (
												!isNaN(newValue) &&
												newValue >= 0
											) {
												setNumRooms(newValue);
											}
										}}
										type="number"
										fullWidth
										margin="normal"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													ambientes
												</InputAdornment>
											),
										}}
									/>

									<TextField
										label="Cantidad de Dormitorios"
										value={numBedrooms}
										onChange={(e) => {
											const newValue = parseInt(
												e.target.value,
												10
											);
											if (
												!isNaN(newValue) &&
												newValue >= 0
											) {
												setNumBedrooms(newValue);
											}
										}}
										type="number"
										required
										fullWidth
										margin="normal"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													dormitorios
												</InputAdornment>
											),
										}}
									/>
								</Box>

								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
										gap: "1rem",
									}}
								>
									<TextField
										label="Tamaño"
										value={size}
										type="number"
										onChange={(e) => {
											const newValue = parseInt(
												e.target.value,
												10
											);
											if (
												!isNaN(newValue) &&
												newValue >= 0
											) {
												setSize(newValue);
											}
										}}
										fullWidth
										required
										margin="normal"
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													m²
												</InputAdornment>
											),
										}}
									/>

									<TextField
										label="Precio"
										value={price}
										onChange={(e) => {
											const newValue = parseInt(
												e.target.value,
												10
											);
											if (
												!isNaN(newValue) &&
												newValue >= 0
											) {
												setPrice(newValue);
											}
										}}
										type="number"
										fullWidth
										required
										margin="normal"
										InputProps={{
											startAdornment: (
												<InputAdornment position="start">
													$
												</InputAdornment>
											),
											endAdornment: (
												<InputAdornment position="end">
													USD
												</InputAdornment>
											),
										}}
									/>
								</Box>

								<FormControl
									sx={{ width: "100%" }}
									margin="normal"
								>
									<Autocomplete
										value={tags}
										fullWidth
										multiple
										options={constants.TAG_OPTIONS} // Provide predefined options here
										freeSolo
										onChange={(event, newValue) => {
											setTags(newValue);
										}}
										renderTags={(tagValue, getTagProps) =>
											tagValue.map((option, index) => (
												<Chip
													label={option}
													{...getTagProps({ index })}
													onDelete={() => {
														const newTags = [
															...tags,
														];
														newTags.splice(
															index,
															1
														);
														setTags(newTags);
													}}
												/>
											))
										}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Agrega etiquetas para que sea mas facil encontrar tu publicación"
											/>
										)}
									/>
								</FormControl>

								<ImagePicker
									text="Arrastrar imagenes de la vivienda aqui o hacer click para seleccionarlas"
									images={images}
									handleImageRemove={handleImageRemove}
									handleImageDrop={handleImageDrop}
								/>
							</>
						)}

						{activeStep === 1 && (
							<>
								<Alert severity="info">
									{
										constants.ALERT_TEXT
											.ADDRESS_USAGE_NOTIFIER
									}{" "}
								</Alert>

								<FormControl margin="normal" fullWidth required>
									<InputLabel id="demo-simple-select-autowidth-label">
										{constants.MISC_TEXT.PROVINCE}
									</InputLabel>
									<Select
										labelId="demo-simple-select-autowidth-label"
										id="demo-simple-select-autowidth"
										value={province}
										onChange={(event) =>
											handleProvinceChange(
												event.target.value
											)
										}
										label={constants.MISC_TEXT.PROVINCE}
									>
										{constants.PROVINCES.map((province) => {
											return (
												<MenuItem
													value={province}
													key={province}
												>
													{province}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
								<FormControl margin="normal" fullWidth required>
									<InputLabel id="demo-simple-select-autowidth-label">
										{constants.MISC_TEXT.LOCALITY}
									</InputLabel>
									<Select
										labelId="demo-simple-select-autowidth-label"
										id="demo-simple-select-autowidth"
										value={locality}
										onChange={(event) =>
											setLocality(event.target.value)
										}
										label={constants.MISC_TEXT.LOCALITY}
									>
										{localities.map((locality) => {
											return (
												<MenuItem
													value={locality}
													key={locality}
												>
													{locality}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
										gap: "1rem",
									}}
								>
									<TextField
										label="Calle"
										value={street}
										required
										onChange={(e) =>
											setStreet(e.target.value)
										}
										fullWidth
										margin="normal"
									/>
									<TextField
										label="Altura"
										value={streetNumber}
										required
										onChange={(e) =>
											setStreetNumber(e.target.value)
										}
										type="number"
										fullWidth
										margin="normal"
									/>
								</Box>
							</>
						)}
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
						{activeStep !== 4 && (
							<>
								<Button
									color="inherit"
									disabled={activeStep === 0}
									onClick={handleBack}
									sx={{ mr: 1 }}
								>
									Anterior
								</Button>
								<Box sx={{ flex: "1 1 auto" }} />

								{activeStep !== steps.length - 1 && (
									<Button
										sx={{ color: "#2D8D59" }}
										onClick={handleNext}
									>
										{"Siguiente"}
									</Button>
								)}
								{}
								{activeStep === steps.length - 1 && (
									<Button
										sx={{ color: "#2D8D59" }}
										type="submit"
									>
										{"Crear"}
									</Button>
								)}
							</>
						)}
					</Box>
				</>
			</Box>
			<LoadingOverlay isLoading={loading} />
		</Container>
	);
};

export default CreatePropertyPageUI;
