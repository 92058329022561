import React from "react";
import NavBarUserAccount from "../../components/NavBarUserAccount/NavBarUserAccount";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import constants from "../../../config/constants";
import { Alert } from "@mui/material";

function DeleteAccountPageUI({
	loading,
	onSubmit,
	password,
	setPassword,
	googleId,
}) {
	const theme = useTheme();
	return (
		<>
			<LoadingOverlay isLoading={loading} />
			<NavBarUserAccount />
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography component="h5" variant="h5">
						{constants.MISC_TEXT.DELETE_ACCOUNT}
					</Typography>
					<Box
						component="form"
						onSubmit={(e) => {
							e.preventDefault(); // Prevent default form submission
							onSubmit(); // Call the onSubmit function passed as a prop
						}}
						noValidate
					>
						<Alert
							severity="warning"
							sx={{ mt: "1rem", mb: "1rem" }}
						>
							{constants.MISC_TEXT.DELETE_ACCOUNT_WARNING}
						</Alert>

						{!googleId && (
							<TextField
								margin="normal"
								required
								fullWidth
								name="password"
								label="Contraseña"
								type="password"
								id="password"
								autoComplete="current-password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
							/>
						)}

						<Box
							sx={{
								display: "flex",
								alignItems: "center", // Center the loading spinner vertically
								justifyContent: "center", // Center the loading spinner horizontally
								flexDirection: "column", // Stack the loading spinner on top of the button
							}}
						>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{
									mt: 3,
									mb: 2,
									backgroundColor: `${theme.palette.secondary.main} !important`,
								}}
							>
								{constants.BUTTON_NAMES.DELETE_ACCOUNT}
							</Button>
						</Box>
					</Box>
				</Box>
				<LoadingOverlay isLoading={loading} />
			</Container>
		</>
	);
}

export default DeleteAccountPageUI;
