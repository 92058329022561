import React from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Card,
	CardMedia,
} from "@mui/material";

function DevicesTable({
	rows,
	openImageDialog,
	handleImageClick,
	handleCloseImageDialog,
	selectedImage,
}) {
	return (
		<TableContainer component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell align="center">Marca</TableCell>
						<TableCell align="center">Modelo</TableCell>
						<TableCell align="center">Dispositivo</TableCell>
						<TableCell align="center">Eficiencia</TableCell>
						<TableCell align="center">Consumo</TableCell>
						<TableCell align="center">Imagen</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.id}>
							<TableCell align="center">{row.brand}</TableCell>
							<TableCell align="center">{row.model}</TableCell>
							<TableCell align="center">{row.device}</TableCell>
							<TableCell align="center">
								{row.efficiency}
							</TableCell>
							<TableCell align="center">
								{row.consumption} kW/h
							</TableCell>
							<TableCell align="center">
								<Card
									onClick={() =>
										handleImageClick(row.imageUrl)
									}
								>
									<CardMedia
										component="img"
										alt="Uploaded Image"
										image={row.imageUrl}
										sx={{
											maxHeight: "60px",
										}}
									/>
								</Card>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
				<DialogTitle>Image Preview</DialogTitle>
				<DialogContent>
					<Card>
						<CardMedia
							component="img"
							alt="Uploaded Image"
							image={selectedImage}
						/>
					</Card>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseImageDialog} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</TableContainer>
	);
}

export default DevicesTable;
