import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function getRatingsForExpertAndUser(
	token,
	expertId,
	userId
) {
	try {
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
		const response = await api.get(
			configuration.BASE_URL + "ratings/" + userId + "/" + expertId
		);
		const data = await response.data;

		return data;
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
