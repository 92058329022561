import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function resetPassword(token, password) {
	try {
		const response = await api.post(
			configuration.BASE_URL + "users/reset-password",
			{
				resetToken: token,
				newPassword: password,
			}
		);
		const data = await response.data;

		return data; // Return the user data and token
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
