import React, { useState } from "react";
import SignUpUI from "./SignUpUI";
import Swal from "sweetalert2";
import { initUser } from "../../../redux/slices/userReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";
import googleSignIn from "../../../networking/endpoints/user/googleSignIn";
import signUp from "../../../networking/endpoints/user/signUp";

function SignUp() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");

	const handleSignUp = async () => {
		try {
			setLoading(true);
			// Call the loginUser API function to perform login
			const response = await signUp(email, password, firstName, lastName);
			// Dispatch the initUser action to save the user data and token in the Redux store
			dispatch(initUser(response));
			localStorage.setItem("authToken", response.token);
			Swal.fire({
				icon: "success",
				title: "Success",
				text: "Sign up successful",
			});

			navigate(constants.PAGES.ACCOUNT);
		} catch (error) {
			// If there's an error, display it using SweetAlert2
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleGoogleLogIn = async (credentialResponse) => {
		setLoading(true);
		try {
			const response = await googleSignIn(credentialResponse.credential);
			dispatch(initUser(response));
			localStorage.setItem("authToken", response.token);
			navigate(constants.PAGES.ACCOUNT);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleGoogleFailure = () => {
		Swal.fire({
			icon: "error",
			title: "Error",
			text: "Login with Google failed",
		});
	};

	return (
		<SignUpUI
			handleSubmit={handleSignUp}
			handleGoogleFailure={handleGoogleFailure}
			handleGoogleLogin={handleGoogleLogIn}
			loading={loading}
			email={email}
			password={password}
			firstName={firstName}
			lastName={lastName}
			setEmail={setEmail}
			setPassword={setPassword}
			setFirstName={setFirstName}
			setLastName={setLastName}
		/>
	);
}

export default SignUp;
