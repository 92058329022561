import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FixedSizeList } from "react-window";
import {
	Grid,
	AccordionDetails,
	AccordionSummary,
	Button,
	FormControlLabel,
	InputAdornment,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from "@mui/material";
import { useState, useMemo } from "react";
import { SearchOutlined } from "@mui/icons-material";
import { ControlledAccordion } from "../ControlledAccordion/ControlledAccordion";
import constants from "../../../config/constants";
import SearchField from "../SearchField/SearchField";

const drawerWidth = 240;

export const FilterdHousingsDrawer = ({
	container,
	isOpen,
	handleDrawerToggle,
	children,
	province,
	localities,
	locality,
	listingType,
	housingType,
	handleProvincesChange,
	handleLocalitiesChange,
	handleListingTypeChange,
	handleHousingTypeChange,
	handleCleanFilters,
	numRooms,
	numBedrooms,
	handleBedroomsChange,
	handleRoomsChange,
	efficiencyLevel,
	handleEfficiencyLevelChange,
	performSearch,
	searchQuery,
	handleSearchInputChange,
	suggestions,
	setSuggestions,
	setSearchQuery,
	handleClickResetSearch,
	searchActive,
}) => {
	const [provinceSearch, setProvinceSearch] = useState("");
	const [localitySearch, setLocalitySearch] = useState("");

	let isFiltered = false;

	if (
		province ||
		locality ||
		listingType ||
		housingType ||
		numRooms ||
		numBedrooms ||
		efficiencyLevel
	) {
		isFiltered = true;
	}

	const handleFiltersClear = () => {
		handleCleanFilters();
	};

	const handleProvinceSearch = (event) => {
		const {
			target: { value },
		} = event;
		setProvinceSearch(value);
	};
	const handleLocalitySearch = (event) => {
		const {
			target: { value },
		} = event;
		setLocalitySearch(value);
	};
	const filteredProvinces = useMemo(
		() =>
			constants.PROVINCES.filter((province) =>
				province.toLowerCase().includes(provinceSearch.toLowerCase())
			),
		[provinceSearch]
	);

	const filteredLocalities = useMemo(
		() =>
			localities.filter((locality) =>
				locality.toLowerCase().includes(localitySearch.toLowerCase())
			),
		[localities, localitySearch]
	);
	const drawer = (
		<div>
			<Toolbar>
				<FilterListIcon />
				<Typography ml={2}>Filtros</Typography>
				{isFiltered && (
					<>
						<Box sx={{ flexGrow: 1 }} />
						<Button onClick={handleFiltersClear}>Borrar</Button>
					</>
				)}
			</Toolbar>
			<Divider />
			<ControlledAccordion
				defaultOpen={Boolean(province)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Provincia</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<TextField
						size="small"
						id="provinceSearch"
						label="Filtrá Provincias"
						name="provinceSearch"
						onChange={(e) => {
							handleProvinceSearch(e);
						}}
						sx={{ mb: 3 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchOutlined />
								</InputAdornment>
							),
						}}
					/>
					<RadioGroup
						onChange={handleProvincesChange}
						value={province}
					>
						<FixedSizeList
							height={200} // Adjust the height as needed
							itemSize={40} // Adjust the item size as needed
							itemCount={filteredProvinces.length} // Replace with the length of your data
						>
							{({ index, style }) => (
								<FormControlLabel
									key={filteredProvinces[index]}
									style={style}
									control={<Radio />}
									label={filteredProvinces[index]}
									value={filteredProvinces[index]}
									onChange={handleProvincesChange}
									checked={
										province === filteredProvinces[index]
									}
								/>
							)}
						</FixedSizeList>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
			<ControlledAccordion
				defaultOpen={Boolean(locality)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Localidad</Typography>
				</AccordionSummary>

				<AccordionDetails>
					<TextField
						size="small"
						id="localitySearch"
						label="Filtrá Localidades"
						name="localitySearch"
						onChange={(e) => {
							handleLocalitySearch(e);
						}}
						sx={{ mb: 3 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchOutlined />
								</InputAdornment>
							),
						}}
					/>
					<RadioGroup
						onChange={handleLocalitiesChange}
						value={locality}
					>
						<FixedSizeList
							height={200} // Adjust the height as needed
							itemSize={40} // Adjust the item size as needed
							itemCount={filteredLocalities.length} // Replace with the length of your data
						>
							{({ index, style }) => (
								<FormControlLabel
									key={filteredLocalities[index]}
									style={style}
									control={<Radio />}
									label={filteredLocalities[index]}
									value={filteredLocalities[index]}
									onChange={handleLocalitiesChange}
									checked={
										locality === filteredLocalities[index]
									}
								/>
							)}
						</FixedSizeList>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
			<ControlledAccordion
				defaultOpen={Boolean(listingType)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Tipo</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<RadioGroup
						onChange={handleListingTypeChange}
						value={listingType}
					>
						<FormControlLabel
							control={<Radio />}
							label="Venta"
							value="sale"
						/>
						<FormControlLabel
							control={<Radio />}
							label="Alquiler"
							value="rent"
						/>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
			<ControlledAccordion
				defaultOpen={Boolean(housingType)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Vivienda</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<RadioGroup
						onChange={handleHousingTypeChange}
						value={housingType}
					>
						<FormControlLabel
							control={<Radio />}
							label="Casa"
							value="house"
						/>
						<FormControlLabel
							control={<Radio />}
							label="Apartamento"
							value="apartment"
						/>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
			<ControlledAccordion
				defaultOpen={Boolean(numBedrooms)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Dormitorios</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<RadioGroup
						onChange={handleBedroomsChange}
						value={numBedrooms}
					>
						<FormControlLabel
							control={<Radio />}
							label="1"
							value="1"
						/>
						<FormControlLabel
							control={<Radio />}
							label="2"
							value="2"
						/>
						<FormControlLabel
							control={<Radio />}
							label="3 o más"
							value="3"
						/>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
			<ControlledAccordion
				defaultOpen={Boolean(numRooms)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Ambientes</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<RadioGroup onChange={handleRoomsChange} value={numRooms}>
						<FormControlLabel
							control={<Radio />}
							label="1"
							value="1"
						/>
						<FormControlLabel
							control={<Radio />}
							label="2"
							value="2"
						/>
						<FormControlLabel
							control={<Radio />}
							label="3 o más"
							value="3"
						/>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
			<ControlledAccordion
				defaultOpen={Boolean(efficiencyLevel)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Eficiencia Energética</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<RadioGroup
						onChange={handleEfficiencyLevelChange}
						value={efficiencyLevel}
					>
						<FormControlLabel
							control={<Radio />}
							label="A"
							value="A"
						/>
						<FormControlLabel
							control={<Radio />}
							label="B"
							value="B"
						/>
						<FormControlLabel
							control={<Radio />}
							label="C"
							value="C"
						/>
						<FormControlLabel
							control={<Radio />}
							label="D"
							value="D"
						/>
						<FormControlLabel
							control={<Radio />}
							label="E"
							value="E"
						/>
						<FormControlLabel
							control={<Radio />}
							label="F"
							value="F"
						/>
						<FormControlLabel
							control={<Radio />}
							label="G"
							value="G"
						/>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
		</div>
	);

	return (
		<Box
			sx={{
				display: "flex",

				backgroundColor: "inherit",
			}}
			id="container-test"
		>
			<Box
				component="nav"
				sx={{
					width: {
						// eslint-disable-next-line no-nested-ternary
						sm: drawerWidth,
					},
					flexShrink: { sm: 0 },
				}}
				aria-label="mailbox folders"
			>
				<Drawer
					variant="temporary"
					open={isOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
						container: container.current,
					}}
					PaperProps={{ style: { position: "absolute" } }}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					ModalProps={{ container: container.current }}
					PaperProps={{
						style: {
							top: "initial",
							left: 0,
							paddingBottom: "80px",
							backgroundColor: "inherit",
							height: "100vh", // Set the height to fill the viewport
							overflowY: "auto", // Add scrolling if the content overflows
						},
					}}
					container={container.current}
					variant="permanent"
					sx={{
						display: {
							xs: "none",
							sm: "block",
						},
						"& .MuiDrawer-paper": {
							width: drawerWidth,
						},
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			<Grid
				container
				columnSpacing={2}
				rowSpacing={2}
				alignItems="center"
				sx={{
					display: "flex",
					bgcolor: (theme) => theme.palette.background.default,
					width: "100%",
					position: "fixed",
					top: "initial",
					left: "initial",
					zIndex: 1000,
					marginLeft: {
						xs: "unset",
						sm: `${drawerWidth}px`,
					},
				}}
			>
				<Grid item>
					<Button
						variant="outlined"
						onClick={handleDrawerToggle}
						sx={{
							display: { xs: "flex", sm: "none" },
							height: "56px",
							marginTop: "4px",
						}}
						startIcon={<FilterListIcon fontSize="small" />}
					>
						Filtrar
					</Button>
				</Grid>
			</Grid>
			<Box
				component="main"
				sx={{
					flexShrink: { sm: 0 },
					flexGrow: 1,
					width: {
						// eslint-disable-next-line no-nested-ternary
						sm: `calc(100% - ${drawerWidth}px)`,
						xs: "calc(100vw - 80px)",
					},
					margin: {
						// eslint-disable-next-line no-nested-ternary
						xs: "0 1.5rem 40px",
						// eslint-disable-next-line no-nested-ternary
						sm: "0 1.5rem 40px",
					},
				}}
			>
				{children && (
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							marginTop: {
								xs: "5rem",
								sm: "1rem",
							},
							marginBottom: "2rem",
						}}
					>
						<SearchField
							performSearch={performSearch}
							searchQuery={searchQuery}
							handleSearchInputChange={handleSearchInputChange}
							suggestions={suggestions}
							setSuggestions={setSuggestions}
							setSearchQuery={setSearchQuery}
						/>
						{searchActive && (
							<Button onClick={handleClickResetSearch}>
								Borrar
							</Button>
						)}
					</Box>
				)}
				{children}
			</Box>
		</Box>
	);
};
