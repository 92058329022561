import React, { useState } from "react";
import ResetPasswordPageUI from "./ResetPasswordPageUI";
import { useParams } from "react-router-dom";
import resetPassword from "../../../networking/endpoints/user/resetPassword";
import Swal from "sweetalert2";
import constants from "../../../config/constants";
import { useNavigate } from "react-router-dom";

function ResetPasswordPage() {
	const navigate = useNavigate();
	const { token } = useParams();
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [loading, setLoading] = useState(false);

	const onSubmit = async () => {
		setLoading(true);
		try {
			if (password !== confirmPassword) {
				throw new Error("Passwords don't match");
			}
			const response = await resetPassword(token, password);
			Swal.fire({
				icon: "success",
				title: response.message,
				showConfirmButton: false,
				timer: 1500,
			});
			navigate(constants.PAGES.LOG_IN);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: error,
				showConfirmButton: false,
				timer: 1500,
			});
		}
		setLoading(false);
	};

	return (
		<ResetPasswordPageUI
			password={password}
			setPassword={setPassword}
			confirmPassword={confirmPassword}
			setConfirmPassword={setConfirmPassword}
			loading={loading}
			onSubmit={onSubmit}
		/>
	);
}

export default ResetPasswordPage;
