import React, { useState, useCallback } from "react";
import Autosuggest from "react-autosuggest";

function SearchField({
	performSearch,
	searchQuery,
	handleSearchInputChange,
	suggestions,
	setSuggestions,
	setSearchQuery,
}) {
	const handleSearch = () => {
		performSearch(searchQuery);
	};

	const handleKeyPress = (e) => {
		if (e.key === "Enter") {
			handleSearch();
		}
	};

	const handleChange = (event, { newValue }) => {
		handleSearchInputChange(event, newValue);
	};

	const renderSuggestion = (suggestion) => {
		return <span>{suggestion}</span>;
	};
	const onSuggestionsFetchRequested = useCallback(({ value }) => {
		setSuggestions(suggestions);
	}, []);

	const onSuggestionsClearRequested = useCallback(() => {
		setSuggestions([]);
	}, []);

	const inputProps = {
		placeholder: "¿Qué estás buscando?",
		value: searchQuery,
		onChange: handleChange,
		onKeyDown: handleKeyPress,
		type: "search",
	};

	return (
		<Autosuggest
			onSuggestionSelected={(event, { suggestion }) => {
				setSearchQuery(suggestion);
			}}
			suggestions={suggestions}
			onSuggestionsFetchRequested={onSuggestionsFetchRequested}
			onSuggestionsClearRequested={onSuggestionsClearRequested}
			getSuggestionValue={(suggestion) => suggestion}
			renderSuggestion={renderSuggestion}
			inputProps={inputProps} // Use the custom TextField here
			theme={{
				input: {
					width: "100%",
					height: "3rem",
				},
				container: {
					width: "80%",
				},
				suggestionsContainer: {
					zIndex: 1,
					backgroundColor: "#ffffff", // Change the background color as desired
					boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", // Add shadow or other styles
					borderRadius: "4px", // Add border radius or other styles
				},
				suggestion: {
					padding: "10px", // Add padding or other styles
					cursor: "pointer", // Change the cursor style as desired
				},
				suggestionHighlighted: {
					backgroundColor: "#f5f5f5", // Change the background color for hovered suggestion
				},
			}}
		/>
	);
}

export default SearchField;
