import api from "../../api";
import { configuration } from "../../../config/configuration";

const uploadInvoices = async (token, invoices, housingId) => {
	try {
		// Set the Authorization header with the token
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		// Make the API request to create a new housing
		const response = await api.post(
			configuration.BASE_URL + "invoices/" + housingId,
			{
				invoices,
			}
		);

		const data = await response.data;
		return data; // Return the created housing data
	} catch (error) {
		throw new Error(error.response.data.error);
	}
};

export default uploadInvoices;
