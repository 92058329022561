import React from "react";
import { Grid, Typography, IconButton, Tooltip, Skeleton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useTheme } from "@mui/material/styles";

function HousingPageSkeleton() {
	const theme = useTheme();
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={5}>
					<Skeleton
						variant="rectangular"
						width={"auto"}
						height={500}
						animation="wave"
					/>
				</Grid>
				<Grid item xs={12} md={7}>
					<div
						style={{
							paddingLeft: "2rem",
							paddingRight: "2rem",
						}}
					>
						<Typography
							variant="h6"
							align="center"
							gutterBottom
							fontWeight="bold"
						>
							<Skeleton animation="wave" width={200} />
						</Typography>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={3}>
								<Typography
									variant="subtitle1"
									gutterBottom
									fontWeight="bold"
								>
									Dirección:
								</Typography>
								<Typography variant="body1" gutterBottom>
									<Skeleton animation="wave" width={150} />
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<Typography
									variant="subtitle1"
									gutterBottom
									fontWeight="bold"
								>
									Precio:
								</Typography>
								<Typography variant="body1" gutterBottom>
									<Skeleton animation="wave" width={100} />
								</Typography>
							</Grid>
							<Grid item xs={12} sm={4} md={3}>
								<Typography
									variant="subtitle1"
									gutterBottom
									fontWeight="bold"
								>
									Condición:
								</Typography>
								<Typography variant="body1" gutterBottom>
									<Skeleton animation="wave" width={80} />
								</Typography>
							</Grid>
							<Grid item xs={12} sm={4} md={3}>
								<Tooltip title="Agregar a favoritos">
									<IconButton
										aria-label="Agregar a favoritos"
										sx={{
											border: "1px solid #ccc",
										}}
									>
										<FavoriteIcon color="inherit" />
									</IconButton>
								</Tooltip>
							</Grid>
						</Grid>

						<Typography
							variant="subtitle1"
							gutterBottom
							fontWeight="bold"
						>
							Descripción:
						</Typography>
						<Typography variant="body1" gutterBottom>
							<Skeleton animation="wave" width={250} />
						</Typography>

						<Typography
							variant="subtitle1"
							gutterBottom
							fontWeight="bold"
						>
							Tags:
						</Typography>

						<div
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-around",
								alignContent: "center",
								flexWrap: "wrap",
								border: "1px solid #ccc",
								borderRadius: "10px",
								borderColor: theme.palette.secondary.main,
								marginBottom: "1rem",
							}}
						>
							<Skeleton
								animation="wave"
								width={200}
								height={40}
							/>
						</div>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6} md={4}>
								<Typography
									variant="subtitle1"
									gutterBottom
									fontWeight="bold"
								>
									Eficiencia:
								</Typography>
								<Skeleton animation="wave" width={100} />
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<Typography
									variant="subtitle1"
									gutterBottom
									fontWeight="bold"
								>
									Ambientes:
								</Typography>
								<Typography variant="body1" gutterBottom>
									<Skeleton animation="wave" width={50} />
								</Typography>
							</Grid>
							<Grid item xs={12} sm={6} md={4}>
								<Typography
									variant="subtitle1"
									gutterBottom
									fontWeight="bold"
								>
									Dormitorios:
								</Typography>
								<Typography variant="body1" gutterBottom>
									<Skeleton animation="wave" width={50} />
								</Typography>
							</Grid>
						</Grid>
					</div>
				</Grid>
			</Grid>
			<Grid container spacing={2} mt={"2rem"}>
				<Grid item xs={12} md={5}>
					<Skeleton
						variant="rectangular"
						width={"auto"}
						height={550}
						animation="wave"
					/>
				</Grid>
				<Grid item xs={12} md={7}>
					<Skeleton animation="wave" width={"auto"} height={550} />
				</Grid>
			</Grid>
			<div
				style={{
					paddingLeft: "1rem",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-evenly",
					alignItems: "center",
					marginTop: "4rem",
					gap: "1rem",
					flexWrap: "wrap",
				}}
			>
				<Skeleton
					animation="wave"
					width={200}
					height={140}
					variant="rectangular"
				/>
				<Skeleton
					animation="wave"
					width={200}
					height={140}
					variant="rectangular"
				/>
				<Skeleton
					animation="wave"
					width={200}
					height={140}
					variant="rectangular"
				/>
				<Skeleton
					animation="wave"
					width={200}
					height={140}
					variant="rectangular"
				/>
				<Skeleton
					animation="wave"
					width={200}
					height={140}
					variant="rectangular"
				/>
			</div>
		</>
	);
}

export default HousingPageSkeleton;
