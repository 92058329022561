import React from "react";

import Modal from "react-modal";
import { Circles } from "react-loader-spinner";

const LoadingOverlay = ({ isLoading }) => {
	// Style for the modal (centered on the screen)
	const modalStyle = {
		overlay: {
			backgroundColor: "rgba(0, 0, 0, 0.5)",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			zIndex: 9999, // Make sure the modal is on top of everything
		},
		content: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			background: "transparent",
			border: "none",
			boxShadow: "none",
			textAlign: "center",
		},
	};

	return (
		<Modal
			isOpen={isLoading}
			style={modalStyle}
			ariaHideApp={false} // This is to prevent warnings about the modal not being accessible
		>
			<Circles
				height="80"
				width="80"
				color="#4fa94d"
				ariaLabel="circles-loading"
				wrapperStyle={{}}
				wrapperClass=""
				visible={true}
			/>
		</Modal>
	);
};

export default LoadingOverlay;
