import React from "react";
import "./AccountPage.css";
import NavBarUserAccount from "../../components/NavBarUserAccount/NavBarUserAccount";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import ImageUploader from "../../components/ImageUploader/ImageUploader";
import CustomDivider from "../../components/CustomDivider/CustomDivider";
import constants from "../../../config/constants";
import Box from "@mui/material/Box";
import ButtonOutline from "../../components/ButtonOutline/ButtonOutline";
import CssBaseline from "@mui/material/CssBaseline";
import InputAdornment from "@mui/material/InputAdornment";
import PhoneIcon from "@mui/icons-material/Phone";
import Button from "@mui/material/Button";
import { useTheme } from "@emotion/react";

function AccountPageUI({
	user,
	loading,
	setLastName,
	lastName,
	setFirstName,
	firstName,
	setDocument,
	document,
	setPhoneNumber,
	phoneNumber,
	image,
	handleImageChange,
	onSubmit,
	handleCreateTechnicalAccount,
	handleViewExpertProfile,
	handleDeleteTechnicalAccount,
	handleEditExpertAccount,
	handleCancelChanges,
}) {
	const theme = useTheme();
	return (
		<>
			<LoadingOverlay isLoading={loading} />
			<NavBarUserAccount />
			<Container component="main" maxWidth="xs">
				<CssBaseline />
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}
				>
					<Typography component="h5" variant="h5">
						Datos Personales
					</Typography>
					<Box
						component="form"
						onSubmit={(e) => {
							e.preventDefault(); // Prevent default form submission
							onSubmit(); // Call the onSubmit function passed as a prop
						}}
					>
						<div
							style={{ marginTop: "1rem", marginBottom: "1rem" }}
						>
							<ImageUploader
								image={image}
								onImageChange={handleImageChange}
							/>
						</div>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-around",
								gap: "1rem",
							}}
						>
							<TextField
								autoComplete="given-name"
								name="firstName"
								required
								fullWidth
								id="firstName"
								label="Nombre"
								autoFocus
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								margin="normal"
							/>
							<TextField
								required
								fullWidth
								id="lastName"
								label="Apellido"
								name="lastName"
								autoComplete="family-name"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								margin="normal"
							/>
						</Box>
						<TextField
							fullWidth
							type="number"
							id="document"
							label="Documento"
							name="document"
							value={document}
							onChange={(e) => setDocument(e.target.value)}
							margin="normal"
						/>
						<TextField
							fullWidth
							id="phoneNumber"
							label="Celular"
							name="phoneNumber"
							value={phoneNumber}
							onChange={(e) => setPhoneNumber(e.target.value)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<PhoneIcon /> +
									</InputAdornment>
								),
							}}
							placeholder="Ej: 54911234567"
							margin="normal"
						/>

						{(firstName !== user.firstName ||
							lastName !== user.lastName ||
							document !== user.documentNumber ||
							phoneNumber !== user.phone ||
							image !== user.profileImageUrl) && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									gap: 2,
									mt: "1rem",
									mb: "1rem",
								}}
							>
								<Button
									fullWidth
									variant="outline"
									sx={{}}
									onClick={handleCancelChanges}
								>
									Cancelar Cambios
								</Button>
								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{
										backgroundColor:
											theme.palette.secondary.main,
									}}
								>
									Guardar Cambios
								</Button>
							</Box>
						)}
					</Box>
				</Box>
			</Container>

			{user.role === "user" && (
				<div style={{ marginTop: "2rem" }}>
					<CustomDivider
						text={
							constants.MISC_TEXT.WANT_TO_CREATE_TECHNICAL_ACCOUNT
						}
					/>
					<Box
						sx={{
							justifyContent: "center",
							display: "flex",
							mt: 2,
							width: "100%",
						}}
					>
						<ButtonOutline
							title="Crear cuenta de Experto"
							onClick={handleCreateTechnicalAccount}
							fullWidth
						/>
					</Box>
				</div>
			)}
			{user.role === "expert" && (
				<div>
					<CustomDivider
						text={
							constants.MISC_TEXT.WANT_TO_EDIT_TECHNICAL_ACCOUNT
						}
					/>
					<Box
						sx={{
							justifyContent: "center",
							display: "flex",
							flexDirection: "row",
							mt: 2,
							gap: 2,
							flexWrap: "wrap",
						}}
					>
						<ButtonOutline
							title={constants.BUTTON_NAMES.EDIT_EXPERT_ACCOUNT}
							onClick={handleEditExpertAccount}
						/>
						<ButtonOutline
							title={constants.BUTTON_NAMES.DELETE_EXPERT_ACCOUNT}
							onClick={handleDeleteTechnicalAccount}
						/>
						<ButtonOutline
							title={constants.BUTTON_NAMES.VIEW_EXPERT_PROFILE}
							onClick={handleViewExpertProfile}
						/>
					</Box>
				</div>
			)}
		</>
	);
}

export default AccountPageUI;
