import { Navigate, useLocation } from "react-router-dom";
import constants from "../../../config/constants";

export default function PrivateRoute({ children }) {
	const authToken = localStorage.getItem("authToken");
	const location = useLocation();

	return authToken ? (
		<>{children}</>
	) : (
		<Navigate to={constants.PAGES.LOG_IN} state={{ from: location }} />
	);
}
