import { createSlice } from "@reduxjs/toolkit";

const defaultState = {
	id: "",
	token: null,
	email: "",
	photo: "",
	role: "",
	firstName: "",
	lastName: "",
	favorites: [],
	isLoggedIn: false,
	googleId: null,
};

const userSlice = createSlice({
	name: "user",
	initialState: defaultState,

	reducers: {
		initUser(state, action) {
			state.token = action.payload.token;
			state.id = action.payload.user._id;
			state.email = action.payload.user.email;
			state.photo = action.payload.user.profileImageUrl;
			state.firstName = action.payload.user.firstName;
			state.lastName = action.payload.user.lastName;
			state.isLoggedIn = true;
			state.role = action.payload.user.role;
			state.favorites = action.payload.user.favorites;
			if (action.payload.user.googleId) {
				state.googleId = action.payload.user.googleId;
			}
		},

		logout(state, action) {
			return defaultState;
		},
	},
});

export const { logout, initUser } = userSlice.actions;

export default userSlice.reducer;
