import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function getLocation(formattedAddress) {
	try {
		// Make the API request to get user data
		const response = await api.post(
			configuration.BASE_URL + `housings/getCoordinates`,
			{ formattedAddress: formattedAddress },
			{
				timeout: 40000,
			}
		);
		const data = await response.data;

		return data; // Return the user data
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
