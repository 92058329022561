import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

function ButtonOutline({ title, onClick }) {
	const theme = useTheme();
	return (
		<Button
			variant="contained"
			onClick={onClick}
			sx={{
				backgroundColor: `${theme.palette.secondary.main} !important`,
				width: "100%",
			}}
		>
			{title}
		</Button>
	);
}

export default ButtonOutline;
