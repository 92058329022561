import React, { useEffect, useState } from "react";
import EditHousingPageUI from "./EditHousingPageUI";
import { useParams } from "react-router-dom";
import getHousingById from "../../../networking/endpoints/housing/getHousingById";
import Swal from "sweetalert2";
import axios from "axios";
import editHousing from "../../../networking/endpoints/housing/editHousing";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";

function EditHousingPage() {
	const navigate = useNavigate();
	const { housingId } = useParams();
	const token = localStorage.getItem("authToken");
	const [loading, setLoading] = useState(false);
	const [housing, setHousing] = useState({});
	const [housingType, setHousingType] = useState("");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [publicationType, setPublicationType] = useState("");
	const [numRooms, setNumRooms] = useState(0); //ambientes
	const [numBedrooms, setNumBedrooms] = useState(0);
	const [size, setSize] = useState(0);
	const [price, setPrice] = useState(0);
	const [tags, setTags] = useState([]); //etiquetas
	const [images, setImages] = useState([]); //imagenes
	const [province, setProvince] = useState("");
	const [localities, setLocalities] = useState([]);
	const [locality, setLocality] = useState("");
	const [street, setStreet] = useState("");
	const [streetNumber, setStreetNumber] = useState("");
	const [efficiencyLevel, setEfficiencyLevel] = useState("");
	const [imagesToDelete, setImagesToDelete] = useState([]);
	const [fieldsEdited, setFieldsEdited] = useState(false);
	const [imagesToUpload, setImagesToUpload] = useState([]); //imagenes

	const capitalizeFirstLetter = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	};

	const fetchLocalities = async (provincia) => {
		setLoading(true); // Set loading to true

		try {
			const response = await axios.get(
				`https://apis.datos.gob.ar/georef/api/localidades?provincia=${provincia}&max=1000`
			);

			const localitiesData = response.data.localidades;
			const formattedLocalities = localitiesData.map((locality) =>
				capitalizeFirstLetter(locality.nombre.toLowerCase())
			);

			// Sort the formattedLocalities array in alphabetical order
			formattedLocalities.sort((a, b) => a.localeCompare(b));

			setLocalities(formattedLocalities);
		} catch (error) {
			console.log(error);
			if (error.code === 1) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "No se pudo cargar las localidades",
				});
			}
		}
	};

	useEffect(() => {
		const getHousing = async () => {
			try {
				setLoading(true);
				const response = await getHousingById(housingId);
				setHousing(response.housing);
				setTitle(response.housing.title);
				setDescription(response.housing.description);
				setPublicationType(response.housing.listingType);
				setNumRooms(response.housing.numRooms);
				setNumBedrooms(response.housing.numBedrooms);
				setSize(response.housing.size);
				setPrice(response.housing.price);
				setTags(response.housing.tags);
				setImages(response.housing.images);
				setEfficiencyLevel(response.housing.energyEfficiencyLevel);
				setHousingType(response.housing.housingType);
				setProvince(response.housing.address.province);
				setStreet(response.housing.address.street);
				setStreetNumber(response.housing.address.number);
				fetchLocalities(response.housing.address.province);
				setLocality(response.housing.address.locality);
				setLoading(false);
			} catch (err) {
				setLoading(false);
				Swal.fire({
					title: "Error",
					text: err,
					icon: "error",
				});
			}
		};
		getHousing();
	}, [housingId, token]);

	useEffect(() => {
		if (
			title !== housing.title ||
			description !== housing.description ||
			publicationType !== housing.listingType ||
			numRooms !== housing.numRooms ||
			numBedrooms !== housing.numBedrooms ||
			size !== housing.size ||
			price !== housing.price ||
			efficiencyLevel !== housing.energyEfficiencyLevel ||
			housingType !== housing.housingType ||
			province !== housing.address.province ||
			locality !== housing.address.locality ||
			street !== housing.address.street ||
			streetNumber !== housing.address.number ||
			images.length !== housing.images.length ||
			tags.length !== housing.tags.length
		) {
			setFieldsEdited(true);
		} else {
			setFieldsEdited(false);
		}
	}, [
		title,
		description,
		publicationType,
		numRooms,
		numBedrooms,
		size,
		price,
		efficiencyLevel,
		housingType,
		province,
		locality,
		street,
		streetNumber,
		images,
		tags,
		housing,
	]);

	const handleImageDrop = (acceptedFiles) => {
		if (typeof acceptedFiles !== "string") {
			setImagesToUpload([...imagesToUpload, ...acceptedFiles]);
		}
		if (images.length + acceptedFiles.length <= 6) {
			setImages([...images, ...acceptedFiles]);
		} else {
			Swal.fire({
				icon: "warning",
				title: "Limite Alcanzado",
				text: "Se pueden subir hasta 6 imagenes",
				confirmButtonText: "OK",
			});
		}
	};

	const handleImageRemove = (index) => {
		if (typeof images[index] === "string") {
			setImagesToDelete([...imagesToDelete, images[index]]);
		}

		const newImages = [...images];
		newImages.splice(index, 1);
		setImages(newImages);
	};

	const handleProvinceChange = async (province) => {
		setLoading(true); // Set loading to true

		setProvince(province);
		try {
			const response = await axios.get(
				`https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&max=1000`
			);

			const localitiesData = response.data.localidades;
			const formattedLocalities = localitiesData.map((locality) =>
				capitalizeFirstLetter(locality.nombre.toLowerCase())
			);

			// Sort the formattedLocalities array in alphabetical order
			formattedLocalities.sort((a, b) => a.localeCompare(b));

			setLocalities(formattedLocalities);
			// Reset the selected locality
			setLocality("");
		} catch (error) {
			console.log(error);
			if (error.code === 1) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "No se pudo cargar las localidades",
				});
			}
		}

		setLoading(false); // Set loading back to false
	};

	const handleCancel = () => {
		setFieldsEdited(false);
		setTitle(housing.title);
		setDescription(housing.description);
		setPublicationType(housing.listingType);
		setNumRooms(housing.numRooms);
		setNumBedrooms(housing.numBedrooms);
		setSize(housing.size);
		setPrice(housing.price);
		setTags(housing.tags);
		setImages(housing.images);
		setEfficiencyLevel(housing.energyEfficiencyLevel);
		setHousingType(housing.housingType);
		setProvince(housing.address.province);
		setStreet(housing.address.street);
		setStreetNumber(housing.address.number);
		setLocality(housing.address.locality);
		setImagesToDelete([]);
	};

	const handleEditHousing = async () => {
		try {
			setLoading(true);
			const formData = new FormData();
			if (imagesToUpload.length > 0) {
				imagesToUpload.forEach((image) => {
					formData.append("images", image);
				});
			}
			if (title !== housing.title) {
				formData.append("title", title);
			}
			if (description !== housing.description) {
				formData.append("description", description);
			}
			if (publicationType !== housing.listingType) {
				formData.append("listingType", publicationType);
			}
			if (numRooms !== housing.numRooms) {
				formData.append("numRooms", numRooms);
			}
			if (numBedrooms !== housing.numBedrooms) {
				formData.append("numBedrooms", numBedrooms);
			}
			if (size !== housing.size) {
				formData.append("size", size);
			}
			if (price !== housing.price) {
				formData.append("price", price);
			}
			if (tags.length !== housing.tags.length) {
				formData.append("tags", tags);
			}
			if (efficiencyLevel !== housing.energyEfficiencyLevel) {
				formData.append("energyEfficiencyLevel", efficiencyLevel);
			}
			if (housingType !== housing.housingType) {
				formData.append("housingType", housingType);
			}
			if (province !== housing.address.province) {
				formData.append("province", province);
			}
			if (locality !== housing.address.locality) {
				formData.append("locality", locality);
			}

			if (street !== housing.address.street) {
				formData.append("street", street);
			}
			if (streetNumber !== housing.address.number) {
				formData.append("number", streetNumber);
			}
			if (imagesToDelete.length > 0) {
				for (var i = 0; i < imagesToDelete.length; i++) {
					formData.append("imagesToDelete[]", imagesToDelete[i]);
				}
			}
			const response = await editHousing(token, formData, housingId);

			setLoading(false);
			Swal.fire({
				title: "Exito",
				text: response.message,
				icon: "success",
			});
			setFieldsEdited(false);
			navigate(constants.PAGES.USER_HOUSINGS);
		} catch (err) {
			setLoading(false);
			Swal.fire({
				title: "Error",
				text: err,
				icon: "error",
			});
		}
	};

	return (
		<EditHousingPageUI
			loading={loading}
			housingId={housingId}
			title={title}
			setTitle={setTitle}
			description={description}
			setDescription={setDescription}
			publicationType={publicationType}
			setPublicationType={setPublicationType}
			numRooms={numRooms}
			setNumRooms={setNumRooms}
			numBedrooms={numBedrooms}
			setNumBedrooms={setNumBedrooms}
			size={size}
			setSize={setSize}
			price={price}
			setPrice={setPrice}
			tags={tags}
			setTags={setTags}
			images={images}
			setImages={setImages}
			handleImageDrop={handleImageDrop}
			handleImageRemove={handleImageRemove}
			handleProvinceChange={handleProvinceChange}
			province={province}
			localities={localities}
			locality={locality}
			setLocality={setLocality}
			street={street}
			setStreet={setStreet}
			streetNumber={streetNumber}
			setStreetNumber={setStreetNumber}
			efficiencyLevel={efficiencyLevel}
			setEfficiencyLevel={setEfficiencyLevel}
			housingType={housingType}
			setHousingType={setHousingType}
			handleCancel={handleCancel}
			housing={housing}
			fieldsEdited={fieldsEdited}
			handleEditHousing={handleEditHousing}
		/>
	);
}

export default EditHousingPage;
