import React from "react";
import Divider from "@mui/material/Divider";
import { useMediaQuery } from "@mui/material";
import constants from "../../../config/constants";
import { NavLink } from "react-router-dom";
import "./NavbarFavorites.css";

function NavbarFavorites() {
	// Check if the screen size matches a phone breakpoint (adjust the breakpoint as needed)
	const isPhoneSize = useMediaQuery("(max-width: 600px)");

	return (
		<div className="navigatorFavorites">
			<NavLink
				to={constants.PAGES.FAVORITE_HOUSINGS}
				className="navLink" // Add a custom class name for the NavLink
			>
				Viviendas Favoritas
			</NavLink>
			<Divider
				orientation={!isPhoneSize ? "vertical" : "horizontal"}
				flexItem={!isPhoneSize}
				variant="middle"
			/>
			<NavLink
				to={constants.PAGES.FAVORITE_EXPERTS}
				className="navLink" // Add a custom class name for the NavLink
			>
				Expertos Favoritos
			</NavLink>
		</div>
	);
}

export default NavbarFavorites;
