import React, { useState, useEffect } from "react";
import HousingDevicesUI from "./HousingDevicesUI";
import { useParams } from "react-router-dom";
import getDevices from "../../../networking/endpoints/housing/getDevices";
import getInvoices from "../../../networking/endpoints/housing/getInvoices";

function HousingDevices() {
	const { housingId } = useParams();
	const [loading, setLoading] = useState(false);
	const token = localStorage.getItem("authToken");
	const [rows, setRows] = useState([]);
	const [invoiceRows, setInvoiceRows] = useState([]);

	const [selectedImage, setSelectedImage] = useState(null);
	const [openImageDialog, setOpenImageDialog] = useState(false);

	useEffect(() => {
		const getDevicesData = async () => {
			try {
				setLoading(true);
				const response = await getDevices(token, housingId);
				setRows(response.devices);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		getDevicesData();
	}, [housingId, token]);

	useEffect(() => {
		const getInvoicesData = async () => {
			try {
				setLoading(true);
				const response = await getInvoices(token, housingId);
				setInvoiceRows(response.invoices);
				setLoading(false);
			} catch (err) {
				setLoading(false);
			}
		};
		getInvoicesData();
	}, [housingId, token]);

	const handleImageClick = (image) => {
		setSelectedImage(image);
		setOpenImageDialog(true);
	};

	const handleCloseImageDialog = () => {
		setOpenImageDialog(false);
	};

	return (
		<HousingDevicesUI
			housingId={housingId}
			loading={loading}
			handleImageClick={handleImageClick}
			handleCloseImageDialog={handleCloseImageDialog}
			selectedImage={selectedImage}
			openImageDialog={openImageDialog}
			devicesRows={rows}
			invoicesRows={invoiceRows}
		/>
	);
}

export default HousingDevices;
