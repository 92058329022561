import React, { useRef, useState, useCallback, useEffect } from "react";
import SearchExpertPageUI from "./SearchExpertPageUI";
import Swal from "sweetalert2";
import getFilteredExperts from "../../../networking/endpoints/user/getFilteredExperts";
import axios from "axios";

function SearchExpertPage() {
	const container = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [province, setProvince] = useState("");
	const [locality, setLocality] = useState("");
	const [skills, setSkills] = useState([]);
	const [rating, setRating] = useState("");
	const [localities, setLocalities] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalExperts, setTotalExperts] = useState(0);
	const [experts, setExperts] = useState([]);
	const [pageSize] = useState(6);

	const fetchExperts = async (page, filters) => {
		try {
			setLoading(true);
			const response = await getFilteredExperts(page, filters);
			const { experts, totalCount } = response;
			setExperts(experts);
			setTotalExperts(totalCount);
		} catch (error) {
			console.error("Error fetching experts:", error);
			Swal.fire({
				title: "Error",
				text: error,
				icon: "error",
				confirmButtonText: "Aceptar",
			});
		} finally {
			setLoading(false);
		}
	};

	// Function to handle pagination
	const handlePageChange = (newPage) => {
		if (newPage >= 1 && newPage <= Math.ceil(totalExperts / pageSize)) {
			setCurrentPage(newPage);
		}
	};

	useEffect(() => {
		const filters = { province, locality, skills, rating };
		fetchExperts(currentPage, filters);
	}, [province, locality, skills, rating, currentPage]);
	const capitalizeFirstLetter = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	};

	useEffect(() => {
		const fetchLocalities = async () => {
			try {
				const response = await axios.get(
					`https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&max=1000`
				);
				const localitiesData = response.data.localidades;
				const formattedLocalities = localitiesData.map((locality) =>
					capitalizeFirstLetter(locality.nombre.toLowerCase())
				);

				// Remove duplicates from the formattedLocalities array
				const uniqueLocalities = [...new Set(formattedLocalities)];

				// Sort the uniqueLocalities array in alphabetical order
				uniqueLocalities.sort((a, b) => a.localeCompare(b));

				setLocalities(uniqueLocalities);
				// Reset the selected locality
				setLocality("");
			} catch (error) {
				if (error.code === 1) {
					Swal.fire({
						title: "Error",
						text: error,
						icon: "error",
					});
				}
			}
		};
		if (province !== "") {
			fetchLocalities();
		}
	}, [province]);

	const handleDrawerToggle = useCallback(() => {
		setIsOpen(!isOpen);
	}, [isOpen]);

	const handleProvincesChange = useCallback(
		(event) => {
			setProvince(event.target.value);
		},
		[setProvince]
	);

	const handleLocalitiesChange = useCallback(
		(event) => {
			setLocality(event.target.value);
		},
		[setLocality]
	);

	const handleSkillsChange = useCallback(
		(event) => {
			const selectedSkill = event.target.name;
			if (event.target.checked) {
				setSkills((prevSkills) => [...prevSkills, selectedSkill]);
			} else {
				setSkills((prevSkills) =>
					prevSkills.filter((skill) => skill !== selectedSkill)
				);
			}
		},
		[setSkills]
	);

	const handleRatingChange = useCallback(
		(event) => {
			setRating(event.target.value);
		},
		[setRating]
	);

	const handleCleanFilters = useCallback(() => {
		setProvince("");
		setLocality("");
		setSkills([]);
		setRating("");
	}, [setProvince, setLocality, setSkills, setRating]);

	return (
		<SearchExpertPageUI
			data={experts}
			containerRef={container}
			handleDrawerToggle={handleDrawerToggle}
			isOpen={isOpen}
			loading={loading}
			province={province}
			locality={locality}
			skills={skills}
			rating={rating}
			handleProvincesChange={handleProvincesChange}
			handleLocalitiesChange={handleLocalitiesChange}
			handleSkillsChange={handleSkillsChange}
			handleRatingChange={handleRatingChange}
			localities={localities}
			handleCleanFilters={handleCleanFilters}
			pageSize={pageSize}
			handlePageChange={handlePageChange}
			currentPage={currentPage}
			totalExperts={totalExperts}
		/>
	);
}

export default SearchExpertPage;
