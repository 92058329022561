import React from "react";
import styled from "@emotion/styled";
import { Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ButtonBase from "@mui/material/ButtonBase";

const StyledCard = styled(Card)`
	border-radius: 1rem;
	border: 1px solid #2d8d59;
	background-color: #eeeeee50;

	/* Media query for small screens (phones) */
	@media (max-width: 600px) {
		flex-direction: column;
	}

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

const HousingCardSkeleton = () => {
	return (
		<StyledCard elevation={0}>
			<ButtonBase
				sx={{
					flex: 1, // Expand to take the available space
				}}
				// Add any onClick handler you want for the skeleton
			>
				<div
					style={{
						width: "100%",
						height: "100%",
						maxHeight: "17rem",
						backgroundColor: "#ddd", // Placeholder color
					}}
				></div>
			</ButtonBase>
			<CardContent
				sx={{
					flex: 2, // Expand to take the available space
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
					}}
				>
					<Box>
						<div
							style={{
								width: "15rem",
								height: "1rem",
								backgroundColor: "#ddd", // Placeholder color
							}}
						></div>
						<div
							style={{
								width: "15rem",
								height: "1rem",
								backgroundColor: "#ddd", // Placeholder color
							}}
						></div>
					</Box>
					<Box
						sx={{
							display: "flex",
							gap: "1rem",
							flexDirection: "row",
						}}
					>
						<div
							style={{
								width: "24px",
								height: "24px",
								backgroundColor: "#ddd", // Placeholder color
							}}
						></div>
						<div
							style={{
								width: "24px",
								height: "24px",
								backgroundColor: "#ddd", // Placeholder color
							}}
						></div>
					</Box>
				</Box>
				<Divider sx={{ mb: 1 }} />
				<Typography
					variant="body1"
					gutterBottom
					sx={{
						height: "4rem", // Set the maximum height
						backgroundColor: "#ddd", // Placeholder color
					}}
				></Typography>
				<Grid container spacing={1}>
					{[1, 2, 3, 4].map((index) => (
						<Grid item key={index}>
							<div
								style={{
									width: "100%",
									height: "36px",
									backgroundColor: "#ddd", // Placeholder color
								}}
							></div>
						</Grid>
					))}
				</Grid>
				<Divider sx={{ mb: 1, mt: 1 }} />
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between",
						flexWrap: "wrap",
					}}
				>
					<div
						style={{
							width: "15rem",
							height: "2rem",
							backgroundColor: "#ddd", // Placeholder color
						}}
					></div>
					<div
						style={{
							width: "60px",
							height: "24px",
							backgroundColor: "#ddd", // Placeholder color
						}}
					></div>
					<div
						style={{
							width: "80px",
							height: "24px",
							backgroundColor: "#ddd", // Placeholder color
						}}
					></div>
				</Box>
			</CardContent>
		</StyledCard>
	);
};

export default HousingCardSkeleton;
