import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import getUserById from "../../../networking/endpoints/user/getUserById";
import Swal from "sweetalert2";
import ExpertProfilePageUI from "./ExpertProfilePageUI";
import sendEmail from "../../../networking/endpoints/user/sendEmail";
import { useNavigate } from "react-router-dom";
import getRatingsForExpert from "../../../networking/endpoints/rating/getRatingsForExpert";
import getIsFavorite from "../../../networking/endpoints/favoriteExperts/getIsFavorite";
import markAsFavorite from "../../../networking/endpoints/favoriteExperts/markAsFavorite";
import unmarkAsFavorite from "../../../networking/endpoints/favoriteExperts/unmarkFavorite";

function ExpertProfilePage() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [reviews, setReviews] = useState([]);
	const [isFavorite, setIsFavorite] = useState(false);
	const token = localStorage.getItem("authToken");

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await getUserById(id);
				const ratings = await getRatingsForExpert(id);

				setReviews(ratings.ratings);
				setUser(response);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong!",
				});
			} finally {
				setLoading(false);
			}
		};
		fetchData();
	}, [id, token]);

	const handleUnmarkAsFavorite = useCallback(async () => {
		try {
			await unmarkAsFavorite(token, id);
			// Make a toast with sweet alert
			setIsFavorite(false);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	}, [token, id, setIsFavorite]);

	const handleMarkAsFavorite = useCallback(async () => {
		try {
			await markAsFavorite(token, id);
			// Make a toast with sweet alert
			setIsFavorite(true);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	}, [token, id, setIsFavorite]);

	useEffect(() => {
		const fetchFavoriteStatus = async () => {
			try {
				const isFavorite = await getIsFavorite(token, id);
				setIsFavorite(isFavorite.isFavorite);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "Something went wrong!",
				});
			}
		};
		fetchFavoriteStatus();
	}, [id, token, handleMarkAsFavorite, handleUnmarkAsFavorite]);

	const handleOpenModal = () => {
		setModalOpen(true);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
	};
	const handleSendEmail = async (name, email, phone, message) => {
		const subject = "Hay alguien interesado en tus servicios";
		const contactDetails = {
			name: name,
			email: email,
			phone: phone,
		};
		let message2 =
			"Un usuario está interesado en tus servicios. Sus datos de contacto son: \n\n Nombre: " +
			contactDetails.name +
			"\n Email: " +
			contactDetails.email +
			"\n Teléfono: " +
			contactDetails.phone +
			"\n\n Mensaje: " +
			message +
			"\n\n Saludos, \n\n El equipo de EcoHousing";
		try {
			setLoading(true);
			const response = await sendEmail(user.email, subject, message2);
			setModalOpen(false);
			Swal.fire({
				icon: "success",
				title: "Email sent!",
				text: response.message,
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleReviewExpert = () => {
		navigate("/review-expert/" + id);
	};

	return (
		<ExpertProfilePageUI
			user={user}
			loading={loading}
			reviews={reviews}
			handleCloseModal={handleCloseModal}
			handleOpenModal={handleOpenModal}
			modalOpen={modalOpen}
			handleSendMail={handleSendEmail}
			handleReviewExpert={handleReviewExpert}
			isFavorite={isFavorite}
			handleMarkAsFavorite={handleMarkAsFavorite}
			handleUnmarkAsFavorite={handleUnmarkAsFavorite}
		/>
	);
}

export default ExpertProfilePage;
