import React, { useState, useEffect } from "react";
import HousingPageUI from "./HousingPageUI";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import getHousingById from "../../../networking/endpoints/housing/getHousingById";
import getLocation from "../../../networking/endpoints/housing/getLocation";
import sendEmail from "../../../networking/endpoints/user/sendEmail";
import getRecommendations from "../../../networking/endpoints/personalizer/recommendations";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";
import provideReward from "../../../networking/endpoints/personalizer/provideReward";
import markHousingAsFavorite from "../../../networking/endpoints/housing/markHousingAsFavorite";
import unmarkHousingAsFavorite from "../../../networking/endpoints/housing/unmarkHousingAsFavorite";

function HousingPage() {
	const navigate = useNavigate();
	const token = localStorage.getItem("authToken");
	const { housingId } = useParams();
	const [loading, setLoading] = useState(false);
	const [housing, setHousing] = useState({});
	const [images, setImages] = useState([]);
	const [address, setAddress] = useState({});
	const [tags, setTags] = useState([]);
	const [efficiencyLevel, setEfficiencyLevel] = useState("");
	const [expanded, setExpanded] = useState(false);
	const [description, setDescription] = useState("");
	const [location, setLocation] = useState({});
	const [ownerPhone, setOwnerPhone] = useState("");
	const [ownerEmail, setOwnerEmail] = useState("");
	const [recommendedHousings, setRecommendedHousings] = useState([]); // [housing1, housing2, housing3
	const [eventId, setEventId] = useState("");
	const [isFavorite, setIsFavorite] = useState(false);

	const toggleDescription = () => {
		setExpanded(!expanded);
	};

	useEffect(() => {
		const getHousing = async () => {
			try {
				setLoading(true);
				const response = await getHousingById(housingId);
				setHousing(response.housing);
				setImages(response.housing.images);
				setAddress(response.housing.address);
				setTags(response.housing.tags);
				setEfficiencyLevel(response.housing.energyEfficiencyLevel);
				setDescription(response.housing.description);
				setLoading(false);
				setOwnerPhone(response.housing.owner.phone);
				setOwnerEmail(response.housing.owner.email);
				setIsFavorite(response.isFavorite);
				if (response.housing) {
					getCoords(response.housing.address);
				}
				const recommendedHousings = await getRecommendations(
					token,
					response.housing
				);
				setRecommendedHousings(recommendedHousings.recommendations);
				setEventId(recommendedHousings.eventId);
			} catch (err) {
				setLoading(false);
				Swal.fire({
					title: "Error",
					text: err,
					icon: "error",
				});
			}
		};
		getHousing();
	}, [housingId, token]);
	async function getCoords(loc) {
		const add = {
			street: loc.street, //calle
			number: loc.number, //numero
			county: loc.province, //provincia
			city: loc.locality, //ciudad
			country: "Argentina",
		};

		const formattedAddress = `${add.street} ${add.number}, ${add.county}, ${add.city}, ${add.country}`;

		try {
			const response = await getLocation(formattedAddress);
			const { latitude, longitude } = response;
			setLocation({ latitude, longitude });
		} catch (error) {
			console.error("Error getting coordinates:", error);
		}
	}
	const handleSendEmail = async (name, email, phone, message) => {
		const subject = "Hay alguien interesado en tus servicios";
		const contactDetails = {
			name: name,
			email: email,
			phone: phone,
		};
		let message2 =
			"Un usuario está interesado en tus servicios. Sus datos de contacto son: \n\n Nombre: " +
			contactDetails.name +
			"\n Email: " +
			contactDetails.email +
			"\n Teléfono: " +
			contactDetails.phone +
			"\n\n Mensaje: " +
			message +
			"\n\n Saludos, \n\n El equipo de EcoHousing";
		try {
			setLoading(true);
			await sendEmail(ownerEmail, subject, message2);
			Swal.fire({
				icon: "success",
				title: "Email sent!",
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleClickRecommendation = async (housingId) => {
		navigate(constants.PAGES.HOUSING_PAGE + "/" + housingId);
		try {
			await provideReward(token, eventId);
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: error,
			});
		}
	};

	const toggleFavorite = async () => {
		try {
			if (isFavorite) {
				await unmarkHousingAsFavorite(token, housingId);
				setIsFavorite(false);
			} else {
				await markHousingAsFavorite(token, housingId);
				setIsFavorite(true);
			}
		} catch (err) {
			Swal.fire({
				icon: "error",
				title: "Oops...",
				text: err,
			});
		}
	};

	return (
		<HousingPageUI
			loading={loading}
			housingId={housingId}
			housing={housing}
			images={images}
			address={address}
			tags={tags}
			efficiencyLevel={efficiencyLevel}
			expanded={expanded}
			toggleDescription={toggleDescription}
			description={description}
			location={location}
			handleSendEmail={handleSendEmail}
			phoneNumber={ownerPhone}
			recommendedHousings={recommendedHousings}
			handleClickRecommendation={handleClickRecommendation}
			isFavorite={isFavorite}
			toggleFavorite={toggleFavorite}
		/>
	);
}

export default HousingPage;
