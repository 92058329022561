import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function getDevices(token, housingId) {
	api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	try {
		const response = await api.get(
			configuration.BASE_URL + "devices/" + housingId
		);
		const data = await response.data;

		return data;
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
