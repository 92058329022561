import React from "react";
import RecommendedHousing from "../../components/RecommendedHousing/RecommendedHousing";
import NavigatorTable from "../../components/NavBarHousing/NavBarHousing";
import ImageCarousel from "../../components/ImageCarousel/ImageCarousel";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import GoogleMapContainer from "../../components/GoogleMapContainer/GoogleMapContainer";
import ContactCard from "../../components/ContactCard/ContactCard";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Tooltip from "@mui/material/Tooltip";
import HousingPageSkeleton from "../../components/HousingPageSkeleton/HousingPageSkeleton";

function RecommendedHousinsList({
	recommendedHousings,
	handleClickRecommendation,
}) {
	return (
		<>
			<Typography
				variant="h6"
				gutterBottom
				fontWeight="bold"
				mt={"2rem"}
				textAlign={"center"}
			>
				Propiedades Similares
			</Typography>
			<div
				style={{
					paddingLeft: "1rem",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-evenly",
					alignItems: "center",
					marginTop: "2rem",
					gap: "1rem",
					flexWrap: "wrap",
				}}
			>
				{recommendedHousings.map((housing) => (
					<RecommendedHousing
						imageUrl={housing.images[0]}
						key={housing._id}
						title={housing.title}
						listingType={housing.listingType}
						efficiencyLevel={housing.energyEfficiencyLevel}
						price={housing.price}
						isFavorite={housing.isFavorite}
						toggleFavorite={() => console.log("clicked")}
						style={{ marginRight: "1rem" }} // Optional: Add margin between elements
						handleClickRecommendation={() => {
							handleClickRecommendation(housing._id);
						}}
					/>
				))}
			</div>
		</>
	);
}

function HousingPageUI({
	loading,
	housingId,
	housing,
	images,
	address,
	tags,
	efficiencyLevel,
	description,
	expanded,
	toggleDescription,
	location,
	handleSendEmail,
	phoneNumber,
	recommendedHousings,
	handleClickRecommendation,
	isFavorite,
	toggleFavorite,
}) {
	const theme = useTheme();
	return (
		<div
			style={{
				padding: "2rem",
			}}
		>
			<NavigatorTable housingId={housingId} />
			{loading ? (
				<HousingPageSkeleton />
			) : (
				<>
					<Grid container spacing={2}>
						<Grid item xs={12} md={5}>
							<ImageCarousel images={images} />
						</Grid>
						<Grid item xs={12} md={7}>
							<div
								style={{
									paddingLeft: "2rem",
									paddingRight: "2rem",
								}}
							>
								<Typography
									variant="h6"
									align="center"
									gutterBottom
									fontWeight="bold"
								>
									{housing.title}
								</Typography>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6} md={3}>
										<Typography
											variant="subtitle1"
											gutterBottom
											fontWeight="bold"
										>
											Dirección:
										</Typography>
										<Typography
											variant="body1"
											gutterBottom
										>
											{address.street}, {address.locality}
											. {address.province}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6} md={3}>
										<Typography
											variant="subtitle1"
											gutterBottom
											fontWeight="bold"
										>
											Precio:
										</Typography>
										<Typography
											variant="body1"
											gutterBottom
										>
											{housing.price} $
										</Typography>
									</Grid>
									<Grid item xs={12} sm={4} md={3}>
										<Typography
											variant="subtitle1"
											gutterBottom
											fontWeight="bold"
										>
											Condición:
										</Typography>
										<Typography
											variant="body1"
											gutterBottom
										>
											{housing.listingType === "rent"
												? "Alquiler"
												: "Venta"}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={4} md={3}>
										<Tooltip
											title={
												isFavorite
													? "Quitar de favoritos"
													: "Agregar a favoritos"
											}
										>
											<IconButton
												aria-label="Agregar a favoritos"
												sx={{
													border: "1px solid #ccc",
												}}
												onClick={toggleFavorite}
											>
												<FavoriteIcon
													color={
														isFavorite
															? "secondary"
															: "inherit"
													}
												/>
											</IconButton>
										</Tooltip>
									</Grid>
								</Grid>

								<Typography
									variant="subtitle1"
									gutterBottom
									fontWeight="bold"
								>
									Descripción:
								</Typography>
								<Typography variant="body1" gutterBottom>
									{expanded
										? description
										: description.slice(0, 400)}{" "}
									{/* Display only the first 100 characters */}
									{!expanded && description.length > 400 && (
										<Button
											color="primary"
											onClick={toggleDescription}
										>
											Leer Mas
										</Button>
									)}
									{expanded && (
										<Button
											color="primary"
											onClick={toggleDescription}
										>
											Leer Menos
										</Button>
									)}
								</Typography>

								<Typography
									variant="subtitle1"
									gutterBottom
									fontWeight="bold"
								>
									Tags:
								</Typography>

								<div
									style={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "space-around",
										alignContent: "center",
										flexWrap: "wrap",
										border: "1px solid #ccc",
										borderRadius: "10px",
										borderColor:
											theme.palette.secondary.main,
										marginBottom: "1rem",
									}}
								>
									{tags.map((detail) => (
										<Typography
											variant="body1"
											gutterBottom
											key={detail}
										>
											#{detail}
										</Typography>
									))}
								</div>
								<Grid container spacing={2}>
									<Grid item xs={12} sm={6} md={4}>
										<Typography
											variant="subtitle1"
											gutterBottom
											fontWeight="bold"
										>
											Eficiencia:
										</Typography>
										{efficiencyLevel && (
											<img
												src={require(`../../../assets/images/${efficiencyLevel}.png`)}
												alt="Efficiency Level"
												style={{
													width: "auto",
													height: "3rem",
													objectFit: "cover",
													margin: "0 auto",
												}}
											/>
										)}
										{!efficiencyLevel && (
											<Typography variant="body1">
												No disponible
											</Typography>
										)}
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<Typography
											variant="subtitle1"
											gutterBottom
											fontWeight="bold"
										>
											Ambientes:
										</Typography>
										<Typography
											variant="body1"
											gutterBottom
										>
											{housing.numRooms}
										</Typography>
									</Grid>
									<Grid item xs={12} sm={6} md={4}>
										<Typography
											variant="subtitle1"
											gutterBottom
											fontWeight="bold"
										>
											Dormitorios:
										</Typography>
										<Typography
											variant="body1"
											gutterBottom
										>
											{housing.numBedrooms}
										</Typography>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>
					<Grid container spacing={2} mt={"2rem"}>
						<Grid item xs={12} md={5}>
							<GoogleMapContainer
								latitude={location.latitude}
								longitude={location.longitude}
								radius={20}
							/>
						</Grid>
						<Grid item xs={12} md={7}>
							<ContactCard
								handleSendMail={handleSendEmail}
								phoneNumber={phoneNumber}
							/>
						</Grid>
					</Grid>
					<RecommendedHousinsList
						recommendedHousings={recommendedHousings}
						handleClickRecommendation={handleClickRecommendation}
					/>
				</>
			)}
		</div>
	);
}

export default HousingPageUI;
