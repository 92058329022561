import React from "react";
import EditHousingNavBar from "../../components/EditHousingNavBar/EditHousingNavBar";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import EditDevicesTable from "../../components/EditDevicesTable/EditDevicesTable";
import { Typography, Button } from "@mui/material";

function EditHousingDevicesUI({
	loading,
	housingId,
	rows,
	selectedImage,
	openImageDialog,
	handleAddRow,
	handleDeleteRow,
	handleImageChange,
	handleImageClick,
	handleCloseImageDialog,
	handleSubmit,
	edited,
}) {
	console.log(rows);
	return (
		<>
			<LoadingOverlay isLoading={loading} />
			<EditHousingNavBar housingId={housingId} />

			<div
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					marginTop: "2rem",
					gap: "2rem",
					padding: "0 2rem",
				}}
			>
				<Typography variant="h5" fontWeight="bold">
					Dispositivos
				</Typography>
				<EditDevicesTable
					rows={rows}
					handleAddRow={handleAddRow}
					handleDeleteRow={handleDeleteRow}
					handleImageChange={handleImageChange}
					handleImageClick={handleImageClick}
					handleCloseImageDialog={handleCloseImageDialog}
					selectedImage={selectedImage}
					openImageDialog={openImageDialog}
				/>
				{edited ? (
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							marginTop: "2rem",
						}}
					>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleSubmit}
						>
							Subir Dispositivos
						</Button>
					</div>
				) : null}
			</div>
		</>
	);
}

export default EditHousingDevicesUI;
