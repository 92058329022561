import api from "../../api";
import { configuration } from "../../../config/configuration";

const readEfficiencyTag = async (token, image) => {
	try {
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const response = await api.post(
			configuration.BASE_URL + "formRecognizer/analyze-form/efficiency",
			image,
			{
				headers: {
					"Content-Type": "multipart/form-data",
				},
				timeout: 40000,
			}
		);

		const data = await response.data;

		return data;
	} catch (error) {
		throw new Error(error);
	}
};

export default readEfficiencyTag;
