import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function deleteUser(token, id, password) {
	try {
		// Set the Authorization header with the token
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		const response = await api.post(
			configuration.BASE_URL + "users/" + id + "/delete",
			{
				password: password,
			}
		);

		const data = await response.data;
		return data; // Return the updated user data
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
