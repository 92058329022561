import React from "react";
import {
	Alert,
	Button,
	Step,
	StepLabel,
	Stepper,
	Typography,
	Link,
} from "@mui/material";
import { Box, Container } from "@mui/system";
import { Link as RouterLink } from "react-router-dom";
import constants from "../../../config/constants";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import InputRow from "../../components/InputRow/InputRow";
import InvoiceRow from "../../components/InvoiceRow/InvoiceRow";

function CompleteHousingDetailsUI({
	rows,
	selectedImage,
	openImageDialog,
	handleAddRow,
	handleDeleteRow,
	handleImageChange,
	handleImageClick,
	handleCloseImageDialog,
	handleSubmit,
	isMobileScreen,
	activeStep,
	handleNext,
	handleBack,
	loading,
	invoiceRows,
	handlePdfChange,
	handlePdfClick,
	handleClosePdfDialog,
	selectedPDF,
	openPdfDialog,
	handleAddPdfRow,
	handleDeletePdfRow,
}) {
	const steps = [
		"",
		"",
		"Dispositivos de alto consumo",
		"Facturas de Servicios",
	];

	return (
		<Container maxWidth="md">
			<Typography
				mt={2}
				variant="h1"
				fontSize="2rem"
				fontWeight="bold"
				gutterBottom
				style={{ textAlign: "center", margin: "2rem" }}
			>
				Completar Datos de la Vivienda
			</Typography>
			<Stepper
				activeStep={activeStep}
				orientation={isMobileScreen ? "vertical" : "horizontal"}
			>
				<Step index={0}>
					<StepLabel>Listo!</StepLabel>
				</Step>
				<Step index={1}>
					<StepLabel>Listo!</StepLabel>
				</Step>

				<Step index={2}>
					<StepLabel>Dispositivos de alto consumo</StepLabel>
				</Step>
				<Step index={3} last>
					<StepLabel>Facturas de Servicios</StepLabel>
				</Step>
			</Stepper>
			<Box
				id="form"
				component="form"
				noValidate
				sx={{
					margin: "3rem auto 0",
				}}
			>
				<>
					<Box m={4}>
						{activeStep === 2 && (
							<>
								<Alert severity="info">
									{constants.ALERT_TEXT.DEVICES_NOTIFIER} Si
									quieres conocer mas acerca de la etiqueta
									energetica ingresa a{" "}
									<Link
										component={RouterLink}
										to="https://www.argentina.gob.ar/energia/eficiencia-energetica/etiqueta#:~:text=La%20etiqueta%20de%20Eficiencia%20Energ%C3%A9tica,siempre%20en%20forma%20de%20adhesivo%20."
										target="_blank"
										rel="noopener noreferrer"
									>
										este link
									</Link>
								</Alert>
								<Alert
									severity="info"
									sx={{
										marginTop: "1rem",
									}}
								>
									Sube una imagen de la etiqueta. Los campos
									relevantes seran completados por nuestra
									inteligencia artificial
								</Alert>
								<Box sx={{ mt: "1rem" }}>
									<InputRow
										rows={rows}
										handleAddRow={handleAddRow}
										handleDeleteRow={handleDeleteRow}
										handleImageChange={handleImageChange}
										handleImageClick={handleImageClick}
										handleCloseImageDialog={
											handleCloseImageDialog
										}
										selectedImage={selectedImage}
										openImageDialog={openImageDialog}
									/>
								</Box>
							</>
						)}
						{activeStep === 3 && (
							<>
								<Alert severity="info">
									Esta seccion es opcional. Si no tienes las
									facturas de servicios, puedes omitir este
									paso.
								</Alert>
								<Alert
									severity="info"
									sx={{
										marginTop: "1rem",
									}}
								>
									Sube los pdfs de las facturas de servicios.
									Los campos seran parseados por nuestra
									inteligencia artificial
								</Alert>
								<Box sx={{ mt: "1rem" }}>
									<InvoiceRow
										rows={invoiceRows}
										handlePdfChange={handlePdfChange}
										handlePdfClick={handlePdfClick}
										handleClosePdfDialog={
											handleClosePdfDialog
										}
										selectedPDF={selectedPDF}
										openPdfDialog={openPdfDialog}
										handleAddRow={handleAddPdfRow}
										handleDeleteRow={handleDeletePdfRow}
									/>
								</Box>
							</>
						)}
					</Box>
					<Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
						{activeStep !== 4 && (
							<>
								<Button
									color="inherit"
									disabled={activeStep === 0}
									onClick={handleBack}
									sx={{ mr: 1 }}
								>
									Anterior
								</Button>
								<Box sx={{ flex: "1 1 auto" }} />

								{activeStep !== steps.length - 1 && (
									<Button
										sx={{ color: "#2D8D59" }}
										onClick={handleNext}
									>
										{"Siguiente"}
									</Button>
								)}
								{}
								{activeStep === steps.length - 1 && (
									<Button
										sx={{ color: "#2D8D59" }}
										onClick={handleSubmit}
									>
										{"Crear"}
									</Button>
								)}
							</>
						)}
					</Box>
				</>
			</Box>
			<LoadingOverlay isLoading={loading} />
		</Container>
	);
}

export default CompleteHousingDetailsUI;
