import React, { useState } from "react";
import DeleteAccountPageUI from "./DeleteAccountPageUI";
import Swal from "sweetalert2";
import deleteUser from "../../../networking/endpoints/user/deleteUser";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";
import { logout } from "../../../redux/slices/userReducer";

function DeleteAccountPage() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { token, id, googleId } = useSelector((state) => state.userReducer);
	const [loading, setLoading] = useState(false);
	const [password, setPassword] = useState("");

	const showConfirmationModal = () => {
		Swal.fire({
			title: "Confirmar Borrado Cuenta",

			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Confirmar",
			cancelButtonText: "Cancelar",
			confirmButtonColor: "#2D8D59",
			cancelButtonColor: "#D33",
		}).then((result) => {
			if (result.isConfirmed) {
				// Trigger the function you want to execute when the confirm button is pressed
				onSubmit(); // Replace handleConfirm with the actual function you want to execute
			} else {
				// Handle cancel or do nothing
			}
		});
	};

	const onSubmit = async () => {
		setLoading(true);
		try {
			if (googleId) {
				await deleteUser(token, id, "google-auth");
			} else {
				await deleteUser(token, id, password);
			}

			setLoading(false);
			Swal.fire({
				title: "Cuenta eliminada",
				text: "Tu cuenta ha sido eliminada",
				icon: "success",
				confirmButtonText: "Aceptar",
			});
			dispatch(logout());
			localStorage.removeItem("authToken");
			navigate(constants.PAGES.LANDING);
		} catch (error) {
			Swal.fire({
				title: "Error",
				text: error,
				icon: "error",
				confirmButtonText: "Aceptar",
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<DeleteAccountPageUI
			loading={loading}
			onSubmit={showConfirmationModal}
			password={password}
			setPassword={setPassword}
			googleId={googleId}
		/>
	);
}

export default DeleteAccountPage;
