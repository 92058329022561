import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating"; // Import Rating component from Material-UI
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton component from Material-UI
import { TextField, Button } from "@mui/material";
import constants from "../../../config/constants";

function RateExpertUI({
	loading,
	rating,
	comment,
	handleRatingChange,
	handleCommentChange,
	expert,
	onSubmit,
	onEdit,
	review,
	handleCancel,
	handleDelete,
}) {
	if (loading) {
		return (
			<React.Fragment>
				<Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
					<Paper
						sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
						elevation={3}
					>
						<Skeleton
							variant="circle"
							width={64}
							height={64}
							sx={{ margin: "16px auto" }}
						/>
						<Typography component="h5" variant="h5" align="center">
							<Skeleton width={150} />
						</Typography>
						<Skeleton
							height={20}
							width={200}
							sx={{ margin: "16px auto" }}
						/>
						<Skeleton
							variant="rectangular"
							height={40}
							sx={{ margin: "16px auto" }}
						/>
					</Paper>
				</Container>
			</React.Fragment>
		);
	} else {
		return (
			<React.Fragment>
				<CssBaseline />
				<Container component="main" maxWidth="sm" sx={{ mb: 4 }}>
					<Paper
						variant="outlined"
						sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
					>
						<Typography component="h5" variant="h5" align="center">
							{constants.MISC_TEXT.REVIEW_QUESTION}
							{expert.firstName}?
						</Typography>
						<div style={{ textAlign: "center" }}>
							<img
								src={expert.profileImageUrl}
								alt={`${expert.firstName}'s Profile`}
								style={{
									width: "64px", // Adjust the size of the circle as needed
									height: "64px",
									borderRadius: "50%", // Make it a circle
									marginTop: "16px",
								}}
							/>
						</div>
						<div style={{ textAlign: "center", marginTop: "16px" }}>
							<Typography variant="subtitle1">
								{`${expert.firstName} ${expert.lastName}`}
							</Typography>
							<Rating
								name="expert-rating"
								value={rating} // Set the initial rating value
								readOnly={false} // Allow editing
								style={{ marginTop: "16px" }}
								onChange={handleRatingChange}
							/>
							<TextField
								label="Commentario"
								multiline
								variant="outlined"
								fullWidth
								value={comment}
								onChange={handleCommentChange}
								rows={4}
								style={{ marginTop: "16px" }}
							/>
						</div>
						{Object.keys(review).length !== 0 &&
							(comment !== review.comment ||
								rating !== review.rating) && (
								<div
									style={{
										display: "flex",
										justifyContent: "center",
										marginTop: "16px",
									}}
								>
									<Button
										variant="outlined"
										color="secondary"
										onClick={handleCancel}
										fullWidth
									>
										Cancelar
									</Button>
									<Button
										variant="contained"
										color="secondary"
										onClick={onEdit}
										style={{ marginLeft: "8px" }}
										fullWidth
									>
										Editar
									</Button>
								</div>
							)}

						{Object.keys(review).length === 0 && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									marginTop: "16px",
								}}
							>
								<Button
									variant="contained"
									color="secondary"
									onClick={onSubmit}
									fullWidth
								>
									Calificar
								</Button>
							</div>
						)}
						{Object.keys(review).length !== 0 && (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									marginTop: "16px",
								}}
							>
								<Button
									variant="contained"
									color="secondary"
									onClick={handleDelete}
									fullWidth
								>
									Borrar
								</Button>
							</div>
						)}
					</Paper>
				</Container>
			</React.Fragment>
		);
	}
}

export default RateExpertUI;
