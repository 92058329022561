import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FixedSizeList } from "react-window";
import {
	Grid,
	AccordionDetails,
	AccordionSummary,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	InputAdornment,
	Radio,
	RadioGroup,
	Rating,
	TextField,
	Typography,
} from "@mui/material";
import { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { SearchOutlined } from "@mui/icons-material";
import { ControlledAccordion } from "../ControlledAccordion/ControlledAccordion";
import constants from "../../../config/constants";

const drawerWidth = 240;

export const FiltersExpertsDrawer = ({
	container,
	isOpen,
	handleDrawerToggle,
	children,
	province,
	localities,
	locality,
	skills,
	rating,
	handleProvincesChange,
	handleLocalitiesChange,
	handleSkillsChange,
	handleRatingChange,
	handleCleanFilters,
}) => {
	const location = useLocation();
	const isMatch = location.pathname === "/mentor/match";
	const showFiltersForMatch = false;

	const [provinceSearch, setProvinceSearch] = useState("");
	const [localitySearch, setLocalitySearch] = useState("");

	let isFiltered = false;

	if (province || locality || skills.length > 0 || rating) {
		isFiltered = true;
	}

	const isChecked = (name) => {
		//check in skills if name is in skills
		return skills.includes(name);
	};
	const handleFiltersClear = () => {
		handleCleanFilters();
	};

	const handleProvinceSearch = (event) => {
		const {
			target: { value },
		} = event;
		setProvinceSearch(value);
	};
	const handleLocalitySearch = (event) => {
		const {
			target: { value },
		} = event;
		setLocalitySearch(value);
	};
	const filteredProvinces = useMemo(
		() =>
			constants.PROVINCES.filter((province) =>
				province.toLowerCase().includes(provinceSearch.toLowerCase())
			),
		[provinceSearch]
	);

	const filteredLocalities = useMemo(
		() =>
			localities.filter((locality) =>
				locality.toLowerCase().includes(localitySearch.toLowerCase())
			),
		[localities, localitySearch]
	);
	const drawer = (
		<div>
			<Toolbar>
				<FilterListIcon />
				<Typography ml={2}>Filtros</Typography>
				{isFiltered && (
					<>
						<Box sx={{ flexGrow: 1 }} />
						<Button onClick={handleFiltersClear}>Borrar</Button>
					</>
				)}
			</Toolbar>
			<Divider />
			<ControlledAccordion
				defaultOpen={Boolean(province)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Provincia</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<TextField
						size="small"
						id="provinceSearch"
						label="Filtrá Provincias"
						name="provinceSearch"
						onChange={(e) => {
							handleProvinceSearch(e);
						}}
						sx={{ mb: 3 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchOutlined />
								</InputAdornment>
							),
						}}
					/>
					<RadioGroup
						onChange={handleProvincesChange}
						value={province}
					>
						<FixedSizeList
							height={200} // Adjust the height as needed
							itemSize={40} // Adjust the item size as needed
							itemCount={filteredProvinces.length} // Replace with the length of your data
						>
							{({ index, style }) => (
								<FormControlLabel
									key={filteredProvinces[index]}
									style={style}
									control={<Radio />}
									label={filteredProvinces[index]}
									value={filteredProvinces[index]}
									onChange={handleProvincesChange}
									checked={
										province === filteredProvinces[index]
									}
								/>
							)}
						</FixedSizeList>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
			<ControlledAccordion
				defaultOpen={Boolean(locality)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Localidad</Typography>
				</AccordionSummary>

				<AccordionDetails>
					<TextField
						size="small"
						id="localitySearch"
						label="Filtrá Localidades"
						name="localitySearch"
						onChange={(e) => {
							handleLocalitySearch(e);
						}}
						sx={{ mb: 3 }}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<SearchOutlined />
								</InputAdornment>
							),
						}}
					/>
					<RadioGroup
						onChange={handleLocalitiesChange}
						value={locality}
					>
						<FixedSizeList
							height={200} // Adjust the height as needed
							itemSize={40} // Adjust the item size as needed
							itemCount={filteredLocalities.length} // Replace with the length of your data
						>
							{({ index, style }) => (
								<FormControlLabel
									key={filteredLocalities[index]}
									style={style}
									control={<Radio />}
									label={filteredLocalities[index]}
									value={filteredLocalities[index]}
									onChange={handleLocalitiesChange}
									checked={
										locality === filteredLocalities[index]
									}
								/>
							)}
						</FixedSizeList>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
			<ControlledAccordion
				defaultOpen={Boolean(skills.length)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Expertise</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<FormGroup
						onChange={handleSkillsChange}
						sx={{
							flexWrap: "nowrap",
							maxHeight: "300px",
							overflowX: "auto",
							direction: "column",
						}}
					>
						{constants.EXPERT_SKILLS.map((skill) => (
							<FormControlLabel
								key={skill}
								control={<Checkbox />}
								label={skill}
								checked={isChecked(skill, skills)}
								name={skill}
							/>
						))}
					</FormGroup>
				</AccordionDetails>
			</ControlledAccordion>

			<ControlledAccordion
				defaultOpen={Boolean(rating)}
				sx={{ backgroundColor: "inherit" }}
			>
				<AccordionSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>Calificación</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<RadioGroup onChange={handleRatingChange} value={rating}>
						<FormControlLabel
							control={<Radio />}
							label={
								<>
									<span>4,0 o más</span>
									<Rating readOnly size="small" value={4} />
								</>
							}
							value="4"
						/>
						<FormControlLabel
							control={<Radio />}
							label={
								<>
									<span>3,0 o más</span>
									<Rating readOnly size="small" value={3} />
								</>
							}
							value="3"
						/>
						<FormControlLabel
							control={<Radio />}
							label={
								<>
									<span>2,0 o más </span>
									<Rating readOnly size="small" value={2} />
								</>
							}
							value="2"
						/>
						<FormControlLabel
							control={<Radio />}
							label={
								<>
									<span>1,0 o más </span>
									<Rating readOnly size="small" value={1} />
								</>
							}
							value="1"
						/>
					</RadioGroup>
				</AccordionDetails>
			</ControlledAccordion>
		</div>
	);

	return (
		<Box
			sx={{
				display: "flex",

				backgroundColor: "inherit",
			}}
			id="container-test"
		>
			<Box
				component="nav"
				sx={{
					width: {
						// eslint-disable-next-line no-nested-ternary
						sm: !isMatch
							? drawerWidth
							: showFiltersForMatch
							? drawerWidth
							: 0,
					},
					flexShrink: { sm: 0 },
				}}
				aria-label="mailbox folders"
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					variant="temporary"
					open={isOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
						container: container.current,
					}}
					PaperProps={{ style: { position: "absolute" } }}
					BackdropProps={{ style: { position: "absolute" } }}
					sx={{
						display: { xs: "block", sm: "none" },
						"& .MuiDrawer-paper": {
							boxSizing: "border-box",
							width: drawerWidth,
						},
					}}
				>
					{drawer}
				</Drawer>
				<Drawer
					ModalProps={{ container: container.current }}
					PaperProps={{
						style: {
							top: "initial",
							left: 0,
							paddingBottom: "80px",
							backgroundColor: "inherit",
							height: "100vh", // Set the height to fill the viewport
							overflowY: "auto", // Add scrolling if the content overflows
						},
					}}
					container={container.current}
					variant="permanent"
					sx={{
						display: {
							xs: "none",
							sm: "block",
						},
						"& .MuiDrawer-paper": {
							width: drawerWidth,
						},
					}}
					open
				>
					{drawer}
				</Drawer>
			</Box>
			<Grid
				container
				columnSpacing={2}
				rowSpacing={2}
				alignItems="center"
				sx={{
					// eslint-disable-next-line no-nested-ternary
					display: !isMatch
						? "flex"
						: showFiltersForMatch
						? "flex"
						: "none",
					bgcolor: (theme) => theme.palette.background.default,
					width: "100%",
					position: "fixed",
					top: "initial",
					left: "initial",
					zIndex: 1000,
					marginLeft: {
						xs: "unset",
						sm:
							isMatch && !showFiltersForMatch
								? "none"
								: `${drawerWidth}px`,
					},
				}}
			>
				<Grid item>
					<Button
						variant="outlined"
						onClick={handleDrawerToggle}
						sx={{
							display: { xs: "flex", sm: "none" },
							height: "56px",
							marginTop: "4px",
						}}
						startIcon={<FilterListIcon fontSize="small" />}
					>
						Filtrar
					</Button>
				</Grid>
			</Grid>
			<Box
				component="main"
				sx={{
					flexShrink: { sm: 0 },
					flexGrow: 1,
					width: {
						// eslint-disable-next-line no-nested-ternary
						sm: !isMatch
							? `calc(100% - ${drawerWidth}px)`
							: showFiltersForMatch
							? `calc(100% - ${drawerWidth}px)`
							: "calc(100vw - 80px)",
						xs: "calc(100vw - 80px)",
					},
					margin: {
						// eslint-disable-next-line no-nested-ternary
						xs: !isMatch
							? "0 1.5rem 40px"
							: showFiltersForMatch
							? "0 1.5rem 40px"
							: 0,
						// eslint-disable-next-line no-nested-ternary
						sm: !isMatch
							? "0 1.5rem 40px"
							: showFiltersForMatch
							? "0 1.5rem 40px"
							: 0,
					},
				}}
			>
				{children && (
					<Typography
						variant="h4"
						sx={{
							marginBottom: "1rem",
							mt: {
								xs: "5rem",
								sm: "2rem",
							},

							textAlign: "center",
						}}
					>
						Expertos encontrados
					</Typography>
				)}
				{children}
			</Box>
		</Box>
	);
};
