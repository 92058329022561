import React, { useEffect, useState } from "react";
import AccountPageUI from "./AccountPageUI";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import updateUser from "../../../networking/endpoints/user/updateUser";
import { initUser } from "../../../redux/slices/userReducer";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";
import deleteExpertProfile from "../../../networking/endpoints/user/deleteExpertProfile";
import getUserDataFromToken from "../../../networking/endpoints/user/getDataFromToken";

function AccountPage() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const token = localStorage.getItem("authToken");
	const { id, role } = useSelector((state) => state.userReducer);
	const [loading, setLoading] = useState(false);
	const [user, setUser] = useState({});
	const [lastName, setLastName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [document, setDocument] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [image, setImage] = useState("");

	useEffect(() => {
		async function getUserData() {
			try {
				setLoading(true);
				const user = await getUserDataFromToken(token);

				setUser(user.user);
				//before setting them validate if the field exists
				if (user.user.lastName) setLastName(user.user.lastName);
				if (user.user.firstName) setFirstName(user.user.firstName);
				if (user.user.documentNumber)
					setDocument(user.user.documentNumber);
				if (user.user.phone) setPhoneNumber(user.user.phone);
				if (user.user.profileImageUrl)
					setImage(user.user.profileImageUrl);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.message,
				});
			} finally {
				setLoading(false);
			}
		}
		getUserData();
	}, [id, token, role]);

	const handleImageChange = (newImageData) => {
		setImage(newImageData); // Update the state with the new image data
	};

	const base64toFile = (dataURI, filename) => {
		const byteString = atob(dataURI.split(",")[1]);
		const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new File([ab], filename, { type: mimeString });
	};

	const onSubmit = async () => {
		try {
			setLoading(true);
			// Create a new FormData object
			const formData = new FormData();
			// Append the data we want to send to the API
			formData.append("firstName", firstName);
			formData.append("lastName", lastName);
			formData.append("documentNumber", document);
			if (phoneNumber.slice(0, 2) === "54") {
				formData.append("phone", phoneNumber);
			} else {
				Swal.fire({
					icon: "error",
					title: "Error",
					text: "El número de teléfono debe comenzar con 54",
				});
				return;
			}
			if (image !== user.profileImageUrl) {
				const file = base64toFile(image, "profileImage.jpg");
				formData.append("profileImage", file);
			}
			// Make the API request to update user data
			const response = await updateUser(token, id, formData);
			dispatch(initUser(response));
			setUser(response.user);
			Swal.fire({
				icon: "success",
				title: "Datos actualizados",
				text: "Los datos fueron actualizados con éxito",
			});
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleCreateTechnicalAccount = () => {
		navigate(constants.PAGES.CREATE_TECHNICAL_ACCOUNT);
	};

	const showConfirmationModal = () => {
		Swal.fire({
			title: "Confirmar Borrado de Perfil de Técnico",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "Confirmar",
			cancelButtonText: "Cancelar",
			confirmButtonColor: "#2D8D59",
			cancelButtonColor: "#D33",
		}).then((result) => {
			if (result.isConfirmed) {
				handleDeleteTechnicalAccount();
			} else {
			}
		});
	};

	const handleDeleteTechnicalAccount = async () => {
		try {
			setLoading(true);
			const response = await deleteExpertProfile(token, id);
			Swal.fire({
				icon: "success",
				title: "Cuenta eliminada",
				text: "La cuenta fue eliminada con éxito",
			});
			dispatch(initUser(response));
		} catch (error) {
			Swal.fire({
				icon: "error",
				title: "Error",
				text: error,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleViewExpertProfile = () => {
		navigate(constants.PAGES.EXPERT_PROFILE + "/" + id);
	};

	const handleEditExpertAccount = () => {
		navigate(constants.PAGES.EDIT_EXPERT_PROFILE + "/" + id);
	};

	const handleCancelChanges = () => {
		setLastName(user.lastName);
		setFirstName(user.firstName);
		user.documentNumber
			? setDocument(user.documentNumber)
			: setDocument("");
		user.phone ? setPhoneNumber(user.phone) : setPhoneNumber("");
		setImage(user.profileImageUrl);
	};

	return (
		<AccountPageUI
			user={user}
			loading={loading}
			lastName={lastName}
			setLastName={setLastName}
			firstName={firstName}
			setFirstName={setFirstName}
			phoneNumber={phoneNumber}
			setPhoneNumber={setPhoneNumber}
			document={document}
			setDocument={setDocument}
			image={image}
			handleImageChange={handleImageChange}
			onSubmit={onSubmit}
			handleCreateTechnicalAccount={handleCreateTechnicalAccount}
			handleDeleteTechnicalAccount={showConfirmationModal}
			handleViewExpertProfile={handleViewExpertProfile}
			handleEditExpertAccount={handleEditExpertAccount}
			handleCancelChanges={handleCancelChanges}
		/>
	);
}

export default AccountPage;
