import React from "react";
import NavBarFavorites from "../../components/NavbarFavorites/NavbarFavorites";
import HousingCardSkeleton from "../../components/HousingCardSkeleton/HousingCardSkeleton";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import FavoriteHousingCard from "../../components/FavoriteHousingCard/FavoriteHousingCard";
import { Typography } from "@mui/material";

function FavoriteHousingsUI({
	loadingHousings,
	loading,
	housings,
	onPressFavorite,
	handleClickHousing,
}) {
	return (
		<>
			<NavBarFavorites />
			<LoadingOverlay isLoading={loading} />
			{loadingHousings ? (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "2rem",
						padding: "2rem",
					}}
				>
					<HousingCardSkeleton />
					<HousingCardSkeleton />
					<HousingCardSkeleton />
					<HousingCardSkeleton />
				</div>
			) : (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "2rem",
						padding: "2rem",
					}}
				>
					{housings.map((housing) => (
						<FavoriteHousingCard
							title={housing.housing.title}
							description={housing.housing.description}
							price={housing.housing.price}
							locality={housing.housing.address.locality}
							province={housing.housing.address.province}
							listingType={housing.housing.listingType}
							housingImage={housing.housing.images[0]}
							tags={housing.housing.tags}
							id={housing.housing._id}
							effieciencyLevel={
								housing.housing.energyEfficiencyLevel
							}
							handleClickHousing={() =>
								handleClickHousing(housing.housing._id)
							}
							onPressFavorite={() =>
								onPressFavorite(housing.housing._id)
							}
						/>
					))}
				</div>
			)}
			{housings.length === 0 && (
				<div
					style={{
						display: "flex",
						padding: "1rem",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						height: "60vh",
					}}
				>
					<Typography
						variant="h6"
						fontSize="2rem"
						fontWeight="bold"
						gutterBottom
						style={{ textAlign: "center" }}
					>
						No has marcado ninguna vivienda como favorita
					</Typography>
					<img
						src={require("../../../assets/images/caja-vacia.png")}
						alt="empty"
						width="300px"
					/>
				</div>
			)}
		</>
	);
}

export default FavoriteHousingsUI;
