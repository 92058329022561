import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function getFilteredExperts(page, filters) {
	try {
		// Make the API request to get user data
		const response = await api.get(
			configuration.BASE_URL + `users/experts?page=${page}`,
			{
				params: filters,
			}
		);
		const data = await response.data;

		return data; // Return the user data
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
