import api from "../../api";
import { configuration } from "../../../config/configuration";
import axiosRetry from "axios-retry";

const checkTokenValidity = async (token) => {
	try {
		// Set the Authorization header with the token
		api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

		// Configure axios-retry
		axiosRetry(api, {
			retries: 3, // Number of retries
			retryDelay: (retryCount) => retryCount * 1000, // Delay between retries
			retryCondition: (error) => {
				// Retry only if the status code is 5xx
				return error.response && error.response.status >= 500;
			},
		});

		// Make the API request to create a new housing
		const response = await api.get(
			configuration.BASE_URL + "auth/check-token-validity",
			{
				headers: {},
			}
		);

		return response;
	} catch (error) {
		throw new Error(error.response.data.error);
	}
};

export default checkTokenValidity;
