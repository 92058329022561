import React from "react";
import { Avatar, Button, Paper, Stack } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";

const ImageUploader = ({ image, onImageChange }) => {
	const handleImageUpload = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const imageData = reader.result;
				onImageChange(imageData); // Pass the new image data to the parent component
			};
			reader.readAsDataURL(file);
		}
	};

	return (
		<Paper
			elevation={3}
			sx={{
				p: 2,
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<Avatar src={image} sx={{ width: 120, height: 120 }} />

			<Stack direction="row" spacing={2} sx={{ mt: 2 }}>
				<label htmlFor="image-upload">
					<input
						type="file"
						accept="image/*"
						id="image-upload"
						style={{ display: "none" }}
						onChange={handleImageUpload}
					/>
					<Button
						variant="contained"
						component="span"
						startIcon={<PhotoCameraIcon />}
					>
						Select Image
					</Button>
				</label>
			</Stack>
		</Paper>
	);
};

export default ImageUploader;
