import React from "react";
import "./LandingPage.css";
import constants from "../../../config/constants";
import ButtonOutline from "../../components/ButtonOutline/ButtonOutline";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import PrimaryButton from "../../components/PrimaryButton/PrimaryButton";
import YouTube from "react-youtube";
import { useNavigate } from "react-router-dom";

const VideoPlayer = () => {
	const videoId = "u3lc2XdZUUI";

	const opts = {
		// YouTube player options
		height: "250px", // 16:9 aspect ratio (9 / 16 * 100 = 56.25)
		width: "100%",
		playerVars: {
			origin: window.location.origin, // Set the origin to your application's origin
		},
	};

	return (
		<div>
			<YouTube videoId={videoId} opts={opts} />
		</div>
	);
};

function LandingPage() {
	const theme = useTheme();
	const navigate = useNavigate();
	return (
		<>
			<div className="whatWeDoContainer">
				<div className="whatWeDo">
					<Typography
						variant="h5"
						sx={{
							color: theme.palette.primary.main,
							fontWeight: "bold",
							fontFamily: theme.typography.fontFamily,
						}}
					>
						{constants.MISC_TEXT.WHAT_WE_DO}
					</Typography>
					<div className="actionButtons">
						<ButtonOutline
							title={constants.BUTTON_NAMES.SEARCH_HOUSES}
							onClick={() =>
								navigate(constants.PAGES.SEARCH_HOUSES)
							}
						/>
						<ButtonOutline
							title={constants.BUTTON_NAMES.SEARCH_EXPERTS}
							onClick={() =>
								navigate(constants.PAGES.SEARCH_EXPERTS)
							}
						/>
					</div>
				</div>
				<img
					src={require("../../../assets/images/planet-earth landing.png")}
					alt="planet earth"
					className="imagePlanet"
				/>
			</div>
			<div className="whatMakesUsDifferent">
				<Typography
					variant="h5"
					sx={{
						color: theme.palette.background.main,
						fontWeight: "bold",
						fontFamily: theme.typography.fontFamily,
						marginTop: "2rem",
					}}
				>
					{constants.MISC_TEXT.WHAT_MAKES_US_DIFFERENT_TITLE}
				</Typography>
				<div className="differenceContainer">
					<Typography
						variant="p"
						sx={{
							color: theme.palette.background.main,
							fontFamily: theme.typography.fontFamily,
							width: "auto",
						}}
					>
						{constants.MISC_TEXT.WHAT_MAKES_US_DIFFERENT}
						<br />
						<br />
						{constants.MISC_TEXT.WHAT_MAKES_US_DIFFERENT_2}
					</Typography>

					<img
						src={require("../../../assets/images/renewable-energy.png")}
						alt="planet earth"
						className="renewableImage"
					/>
				</div>
			</div>
			<div className="lastPartContainer">
				<Typography
					variant="h5"
					sx={{
						color: theme.palette.primary.main,
						fontWeight: "bold",
						fontFamily: theme.typography.fontFamily,
					}}
				>
					{constants.MISC_TEXT.CREATE_YOUR_ACCOUNT_TODAY}
				</Typography>
				<div className="primaryButtonContainer">
					<PrimaryButton
						title={constants.BUTTON_NAMES.CREATE_ACCOUNT}
						onClick={() => navigate(constants.PAGES.SIGN_UP)}
					/>
				</div>
			</div>
			<div className="pronevContainer">
				<div className="titleVideoContainer">
					<Typography
						variant="h6"
						sx={{
							color: theme.palette.background.main,
							fontWeight: "bold",
							fontFamily: theme.typography.fontFamily,
						}}
					>
						{constants.MISC_TEXT.MEET_PRONEV}
					</Typography>
					<VideoPlayer />
				</div>

				<img
					src={require("../../../assets/images/energy-consumption.png")}
					alt="pronev"
					className="pronevImage"
				/>
			</div>
		</>
	);
}

export default LandingPage;
