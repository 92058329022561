import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import constants from "../../../config/constants";
import { Link as DOMLink } from "react-router-dom";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";
import { GoogleLogin } from "@react-oauth/google";

function LogInPageUI({
	email,
	setEmail,
	password,
	setPassword,
	onSubmit,
	loading,
	handleGoogleLogin,
	handleGoogleFailure,
}) {
	const theme = useTheme();
	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					{constants.BUTTON_NAMES.SIGN_IN}
				</Typography>
				<Box
					component="form"
					onSubmit={(e) => {
						e.preventDefault(); // Prevent default form submission
						onSubmit(); // Call the onSubmit function passed as a prop
					}}
					noValidate
					sx={{ mt: 1 }}
				>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						autoComplete="email"
						autoFocus
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						value={password}
						onChange={(e) => setPassword(e.target.value)}
					/>

					<Box
						sx={{
							display: "flex",
							alignItems: "center", // Center the loading spinner vertically
							justifyContent: "center", // Center the loading spinner horizontally
							flexDirection: "column", // Stack the loading spinner on top of the button
						}}
					>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{
								mt: 3,
								mb: 2,
								backgroundColor: `${theme.palette.secondary.main} !important`,
							}}
						>
							{constants.BUTTON_NAMES.LOG_IN}
						</Button>
					</Box>

					<Grid container>
						<Grid item xs>
							<Link
								component={DOMLink}
								variant="body2"
								to={constants.PAGES.FORGOT_MY_PASSWORD}
							>
								Forgot password?
							</Link>
						</Grid>
						<Grid item>
							<Link
								component={DOMLink}
								variant="body2"
								to={constants.PAGES.SIGN_UP}
							>
								{constants.BUTTON_NAMES.DONT_HAVE_AN_ACCOUNT}
							</Link>
						</Grid>
					</Grid>
				</Box>
				<Box sx={{ mt: 5 }}>
					<GoogleLogin
						onSuccess={(credentialResponse) => {
							handleGoogleLogin(credentialResponse);
						}}
						onError={handleGoogleFailure}
					/>
				</Box>
			</Box>
			<LoadingOverlay isLoading={loading} />
		</Container>
	);
}

export default LogInPageUI;
