import styled from "@emotion/styled";
import {
	Card,
	CardContent,
	Skeleton,
	Box,
	Stack,
	Divider,
	Grid,
} from "@mui/material";

export default function ExpertCardSkeleton() {
	const StyledCard = styled(Card)`
		border-radius: 1rem;
		border: 1.5px solid #2d8d59;
		background-color: #eeeeee50;
	`;

	return (
		<StyledCard
			sx={{
				minWidth: 275,
				maxWidth: {
					xs: "unset",
					md: 330,
				},
				height: 550,
			}}
			elevation={0}
		>
			<CardContent>
				<Box
					sx={{
						display: "grid",
						gridTemplateColumns: "repeat(3, 20% 50% 30%)",
						gap: 1,
						gridTemplateRows: "100px auto auto ",
						gridTemplateAreas: {
							md: `
                "sider sider sider"
                "sider sider sider"
                "main main main"
                "main main main"
              `,
							xs: `
                "sider sider sider"
                "sider sider sider"
                "main main main"
                "main main main"
              `,
						},
					}}
				>
					<Box
						sx={{
							maxHeight: "230px",
							gridArea: "sider",
							borderRight: "1px solid",
							borderColor: "grey.200",
							pr: 1,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}
					>
						<Skeleton variant="circular" width={200} height={200} />
						<Skeleton variant="text" width={100} height={30} />
					</Box>
					<Box
						sx={{
							gridArea: "main",
							borderRight: "1px solid",
							borderColor: "grey.200",
							pr: 1,
						}}
					>
						<Stack>
							<Skeleton variant="text" width={150} height={40} />
							<Skeleton variant="text" width={200} height={30} />
							<Divider sx={{ mb: 1 }} />
							<Skeleton variant="text" width={200} height={80} />
							<Divider sx={{ mb: 1 }} />
							<Grid container spacing={1}>
								{[1, 2, 3].map((index) => (
									<Grid item xs={4} key={index}>
										<Skeleton
											variant="text"
											width={120}
											height={32}
										/>
									</Grid>
								))}
							</Grid>
						</Stack>
					</Box>
				</Box>
			</CardContent>
		</StyledCard>
	);
}
