import api from "../../api";
import { configuration } from "../../../config/configuration";

export default async function getRatingsForExpert(id) {
	try {
		// Make the API request to get user data
		const response = await api.get(
			configuration.BASE_URL + "ratings/" + id
		);
		const data = await response.data;

		return data; // Return the user data
	} catch (error) {
		throw new Error(error.response.data.error);
	}
}
