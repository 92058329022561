import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import constants from "../../../config/constants";
import "./NavBar.css";
import { useTheme } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { logout } from "../../../redux/slices/userReducer";

const pages = [
	{
		name: constants.BUTTON_NAMES.PUBLISH_HOUSE,
		path: constants.PAGES.PUBLISH_HOUSE,
	},
	{
		name: constants.BUTTON_NAMES.SEARCH_HOUSES,
		path: constants.PAGES.SEARCH_HOUSES,
	},
	{
		name: constants.BUTTON_NAMES.SEARCH_EXPERTS,
		path: constants.PAGES.SEARCH_EXPERTS,
	},
];
const settings = [
	{ name: constants.DROPDOWN_MENU.ACCOUNT, path: constants.PAGES.ACCOUNT },
	{
		name: constants.DROPDOWN_MENU.FAVORITES,
		path: constants.PAGES.FAVORITE_HOUSINGS,
	},
	{
		name: constants.DROPDOWN_MENU.MY_PUBLICATIONS,
		path: constants.PAGES.USER_HOUSINGS,
	},
	{ name: constants.DROPDOWN_MENU.LOG_OUT, path: constants.PAGES.LOG_OUT },
];

function NavBar() {
	const dispatch = useDispatch();
	const theme = useTheme();
	const navigate = useNavigate();
	const { isLoggedIn, photo } = useSelector((state) => state.userReducer);

	const [anchorElNav, setAnchorElNav] = React.useState(null);
	const [anchorElUser, setAnchorElUser] = React.useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};
	const handleOpenUserMenu = (event) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	const handleCloseUserMenu = (pagePath) => {
		setAnchorElUser(null);
	};
	const handleClickUserMenu = (pagePath) => {
		handleCloseUserMenu();
		if (pagePath === constants.PAGES.LOG_OUT) {
			handleLogout();
		} else {
			navigate(pagePath);
		}
	};
	const handleClickPageMenu = (pagePath) => {
		handleCloseNavMenu();
		navigate(pagePath);
	};

	const handleLogout = () => {
		localStorage.removeItem("authToken");
		dispatch(logout());
		navigate(constants.PAGES.LOG_IN);
	};

	return (
		<AppBar position="sticky">
			<Container maxWidth="xl">
				<Toolbar disableGutters>
					<Box>
						<IconButton
							size="large"
							aria-label="account of current user"
							aria-controls="menu-appbar"
							aria-haspopup="true"
							onClick={() => navigate(constants.PAGES.LANDING)}
							color="inherit"
							sx={{
								display: { xs: "block", md: "none" },
							}}
						>
							<img
								src={require("../../../assets/images/solar-energy.png")}
								alt="logo"
								className="logo"
							/>
						</IconButton>
					</Box>
					<Typography
						variant="h6"
						noWrap
						component={Link}
						to={constants.PAGES.LANDING}
						sx={{
							mr: 2,
							display: { xs: "none", md: "flex" },
							fontWeight: "bold",
							textDecoration: "none !important", // Prevent link underline
							color: `${theme.palette.common.white} !important`,
						}}
					>
						<img
							src={require("../../../assets/images/solar-energy.png")}
							alt="logo"
							className="logo"
						/>
						{constants.MISC_TEXT.APP_NAME}
					</Typography>
					{isLoggedIn ? (
						<>
							<Box
								sx={{
									flexGrow: 1,
									display: { xs: "flex", md: "none" },
								}}
							>
								<IconButton
									size="large"
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleOpenNavMenu}
									color="inherit"
								>
									<MenuIcon />
								</IconButton>
								<Menu
									id="menu-appbar"
									anchorEl={anchorElNav}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "left",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "left",
									}}
									open={Boolean(anchorElNav)}
									onClose={handleCloseNavMenu}
									sx={{
										display: { xs: "block", md: "none" },
									}}
								>
									{pages.map((page) => (
										<MenuItem
											key={page.name}
											onClick={() =>
												handleClickPageMenu(page.path)
											}
										>
											<Typography textAlign="center">
												{page.name}
											</Typography>
										</MenuItem>
									))}
								</Menu>
							</Box>

							<Typography
								variant="h5"
								noWrap
								component={Link}
								to={constants.PAGES.LANDING}
								sx={{
									mr: 2,
									display: { xs: "flex", md: "none" },
									flexGrow: 1,
									fontWeight: "bold",
									color: `${theme.palette.common.white} !important`,
									textDecoration: "none !important",
								}}
							>
								{constants.MISC_TEXT.APP_NAME}
							</Typography>

							<Box
								sx={{
									flexGrow: 1,
									display: { xs: "none", md: "flex" },
								}}
							>
								{pages.map((page) => (
									<Button
										variant="contained"
										onClick={() => navigate(page.path)}
										key={page.name}
										sx={{
											backgroundColor: `${theme.palette.secondary.main} !important`,
											mr: 2,
										}}
									>
										{page.name}
									</Button>
								))}
							</Box>
							<Box sx={{ flexGrow: 0 }}>
								<Tooltip title="Open settings">
									<IconButton
										onClick={handleOpenUserMenu}
										sx={{ p: 0 }}
									>
										<Avatar alt="Remy Sharp" src={photo} />
									</IconButton>
								</Tooltip>
								<Menu
									sx={{ mt: "45px" }}
									id="menu-appbar"
									anchorEl={anchorElUser}
									anchorOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									keepMounted
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									open={Boolean(anchorElUser)}
									onClose={handleCloseUserMenu}
								>
									{settings.map((setting) => (
										<MenuItem
											key={setting.name}
											onClick={() =>
												handleClickUserMenu(
													setting.path
												)
											}
										>
											<Typography textAlign="center">
												{setting.name}
											</Typography>
										</MenuItem>
									))}
								</Menu>
							</Box>
						</>
					) : (
						<div id="buttonContainer">
							<Button
								variant="contained"
								onClick={() => navigate(constants.PAGES.LOG_IN)}
								sx={{
									backgroundColor: `${theme.palette.secondary.main} !important`,
								}}
							>
								{constants.BUTTON_NAMES.LOG_IN}
							</Button>
							<Button
								variant="contained"
								sx={{
									backgroundColor: `${theme.palette.secondary.main} !important`,
								}}
								onClick={() =>
									navigate(constants.PAGES.SIGN_UP)
								}
							>
								{constants.BUTTON_NAMES.SIGN_UP}
							</Button>
						</div>
					)}
				</Toolbar>
			</Container>
		</AppBar>
	);
}

export default NavBar;
