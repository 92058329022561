import React, { useState, useEffect } from "react";
import EditExpertProfileUI from "./EditExpertProfileUI";
import axios from "axios";
import Swal from "sweetalert2";
import updateExpertProfile from "../../../networking/endpoints/user/updateExpertProfile";
import { useSelector, useDispatch } from "react-redux";
import { initUser } from "../../../redux/slices/userReducer";
import { useNavigate } from "react-router-dom";
import constants from "../../../config/constants";
import getUserById from "../../../networking/endpoints/user/getUserById";

function EditExpertProfile() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { token, id } = useSelector((state) => state.userReducer);
	const [province, setProvince] = useState("");
	const [locality, setLocality] = useState("");
	const [loading, setLoading] = useState(false);
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [localities, setLocalities] = useState([]);
	const [linkedinUrl, setLinkedinUrl] = useState("");
	const [facebookUrl, setFacebookUrl] = useState("");
	const [skills, setSkills] = useState([]);
	const [user, setUser] = useState({});

	useEffect(() => {
		async function getUserData() {
			try {
				setLoading(true);
				const user = await getUserById(id);

				//before setting them validate if the field exists
				if (user.skills) setSkills(user.skills);
				if (user.expertTitle) setTitle(user.expertTitle);
				if (user.expertDescription)
					setDescription(user.expertDescription);
				if (user.linkedinUrl) setLinkedinUrl(user.linkedinUrl);
				if (user.facebookUrl) setFacebookUrl(user.facebookUrl);
				if (user.address) {
					setProvince(user.address.province);
					setLocality(user.address.locality);
				}
				setUser(user);
			} catch (error) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error.message,
				});
			} finally {
				setLoading(false);
			}
		}
		getUserData();
	}, [id, token]);

	useEffect(() => {
		const fetchLocalities = async () => {
			setLoading(true); // Set loading to true

			try {
				const response = await axios.get(
					`https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&max=1000`
				);
				const localitiesData = response.data.localidades;
				const formattedLocalities = localitiesData.map((locality) =>
					capitalizeFirstLetter(locality.nombre.toLowerCase())
				);

				// Sort the formattedLocalities array in alphabetical order
				formattedLocalities.sort((a, b) => a.localeCompare(b));

				setLocalities(formattedLocalities);
				// Reset the selected locality
			} catch (error) {
				if (error.code === 1) {
					console.log("Error: ", error.message);
				}
			}

			setLoading(false); // Set loading back to false
		};

		fetchLocalities();
	}, [province]);

	const capitalizeFirstLetter = (str) => {
		return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
	};

	const handleSubmit = async () => {
		setLoading(true); // Set loading to true
		try {
			const userInfo = {};
			//check if the field is different from the one in the state user before adding it to user info
			if (title !== user.expertTitle) userInfo.expertTitle = title;
			if (description !== user.expertDescription)
				userInfo.expertDescription = description;
			if (province !== user.address.province)
				userInfo.address = { ...user.address, province };
			if (locality !== user.address.locality)
				userInfo.address = { ...user.address, locality };
			if (linkedinUrl !== user.linkedinUrl)
				userInfo.linkedinUrl = linkedinUrl;
			if (facebookUrl !== user.facebookUrl)
				userInfo.facebookUrl = facebookUrl;
			if (skills !== user.skills) userInfo.skills = skills;
			const response = await updateExpertProfile(token, id, userInfo);
			dispatch(initUser(response));
			Swal.fire({
				icon: "success",
				title: "¡Perfil de experto modificado con exito!",
			});
			navigate(constants.PAGES.ACCOUNT);
		} catch (error) {
			if (error.code === 1) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: error,
				});
			}
		} finally {
			setLoading(false); // Set loading back to false
		}
	};

	const handleProvinceChange = async (province) => {
		setLoading(true); // Set loading to true

		setProvince(province);
		try {
			const response = await axios.get(
				`https://apis.datos.gob.ar/georef/api/localidades?provincia=${province}&max=1000`
			);

			const localitiesData = response.data.localidades;
			const formattedLocalities = localitiesData.map((locality) =>
				capitalizeFirstLetter(locality.nombre.toLowerCase())
			);

			// Sort the formattedLocalities array in alphabetical order
			formattedLocalities.sort((a, b) => a.localeCompare(b));

			setLocalities(formattedLocalities);
			// Reset the selected locality
			setLocality("");
		} catch (error) {
			console.log(error);
			if (error.code === 1) {
				Swal.fire({
					icon: "error",
					title: "Oops...",
					text: "No se pudo cargar las localidades",
				});
			}
		}

		setLoading(false); // Set loading back to false
	};
	return (
		<EditExpertProfileUI
			province={province}
			locality={locality}
			loading={loading}
			title={title}
			description={description}
			handleSubmit={handleSubmit}
			setProvince={setProvince}
			setLocality={setLocality}
			setTitle={setTitle}
			setDescription={setDescription}
			handleProvinceChange={handleProvinceChange}
			localities={localities}
			linkedinUrl={linkedinUrl}
			setLinkedinUrl={setLinkedinUrl}
			facebookUrl={facebookUrl}
			setFacebookUrl={setFacebookUrl}
			skills={skills}
			setSkills={setSkills}
		/>
	);
}

export default EditExpertProfile;
