import React from "react";
import { Button, FormControl } from "@mui/material";
import Dropzone from "react-dropzone"; // Import Dropzone

function ImagePickerEditHousing({
	text,
	images,
	handleImageRemove,
	handleImageDrop,
}) {
	return (
		<FormControl sx={{ width: "100%" }} margin="normal">
			<Dropzone onDrop={handleImageDrop} multiple maxFiles={2}>
				{({ getRootProps, getInputProps }) => (
					<div
						{...getRootProps()}
						style={{
							border: "2px dashed #ccc",
							padding: "20px",
							textAlign: "center",
						}}
					>
						<input {...getInputProps()} />
						<p>{text}</p>
					</div>
				)}
			</Dropzone>
			<div
				style={{
					marginTop: "1rem",
					display: "flex",
					flexWrap: "wrap",
				}}
			>
				{images &&
					images.map((url, index) => (
						<div
							key={index}
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								marginRight: "10px",
								marginBottom: "10px",
							}}
						>
							<img
								src={
									typeof url === "string"
										? url
										: URL.createObjectURL(url)
								}
								alt="preview"
								style={{
									width: "100px",
									height: "100px",
									borderRadius: "5px",
									boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
								}}
							/>
							<Button
								onClick={() => handleImageRemove(index)}
								style={{ marginTop: "5px" }}
							>
								<span
									role="img"
									aria-label="Remove"
									style={{
										fontSize: "16px",
									}}
								>
									❌
								</span>
							</Button>
						</div>
					))}
			</div>
		</FormControl>
	);
}

export default ImagePickerEditHousing;
