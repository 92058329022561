import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import constants from "../../../config/constants";
import LoadingOverlay from "../../components/LoadingOverlay/LoadingOverlay";

function ForgotPasswordPageUI({ loading, onSubmit, email, setEmail }) {
	const theme = useTheme();
	return (
		<Container component="main">
			<CssBaseline />
			<Box
				sx={{
					marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
				}}
			>
				<Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					{constants.BUTTON_NAMES.FORGOT_MY_PASSWORD}
				</Typography>
				<Box
					component="form"
					onSubmit={(e) => {
						e.preventDefault(); // Prevent default form submission
						onSubmit(); // Call the onSubmit function passed as a prop
					}}
					noValidate
					sx={{ mt: 1, width: "100%" }}
					maxWidth="400px"
				>
					<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email"
						name="email"
						autoComplete="email"
						autoFocus
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					/>

					<Box
						sx={{
							display: "flex",
							alignItems: "center", // Center the loading spinner vertically
							justifyContent: "center", // Center the loading spinner horizontally
							flexDirection: "column", // Stack the loading spinner on top of the button
						}}
					>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{
								mt: 3,
								mb: 2,
								backgroundColor: `${theme.palette.secondary.main} !important`,
							}}
						>
							{constants.BUTTON_NAMES.SEND}
						</Button>
					</Box>
				</Box>
			</Box>
			<LoadingOverlay isLoading={loading} />
		</Container>
	);
}

export default ForgotPasswordPageUI;
